.blog-row {
  background-color: $light-gray;
  min-height: fit-content;
  overflow: hidden;
  padding: 3%;

  .blog-container {
    margin-bottom: 2%;

    .blog-item {
      background-color: $white;

      .blog-image {
        // stylelint doesn't recognize aspect-ratio, even though it gets supported by all modern browsers
        /* stylelint-disable-next-line property-no-unknown */
        aspect-ratio: 2 / 1;
        object-fit: cover;
        object-position: 50% 15%;
        width: 100%;
      }

      .blog-textbox {
        padding: 6% 6% 15%;

        .blog-text {
          padding-top: 8%;

          .blog-title {
            font-size: 1.4375rem;
          }
        }
      }
    }
  }

  .blog-news-button {
    background-color: $white;
    border-radius: 30px;
    float: right;
    padding: 14px 28px;
  }
}
