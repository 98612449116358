.invisible,
.kadi-row-product .kadi-product-desc .icons div.mail-contact a span,
.kadi-row-product div.dl-widget-link a span,
.kadi-row-product .product-desc-toggle span,
.sv-widget-asset ul.asset-info div.info a.download span,
.sv-widget-gallerydownload a.download span,
footer > div.get-in-touch div.fb a span,
footer > div.get-in-touch div.yt a span,
footer > div.get-in-touch div.instagram a span,
footer > div.get-in-touch div.twitter a span {
  display: inline;
  height: 0;
  left: -1000px;
  overflow: hidden;
  position: absolute;
  width: 0;
}

.button-nwl-subscribe,
footer > div.get-in-touch div.nwl a.button {
  background: #403933;
  color: #fff !important;
  display: inline-block;
  padding: 3px 10px;
  text-decoration: none !important;
  text-transform: none;
}

.sv-widget .button-nwl-subscribe,
.sv-widget footer > div.get-in-touch div.nwl a.button,
footer > div.get-in-touch div.nwl .sv-widget a.button {
  padding: 3px 15px;
}

.sv-widget .button-nwl-subscribe:hover,
.sv-widget footer > div.get-in-touch div.nwl a.button:hover,
footer > div.get-in-touch div.nwl .sv-widget a.button:hover {
  background: #fff !important;
  color: #403933 !important;
}

@font-face {
  font-family: 'FS Sammy Regular';
  src: url('fonts/FSSammyWeb-Regular.eot');
  src: url('fonts/FSSammyWeb-Regular.eot?#iefix') format('embedded-opentype'), url('fonts/FSSammyWeb-Regular.woff') format('woff');
}

meta.foundation-version {
  font-family: '/5.1.0/', sans-serif;
}

meta.foundation-mq-small {
  font-family: '/only screen and (max-width: 50em)/', sans-serif;
  width: 0;
}

meta.foundation-mq-medium {
  font-family: '/only screen and (min-width:50.063em)/', sans-serif;
  width: 50.063em;
}

meta.foundation-mq-large {
  font-family: '/only screen and (min-width:64.063em)/', sans-serif;
  width: 64.063em;
}

meta.foundation-mq-xlarge {
  font-family: '/only screen and (min-width:90.063em)/', sans-serif;
  width: 90.063em;
}

meta.foundation-mq-xxlarge {
  font-family: '/only screen and (min-width:120.063em)/', sans-serif;
  width: 120.063em;
}

meta.foundation-data-attribute-namespace {
  font-family: false, sans-serif;
}

html,
body {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  font-size: 100%;
}

body {
  background: #fff;
  color: #6d6458;
  cursor: default;
  font-family: FrutigerLTW01-55Roman, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  margin: 0;
  padding: 0;
  position: relative;
}

a:hover {
  cursor: pointer;
}

img,
object,
embed {
  height: auto;
  max-width: 100%;
}

object,
embed {
  height: 100%;
}

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object {
  max-width: none !important;
}

.left,
.kadi-row-product .product-preparation-1,
.kadi-row-product .product-preparation-2,
.kadi-row-product .product-preparation-3,
.kadi-row-product .product-preparation-4,
.kadi-row-product .product-preparation-5 {
  float: left !important;
}

.right,
.kadi-row-product .kadi-product-desc .icons div {
  float: right !important;
}

.clearfix {
  *zoom: 1;
}

.clearfix::before,
.clearfix::after {
  content: ' ';
  display: table;
}

.clearfix::after {
  clear: both;
}

.hide {
  display: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
}

textarea {
  height: auto;
  min-height: 50px;
}

select {
  width: 100%;
}

.row {
  margin: 0 auto;
  max-width: 150rem;
  width: 100%;
  *zoom: 1;
}

.row::before,
.row::after {
  content: ' ';
  display: table;
}

.row::after {
  clear: both;
}

.row.collapse > .column,
.row.collapse > .columns {
  float: left;
  padding-left: 0;
  padding-right: 0;
}

.row.collapse .row {
  margin-left: 0;
  margin-right: 0;
}

.row .row {
  margin: 0 -4.375rem;
  max-width: none;
  width: auto;
  *zoom: 1;
}

.row .row::before,
.row .row::after {
  content: ' ';
  display: table;
}

.row .row::after {
  clear: both;
}

.row .row.collapse {
  margin: 0;
  max-width: none;
  width: auto;
  *zoom: 1;
}

.row .row.collapse::before,
.row .row.collapse::after {
  content: ' ';
  display: table;
}

.row .row.collapse::after {
  clear: both;
}

.column,
.columns {
  float: left;
  padding-left: 4.375rem;
  padding-right: 4.375rem;
  width: 100%;
}

@media only screen {
  .column.small-centered,
  .columns.small-centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .column.small-uncentered,
  .columns.small-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .column.small-uncentered.opposite,
  .columns.small-uncentered.opposite {
    float: right;
  }

  .small-push-0 {
    left: 0;
    right: auto;
  }

  .small-pull-0 {
    left: auto;
    right: 0;
  }

  .small-push-1 {
    left: 8.33333%;
    right: auto;
  }

  .small-pull-1 {
    left: auto;
    right: 8.33333%;
  }

  .small-push-2 {
    left: 16.66667%;
    right: auto;
  }

  .small-pull-2 {
    left: auto;
    right: 16.66667%;
  }

  .small-push-3 {
    left: 25%;
    right: auto;
  }

  .small-pull-3 {
    left: auto;
    right: 25%;
  }

  .small-push-4 {
    left: 33.33333%;
    right: auto;
  }

  .small-pull-4 {
    left: auto;
    right: 33.33333%;
  }

  .small-push-5 {
    left: 41.66667%;
    right: auto;
  }

  .small-pull-5 {
    left: auto;
    right: 41.66667%;
  }

  .small-push-6 {
    left: 50%;
    right: auto;
  }

  .small-pull-6 {
    left: auto;
    right: 50%;
  }

  .small-push-7 {
    left: 58.33333%;
    right: auto;
  }

  .small-pull-7 {
    left: auto;
    right: 58.33333%;
  }

  .small-push-8 {
    left: 66.66667%;
    right: auto;
  }

  .small-pull-8 {
    left: auto;
    right: 66.66667%;
  }

  .small-push-9 {
    left: 75%;
    right: auto;
  }

  .small-pull-9 {
    left: auto;
    right: 75%;
  }

  .small-push-10 {
    left: 83.33333%;
    right: auto;
  }

  .small-pull-10 {
    left: auto;
    right: 83.33333%;
  }

  .small-push-11 {
    left: 91.66667%;
    right: auto;
  }

  .small-pull-11 {
    left: auto;
    right: 91.66667%;
  }

  .column,
  .columns {
    float: left;
    padding-left: 4.375rem;
    padding-right: 4.375rem;
    position: relative;
  }

  .small-1 {
    width: 8.33333%;
  }

  .small-2 {
    width: 16.66667%;
  }

  .small-3 {
    width: 25%;
  }

  .small-4 {
    width: 33.33333%;
  }

  .small-5 {
    width: 41.66667%;
  }

  .small-6 {
    width: 50%;
  }

  .small-7 {
    width: 58.33333%;
  }

  .small-8 {
    width: 66.66667%;
  }

  .small-9 {
    width: 75%;
  }

  .small-10 {
    width: 83.33333%;
  }

  .small-11 {
    width: 91.66667%;
  }

  .small-12 {
    width: 100%;
  }

  [class*='column'] + [class*='column']:last-child {
    float: right;
  }

  [class*='column'] + [class*='column'].end {
    float: left;
  }

  .small-offset-0 {
    margin-left: 0 !important;
  }

  .small-offset-1 {
    margin-left: 8.33333% !important;
  }

  .small-offset-2 {
    margin-left: 16.66667% !important;
  }

  .small-offset-3 {
    margin-left: 25% !important;
  }

  .small-offset-4 {
    margin-left: 33.33333% !important;
  }

  .small-offset-5 {
    margin-left: 41.66667% !important;
  }

  .small-offset-6 {
    margin-left: 50% !important;
  }

  .small-offset-7 {
    margin-left: 58.33333% !important;
  }

  .small-offset-8 {
    margin-left: 66.66667% !important;
  }

  .small-offset-9 {
    margin-left: 75% !important;
  }

  .small-offset-10 {
    margin-left: 83.33333% !important;
  }

  .small-offset-11 {
    margin-left: 91.66667% !important;
  }

  .small-reset-order,
  .small-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }
}

@media only screen and (min-width: 50.063em) {
  .column.medium-centered,
  .columns.medium-centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .column.medium-uncentered,
  .columns.medium-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .column.medium-uncentered.opposite,
  .columns.medium-uncentered.opposite {
    float: right;
  }

  .medium-push-0 {
    left: 0;
    right: auto;
  }

  .medium-pull-0 {
    left: auto;
    right: 0;
  }

  .medium-push-1 {
    left: 8.33333%;
    right: auto;
  }

  .medium-pull-1 {
    left: auto;
    right: 8.33333%;
  }

  .medium-push-2 {
    left: 16.66667%;
    right: auto;
  }

  .medium-pull-2 {
    left: auto;
    right: 16.66667%;
  }

  .medium-push-3 {
    left: 25%;
    right: auto;
  }

  .medium-pull-3 {
    left: auto;
    right: 25%;
  }

  .medium-push-4 {
    left: 33.33333%;
    right: auto;
  }

  .medium-pull-4 {
    left: auto;
    right: 33.33333%;
  }

  .medium-push-5 {
    left: 41.66667%;
    right: auto;
  }

  .medium-pull-5 {
    left: auto;
    right: 41.66667%;
  }

  .medium-push-6 {
    left: 50%;
    right: auto;
  }

  .medium-pull-6 {
    left: auto;
    right: 50%;
  }

  .medium-push-7 {
    left: 58.33333%;
    right: auto;
  }

  .medium-pull-7 {
    left: auto;
    right: 58.33333%;
  }

  .medium-push-8 {
    left: 66.66667%;
    right: auto;
  }

  .medium-pull-8 {
    left: auto;
    right: 66.66667%;
  }

  .medium-push-9 {
    left: 75%;
    right: auto;
  }

  .medium-pull-9 {
    left: auto;
    right: 75%;
  }

  .medium-push-10 {
    left: 83.33333%;
    right: auto;
  }

  .medium-pull-10 {
    left: auto;
    right: 83.33333%;
  }

  .medium-push-11 {
    left: 91.66667%;
    right: auto;
  }

  .medium-pull-11 {
    left: auto;
    right: 91.66667%;
  }

  .column,
  .columns {
    float: left;
    padding-left: 4.375rem;
    padding-right: 4.375rem;
    position: relative;
  }

  .medium-1 {
    width: 8.33333%;
  }

  .medium-2 {
    width: 16.66667%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-4 {
    width: 33.33333%;
  }

  .medium-5 {
    width: 41.66667%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-7 {
    width: 58.33333%;
  }

  .medium-8 {
    width: 66.66667%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-10 {
    width: 83.33333%;
  }

  .medium-11 {
    width: 91.66667%;
  }

  .medium-12 {
    width: 100%;
  }

  [class*='column'] + [class*='column']:last-child {
    float: right;
  }

  [class*='column'] + [class*='column'].end {
    float: left;
  }

  .medium-offset-0 {
    margin-left: 0 !important;
  }

  .medium-offset-1 {
    margin-left: 8.33333% !important;
  }

  .medium-offset-2 {
    margin-left: 16.66667% !important;
  }

  .medium-offset-3 {
    margin-left: 25% !important;
  }

  .medium-offset-4 {
    margin-left: 33.33333% !important;
  }

  .medium-offset-5 {
    margin-left: 41.66667% !important;
  }

  .medium-offset-6 {
    margin-left: 50% !important;
  }

  .medium-offset-7 {
    margin-left: 58.33333% !important;
  }

  .medium-offset-8 {
    margin-left: 66.66667% !important;
  }

  .medium-offset-9 {
    margin-left: 75% !important;
  }

  .medium-offset-10 {
    margin-left: 83.33333% !important;
  }

  .medium-offset-11 {
    margin-left: 91.66667% !important;
  }

  .medium-reset-order,
  .medium-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }

  .push-0 {
    left: 0;
    right: auto;
  }

  .pull-0 {
    left: auto;
    right: 0;
  }

  .push-1 {
    left: 8.33333%;
    right: auto;
  }

  .pull-1 {
    left: auto;
    right: 8.33333%;
  }

  .push-2 {
    left: 16.66667%;
    right: auto;
  }

  .pull-2 {
    left: auto;
    right: 16.66667%;
  }

  .push-3 {
    left: 25%;
    right: auto;
  }

  .pull-3 {
    left: auto;
    right: 25%;
  }

  .push-4 {
    left: 33.33333%;
    right: auto;
  }

  .pull-4 {
    left: auto;
    right: 33.33333%;
  }

  .push-5 {
    left: 41.66667%;
    right: auto;
  }

  .pull-5 {
    left: auto;
    right: 41.66667%;
  }

  .push-6 {
    left: 50%;
    right: auto;
  }

  .pull-6 {
    left: auto;
    right: 50%;
  }

  .push-7 {
    left: 58.33333%;
    right: auto;
  }

  .pull-7 {
    left: auto;
    right: 58.33333%;
  }

  .push-8 {
    left: 66.66667%;
    right: auto;
  }

  .pull-8 {
    left: auto;
    right: 66.66667%;
  }

  .push-9 {
    left: 75%;
    right: auto;
  }

  .pull-9 {
    left: auto;
    right: 75%;
  }

  .push-10 {
    left: 83.33333%;
    right: auto;
  }

  .pull-10 {
    left: auto;
    right: 83.33333%;
  }

  .push-11 {
    left: 91.66667%;
    right: auto;
  }

  .pull-11 {
    left: auto;
    right: 91.66667%;
  }
}

@media only screen and (min-width: 64.063em) {
  .column.large-centered,
  .columns.large-centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .column.large-uncentered,
  .columns.large-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .column.large-uncentered.opposite,
  .columns.large-uncentered.opposite {
    float: right;
  }

  .large-push-0 {
    left: 0;
    right: auto;
  }

  .large-pull-0 {
    left: auto;
    right: 0;
  }

  .large-push-1 {
    left: 8.33333%;
    right: auto;
  }

  .large-pull-1 {
    left: auto;
    right: 8.33333%;
  }

  .large-push-2 {
    left: 16.66667%;
    right: auto;
  }

  .large-pull-2 {
    left: auto;
    right: 16.66667%;
  }

  .large-push-3 {
    left: 25%;
    right: auto;
  }

  .large-pull-3 {
    left: auto;
    right: 25%;
  }

  .large-push-4 {
    left: 33.33333%;
    right: auto;
  }

  .large-pull-4 {
    left: auto;
    right: 33.33333%;
  }

  .large-push-5 {
    left: 41.66667%;
    right: auto;
  }

  .large-pull-5 {
    left: auto;
    right: 41.66667%;
  }

  .large-push-6 {
    left: 50%;
    right: auto;
  }

  .large-pull-6 {
    left: auto;
    right: 50%;
  }

  .large-push-7 {
    left: 58.33333%;
    right: auto;
  }

  .large-pull-7 {
    left: auto;
    right: 58.33333%;
  }

  .large-push-8 {
    left: 66.66667%;
    right: auto;
  }

  .large-pull-8 {
    left: auto;
    right: 66.66667%;
  }

  .large-push-9 {
    left: 75%;
    right: auto;
  }

  .large-pull-9 {
    left: auto;
    right: 75%;
  }

  .large-push-10 {
    left: 83.33333%;
    right: auto;
  }

  .large-pull-10 {
    left: auto;
    right: 83.33333%;
  }

  .large-push-11 {
    left: 91.66667%;
    right: auto;
  }

  .large-pull-11 {
    left: auto;
    right: 91.66667%;
  }

  .column,
  .columns {
    float: left;
    padding-left: 4.375rem;
    padding-right: 4.375rem;
    position: relative;
  }

  .large-1 {
    width: 8.33333%;
  }

  .large-2 {
    width: 16.66667%;
  }

  .large-3 {
    width: 25%;
  }

  .large-4 {
    width: 33.33333%;
  }

  .large-5 {
    width: 41.66667%;
  }

  .large-6 {
    width: 50%;
  }

  .large-7 {
    width: 58.33333%;
  }

  .large-8 {
    width: 66.66667%;
  }

  .large-9 {
    width: 75%;
  }

  .large-10 {
    width: 83.33333%;
  }

  .large-11 {
    width: 91.66667%;
  }

  .large-12 {
    width: 100%;
  }

  [class*='column'] + [class*='column']:last-child {
    float: right;
  }

  [class*='column'] + [class*='column'].end {
    float: left;
  }

  .large-offset-0 {
    margin-left: 0 !important;
  }

  .large-offset-1 {
    margin-left: 8.33333% !important;
  }

  .large-offset-2 {
    margin-left: 16.66667% !important;
  }

  .large-offset-3 {
    margin-left: 25% !important;
  }

  .large-offset-4 {
    margin-left: 33.33333% !important;
  }

  .large-offset-5 {
    margin-left: 41.66667% !important;
  }

  .large-offset-6 {
    margin-left: 50% !important;
  }

  .large-offset-7 {
    margin-left: 58.33333% !important;
  }

  .large-offset-8 {
    margin-left: 66.66667% !important;
  }

  .large-offset-9 {
    margin-left: 75% !important;
  }

  .large-offset-10 {
    margin-left: 83.33333% !important;
  }

  .large-offset-11 {
    margin-left: 91.66667% !important;
  }

  .large-reset-order,
  .large-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }

  .push-0 {
    left: 0;
    right: auto;
  }

  .pull-0 {
    left: auto;
    right: 0;
  }

  .push-1 {
    left: 8.33333%;
    right: auto;
  }

  .pull-1 {
    left: auto;
    right: 8.33333%;
  }

  .push-2 {
    left: 16.66667%;
    right: auto;
  }

  .pull-2 {
    left: auto;
    right: 16.66667%;
  }

  .push-3 {
    left: 25%;
    right: auto;
  }

  .pull-3 {
    left: auto;
    right: 25%;
  }

  .push-4 {
    left: 33.33333%;
    right: auto;
  }

  .pull-4 {
    left: auto;
    right: 33.33333%;
  }

  .push-5 {
    left: 41.66667%;
    right: auto;
  }

  .pull-5 {
    left: auto;
    right: 41.66667%;
  }

  .push-6 {
    left: 50%;
    right: auto;
  }

  .pull-6 {
    left: auto;
    right: 50%;
  }

  .push-7 {
    left: 58.33333%;
    right: auto;
  }

  .pull-7 {
    left: auto;
    right: 58.33333%;
  }

  .push-8 {
    left: 66.66667%;
    right: auto;
  }

  .pull-8 {
    left: auto;
    right: 66.66667%;
  }

  .push-9 {
    left: 75%;
    right: auto;
  }

  .pull-9 {
    left: auto;
    right: 75%;
  }

  .push-10 {
    left: 83.33333%;
    right: auto;
  }

  .pull-10 {
    left: auto;
    right: 83.33333%;
  }

  .push-11 {
    left: 91.66667%;
    right: auto;
  }

  .pull-11 {
    left: auto;
    right: 91.66667%;
  }
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

@media only screen and (max-width: 50em) {
  .small-only-text-left {
    text-align: left !important;
  }

  .small-only-text-right {
    text-align: right !important;
  }

  .small-only-text-center {
    text-align: center !important;
  }

  .small-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen {
  .small-text-left {
    text-align: left !important;
  }

  .small-text-right {
    text-align: right !important;
  }

  .small-text-center {
    text-align: center !important;
  }

  .small-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 50.063em) and (max-width: 64em) {
  .medium-only-text-left {
    text-align: left !important;
  }

  .medium-only-text-right {
    text-align: right !important;
  }

  .medium-only-text-center {
    text-align: center !important;
  }

  .medium-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 50.063em) {
  .medium-text-left {
    text-align: left !important;
  }

  .medium-text-right {
    text-align: right !important;
  }

  .medium-text-center {
    text-align: center !important;
  }

  .medium-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 64.063em) and (max-width: 90em) {
  .large-only-text-left {
    text-align: left !important;
  }

  .large-only-text-right {
    text-align: right !important;
  }

  .large-only-text-center {
    text-align: center !important;
  }

  .large-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 64.063em) {
  .large-text-left {
    text-align: left !important;
  }

  .large-text-right {
    text-align: right !important;
  }

  .large-text-center {
    text-align: center !important;
  }

  .large-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 90.063em) and (max-width: 120em) {
  .xlarge-only-text-left {
    text-align: left !important;
  }

  .xlarge-only-text-right {
    text-align: right !important;
  }

  .xlarge-only-text-center {
    text-align: center !important;
  }

  .xlarge-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 90.063em) {
  .xlarge-text-left {
    text-align: left !important;
  }

  .xlarge-text-right {
    text-align: right !important;
  }

  .xlarge-text-center {
    text-align: center !important;
  }

  .xlarge-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 120.063em) and (max-width: 99999999em) {
  .xxlarge-only-text-left {
    text-align: left !important;
  }

  .xxlarge-only-text-right {
    text-align: right !important;
  }

  .xxlarge-only-text-center {
    text-align: center !important;
  }

  .xxlarge-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 120.063em) {
  .xxlarge-text-left {
    text-align: left !important;
  }

  .xxlarge-text-right {
    text-align: right !important;
  }

  .xxlarge-text-center {
    text-align: center !important;
  }

  .xxlarge-text-justify {
    text-align: justify !important;
  }
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

a {
  color: #403933;
  line-height: inherit;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #37312c;
}

a img {
  border: 0;
}

p {
  font-family: inherit;
  font-size: 0.8125rem;
  font-weight: normal;
  line-height: 1.1875rem;
  margin-bottom: 1.25rem;
  text-rendering: optimizeLegibility;
}

p.lead {
  font-size: 1.03125rem;
  line-height: 1.6;
}

p aside {
  font-size: 0.875rem;
  font-style: italic;
  line-height: 1.35;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #6d6458;
  font-family: FrutigerLTW01-75Black, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 1.1875rem;
  margin-top: 0;
  text-rendering: optimizeLegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #a49b8e;
  font-size: 60%;
  line-height: 0;
}

h1 {
  font-size: 1.4375rem;
}

h2 {
  font-size: 1.4375rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

.subheader {
  color: #a49b8e;
  font-weight: normal;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
}

hr {
  border: solid #ddd;
  border-width: 1px 0 0;
  clear: both;
  height: 0;
  margin: 1.25rem 0 1.1875rem;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 60%;
  line-height: inherit;
}

code {
  color: #bd260d;
  font-family: Consolas, 'Liberation Mono', Courier, monospace;
  font-weight: bold;
}

ul,
ol,
dl {
  font-family: inherit;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  list-style-position: outside;
  margin-bottom: 1.25rem;
}

ul {
  margin-left: 1.1rem;
}

ul.no-bullet {
  margin-left: 0;
}

ul.no-bullet li ul,
ul.no-bullet li ol {
  list-style: none;
  margin-bottom: 0;
  margin-left: 1.25rem;
}

ul li ul,
ul li ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
}

ul.square li ul,
ul.circle li ul,
ul.disc li ul {
  list-style: inherit;
}

ul.square {
  list-style-type: square;
  margin-left: 1.1rem;
}

ul.circle {
  list-style-type: circle;
  margin-left: 1.1rem;
}

ul.disc {
  list-style-type: disc;
  margin-left: 1.1rem;
}

ul.no-bullet {
  list-style: none;
}

ol {
  margin-left: 1.4rem;
}

ol li ul,
ol li ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
}

dl dt {
  font-weight: bold;
  margin-bottom: 0.3rem;
}

dl dd {
  margin-bottom: 0.75rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #ddd;
  color: #6d6458;
  cursor: help;
  font-size: 90%;
  text-transform: uppercase;
}

abbr {
  text-transform: none;
}

blockquote {
  border-left: 0;
  margin: 0 0 1.25rem;
  padding: 0;
}

blockquote cite {
  color: #93887a;
  display: block;
  font-size: 0.8125rem;
}

blockquote cite::before {
  content: '\2014 \0020';
}

blockquote cite a,
blockquote cite a:visited {
  color: #93887a;
}

blockquote,
blockquote p {
  color: #6d6458;
  line-height: 1.1875rem;
}

.vcard {
  border: 1px solid #ddd;
  display: inline-block;
  margin: 0 0 1.25rem;
  padding: 0.625rem 0.75rem;
}

.vcard li {
  display: block;
  margin: 0;
}

.vcard .fn {
  font-size: 0.9375rem;
  font-weight: bold;
}

.vevent .summary {
  font-weight: bold;
}

.vevent abbr {
  border: 0;
  cursor: default;
  font-weight: bold;
  padding: 0 0.0625rem;
  text-decoration: none;
}

@media only screen and (min-width: 50.063em) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1;
  }

  h1 {
    font-size: 2.0625rem;
  }

  h2 {
    font-size: 2.0625rem;
  }

  h3 {
    font-size: 2.0625rem;
  }

  h4 {
    font-size: 1.4375rem;
  }
}

.print-only {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: ' (' attr(href) ')';
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  .ir a::after,
  a[href^='javascript:']::after,
  a[href^='#']::after {
    content: '';
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .hide-on-print {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }

  .hide-for-print {
    display: none !important;
  }

  .show-for-print {
    display: inherit !important;
  }
}

meta.foundation-mq-topbar {
  font-family: '/only screen and (min-width:50.063em)/', sans-serif;
  width: 50.063em;
}

.contain-to-grid {
  background: rgba(255, 255, 255, 0);
  width: 100%;
}

.contain-to-grid .top-bar {
  margin-bottom: 0;
}

.fixed {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.fixed.expanded:not(.top-bar) {
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
}

.fixed.expanded:not(.top-bar) .title-area {
  position: fixed;
  width: 100%;
  z-index: 99;
}

.fixed.expanded:not(.top-bar) .top-bar-section {
  margin-top: 53px;
  z-index: 98;
}

.top-bar {
  background: rgba(255, 255, 255, 0);
  height: 53px;
  line-height: 53px;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
}

.top-bar ul {
  list-style: none;
  margin-bottom: 0;
}

.top-bar .row {
  max-width: none;
}

.top-bar form,
.top-bar input {
  margin-bottom: 0;
}

.top-bar input {
  font-size: 0.75rem;
  height: auto;
  padding-bottom: 0.35rem;
  padding-top: 0.35rem;
}

.top-bar .button {
  font-size: 0.75rem;
  margin-bottom: 0;
  padding-bottom: 0.35rem;
  padding-top: 0.45rem;
}

.top-bar .title-area {
  margin: 0;
  position: relative;
}

.top-bar .name {
  font-size: 16px;
  height: 53px;
  margin: 0;
}

.top-bar .name h1 {
  font-size: 1.0625rem;
  line-height: 53px;
  margin: 0;
}

.top-bar .name h1 a {
  color: #403933;
  display: block;
  font-weight: normal;
  padding: 0 17.66667px;
  width: 50%;
}

.top-bar .toggle-topbar {
  position: absolute;
  right: 0;
  top: 0;
}

.top-bar .toggle-topbar a {
  color: #403933;
  display: block;
  font-size: 0.8125rem;
  font-weight: bold;
  height: 53px;
  line-height: 53px;
  padding: 0 17.66667px;
  position: relative;
  text-transform: uppercase;
}

.top-bar .toggle-topbar.menu-icon {
  margin-top: -16px;
  padding-left: 40px;
  right: 17.66667px;
  top: 50%;
}

.top-bar .toggle-topbar.menu-icon a {
  color: #403933;
  height: 34px;
  line-height: 33px;
  padding: 0 25px 0 0;
  position: relative;
}

.top-bar .toggle-topbar.menu-icon a::after {
  box-shadow: 0 10px 0 1px #403933, 0 16px 0 1px #403933, 0 22px 0 1px #403933;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
}

.top-bar.expanded {
  background: transparent;
  height: auto;
}

.top-bar.expanded .title-area {
  background: rgba(255, 255, 255, 0);
}

.top-bar.expanded .toggle-topbar a {
  color: #9e9283;
}

.top-bar.expanded .toggle-topbar a span {
  box-shadow: 0 10px 0 1px #9e9283, 0 16px 0 1px #9e9283, 0 22px 0 1px #9e9283;
}

.top-bar-section {
  left: 0;
  position: relative;
  transition: left 300ms ease-out;
  width: auto;
}

.top-bar-section ul {
  background: rgba(255, 255, 255, 0);
  display: block;
  font-size: 16px;
  height: auto;
  margin: 0;
  width: 100%;
}

.top-bar-section .divider,
.top-bar-section [role='separator'] {
  border-top: solid 1px rgba(128, 128, 128, 0);
  clear: both;
  height: 1px;
  width: 100%;
}

.top-bar-section ul li > a {
  background: rgba(255, 255, 255, 0);
  color: #403933;
  display: block;
  font-family: FrutigerLTW01-55Roman, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
  font-size: 0.8125rem;
  font-weight: normal;
  padding: 12px 0 12px 17.66667px;
  width: 100%;
}

.top-bar-section ul li > a.button {
  background: #403933;
  font-size: 0.8125rem;
  padding-left: 17.66667px;
  padding-right: 17.66667px;
}

.top-bar-section ul li > a.button:hover {
  background: #2f2a25;
}

.top-bar-section ul li > a.button.secondary {
  background: #e7e7e7;
}

.top-bar-section ul li > a.button.secondary:hover {
  background: #cecece;
}

.top-bar-section ul li > a.button.success {
  background: #43ac6a;
}

.top-bar-section ul li > a.button.success:hover {
  background: #358854;
}

.top-bar-section ul li > a.button.alert {
  background: #f04124;
}

.top-bar-section ul li > a.button.alert:hover {
  background: #d42b0f;
}

.top-bar-section ul li:hover > a {
  background: transparent;
  color: #403933;
}

.top-bar-section ul li.active > a {
  background: transparent;
  color: #403933;
}

.top-bar-section ul li.active > a:hover {
  background: transparent;
  color: #fff;
}

.top-bar-section .has-form {
  padding: 17.66667px;
}

.top-bar-section .has-dropdown {
  position: relative;
}

.top-bar-section .has-dropdown > a::after {
  margin-right: 17.66667px;
  margin-top: -4.5px;
  position: absolute;
  right: 0;
  top: 50%;
}

.top-bar-section .has-dropdown.moved {
  position: static;
}

.top-bar-section .has-dropdown.moved > .dropdown {
  display: block;
}

.top-bar-section .dropdown {
  display: none;
  left: 100%;
  position: absolute;
  top: 0;
  z-index: 99;
}

.top-bar-section .dropdown li {
  height: auto;
  width: 100%;
}

.top-bar-section .dropdown li a {
  font-weight: normal;
  padding: 8px 17.66667px;
}

.top-bar-section .dropdown li a.parent-link {
  font-weight: normal;
}

.top-bar-section .dropdown li.title h5 {
  margin-bottom: 0;
}

.top-bar-section .dropdown li.title h5 a {
  color: #403933;
  display: block;
  line-height: 26.5px;
}

.top-bar-section .dropdown li.has-form {
  padding: 8px 17.66667px;
}

.top-bar-section .dropdown li .button {
  top: auto;
}

.top-bar-section .dropdown label {
  color: #777;
  font-size: 0.625rem;
  font-weight: bold;
  margin-bottom: 0;
  padding: 8px 17.66667px 2px;
  text-transform: uppercase;
}

.js-generated {
  display: block;
}

@media only screen and (min-width: 64.063em) {
  .top-bar {
    background: rgba(255, 255, 255, 0);
    overflow: visible;
    *zoom: 1;
  }

  .top-bar::before,
  .top-bar::after {
    content: ' ';
    display: table;
  }

  .top-bar::after {
    clear: both;
  }

  .top-bar .toggle-topbar {
    display: none;
  }

  .top-bar .title-area {
    float: left;
  }

  .top-bar .name h1 a {
    width: auto;
  }

  .top-bar input,
  .top-bar .button {
    font-size: 0.875rem;
    position: relative;
    top: 7px;
  }

  .top-bar.expanded {
    background: rgba(255, 255, 255, 0);
  }

  .contain-to-grid .top-bar {
    margin: 0 auto;
    max-width: 150rem;
  }

  .top-bar-section {
    left: 0 !important;
    transition: none;
  }

  .top-bar-section ul {
    display: inline;
    height: auto !important;
    width: auto;
  }

  .top-bar-section ul li {
    float: left;
  }

  .top-bar-section ul li .js-generated {
    display: none;
  }

  .top-bar-section li.hover > a:not(.button) {
    background: transparent;
    color: #403933;
  }

  .top-bar-section li:not(.has-form) a:not(.button) {
    background: rgba(255, 255, 255, 0);
    line-height: 53px;
    padding: 0 1vw;
  }

  .top-bar-section li:not(.has-form) a:not(.button):hover {
    background: transparent;
  }

  .top-bar-section li.active:not(.has-form) a:not(.button) {
    background: transparent;
    color: #403933;
    line-height: 53px;
    padding: 0 17.66667px;
  }

  .top-bar-section li.active:not(.has-form) a:not(.button):hover {
    background: transparent;
  }

  .top-bar-section .has-dropdown.moved {
    position: relative;
  }

  .top-bar-section .has-dropdown.moved > .dropdown {
    display: none;
  }

  .top-bar-section .has-dropdown.hover > .dropdown,
  .top-bar-section .has-dropdown.not-click:hover > .dropdown {
    display: block;
  }

  .top-bar-section .has-dropdown .dropdown li.has-dropdown > a::after {
    border: 0;
    content: '\00bb';
    line-height: 1.2;
    margin-top: -2px;
    right: 5px;
    top: 1rem;
  }

  .top-bar-section .dropdown {
    background: transparent;
    left: 0;
    min-width: 100%;
    top: auto;
  }

  .top-bar-section .dropdown li a {
    background: rgba(255, 255, 255, 0);
    color: #403933;
    line-height: 1;
    padding: 12px 17.66667px;
    white-space: nowrap;
  }

  .top-bar-section .dropdown li label {
    background: #333;
    white-space: nowrap;
  }

  .top-bar-section .dropdown li .dropdown {
    left: 100%;
    top: 0;
  }

  .top-bar-section > ul > .divider,
  .top-bar-section > ul > [role='separator'] {
    border-bottom: 0;
    border-right: solid 1px rgba(255, 255, 255, 0);
    border-top: 0;
    clear: none;
    height: 53px;
    width: 0;
  }

  .top-bar-section .has-form {
    background: rgba(255, 255, 255, 0);
    height: 53px;
    padding: 0 17.66667px;
  }

  .top-bar-section .right li .dropdown,
  .top-bar-section .kadi-row-product .kadi-product-desc .icons div li .dropdown,
  .kadi-row-product .kadi-product-desc .icons .top-bar-section div li .dropdown {
    left: auto;
    right: 0;
  }

  /* stylelint-disable selector-max-compound-selectors */
  .top-bar-section .right li .dropdown li .dropdown,
  .top-bar-section .kadi-row-product .kadi-product-desc .icons div li .dropdown li .dropdown,
  .kadi-row-product .kadi-product-desc .icons .top-bar-section div li .dropdown li .dropdown {
    right: 100%;
  }

  .top-bar-section .left li .dropdown,
  .top-bar-section .kadi-row-product .product-preparation-1 li .dropdown,
  .kadi-row-product .top-bar-section .product-preparation-1 li .dropdown,
  .top-bar-section .kadi-row-product .product-preparation-2 li .dropdown,
  .kadi-row-product .top-bar-section .product-preparation-2 li .dropdown,
  .top-bar-section .kadi-row-product .product-preparation-3 li .dropdown,
  .kadi-row-product .top-bar-section .product-preparation-3 li .dropdown,
  .top-bar-section .kadi-row-product .product-preparation-4 li .dropdown,
  .kadi-row-product .top-bar-section .product-preparation-4 li .dropdown,
  .top-bar-section .kadi-row-product .product-preparation-5 li .dropdown,
  .kadi-row-product .top-bar-section .product-preparation-5 li .dropdown {
    left: 0;
    right: auto;
  }

  .top-bar-section .left li .dropdown li .dropdown,
  .top-bar-section .kadi-row-product .product-preparation-1 li .dropdown li .dropdown,
  .kadi-row-product .top-bar-section .product-preparation-1 li .dropdown li .dropdown,
  .top-bar-section .kadi-row-product .product-preparation-2 li .dropdown li .dropdown,
  .kadi-row-product .top-bar-section .product-preparation-2 li .dropdown li .dropdown,
  .top-bar-section .kadi-row-product .product-preparation-3 li .dropdown li .dropdown,
  .kadi-row-product .top-bar-section .product-preparation-3 li .dropdown li .dropdown,
  .top-bar-section .kadi-row-product .product-preparation-4 li .dropdown li .dropdown,
  .kadi-row-product .top-bar-section .product-preparation-4 li .dropdown li .dropdown,
  .top-bar-section .kadi-row-product .product-preparation-5 li .dropdown li .dropdown,
  .kadi-row-product .top-bar-section .product-preparation-5 li .dropdown li .dropdown {
    left: 100%;
  }

  .no-js .top-bar-section ul li:hover > a {
    background: transparent;
    color: #403933;
  }

  .no-js .top-bar-section ul li:active > a {
    background: transparent;
    color: #403933;
  }

  .no-js .top-bar-section .has-dropdown:hover > .dropdown {
    display: block;
  }
}

.show-for-small,
.show-for-small-only,
.show-for-medium-down,
.show-for-large-down,
.hide-for-medium,
.hide-for-medium-up,
.hide-for-medium-only,
.hide-for-large,
.hide-for-large-up,
.hide-for-large-only,
.hide-for-xlarge,
.hide-for-xlarge-up,
.hide-for-xlarge-only,
.hide-for-xxlarge-up,
.hide-for-xxlarge-only {
  display: inherit !important;
}

.hide-for-small,
.hide-for-small-only,
.hide-for-medium-down,
.show-for-medium,
.show-for-medium-up,
.kadi-row-product div.dl-widget-link,
.sv-widget div.logo-bar-wrapper,
.show-for-medium-only,
.hide-for-large-down,
.show-for-large,
.show-for-large-up,
.show-for-large-only,
.show-for-xlarge,
.show-for-xlarge-up,
.show-for-xlarge-only,
.show-for-xxlarge-up,
.show-for-xxlarge-only {
  display: none !important;
}

table.show-for-small,
table.show-for-small-only,
table.show-for-medium-down,
table.show-for-large-down,
table.hide-for-medium,
table.hide-for-medium-up,
table.hide-for-medium-only,
table.hide-for-large,
table.hide-for-large-up,
table.hide-for-large-only,
table.hide-for-xlarge,
table.hide-for-xlarge-up,
table.hide-for-xlarge-only,
table.hide-for-xxlarge-up,
table.hide-for-xxlarge-only {
  display: table;
}

thead.show-for-small,
thead.show-for-small-only,
thead.show-for-medium-down,
thead.show-for-large-down,
thead.hide-for-medium,
thead.hide-for-medium-up,
thead.hide-for-medium-only,
thead.hide-for-large,
thead.hide-for-large-up,
thead.hide-for-large-only,
thead.hide-for-xlarge,
thead.hide-for-xlarge-up,
thead.hide-for-xlarge-only,
thead.hide-for-xxlarge-up,
thead.hide-for-xxlarge-only {
  display: table-header-group !important;
}

tbody.show-for-small,
tbody.show-for-small-only,
tbody.show-for-medium-down,
tbody.show-for-large-down,
tbody.hide-for-medium,
tbody.hide-for-medium-up,
tbody.hide-for-medium-only,
tbody.hide-for-large,
tbody.hide-for-large-up,
tbody.hide-for-large-only,
tbody.hide-for-xlarge,
tbody.hide-for-xlarge-up,
tbody.hide-for-xlarge-only,
tbody.hide-for-xxlarge-up,
tbody.hide-for-xxlarge-only {
  display: table-row-group !important;
}

tr.show-for-small,
tr.show-for-small-only,
tr.show-for-medium-down,
tr.show-for-large-down,
tr.hide-for-medium,
tr.hide-for-medium-up,
tr.hide-for-medium-only,
tr.hide-for-large,
tr.hide-for-large-up,
tr.hide-for-large-only,
tr.hide-for-xlarge,
tr.hide-for-xlarge-up,
tr.hide-for-xlarge-only,
tr.hide-for-xxlarge-up,
tr.hide-for-xxlarge-only {
  display: table-row !important;
}

td.show-for-small,
td.show-for-small-only,
td.show-for-medium-down,
td.show-for-large-down,
td.hide-for-medium,
td.hide-for-medium-up,
td.hide-for-large,
td.hide-for-large-up,
td.hide-for-xlarge,
td.hide-for-xlarge-up,
td.hide-for-xxlarge-up,
th.show-for-small,
th.show-for-small-only,
th.show-for-medium-down,
th.show-for-large-down,
th.hide-for-medium,
th.hide-for-medium-up,
th.hide-for-large,
th.hide-for-large-up,
th.hide-for-xlarge,
th.hide-for-xlarge-up,
th.hide-for-xxlarge-up {
  display: table-cell !important;
}

@media only screen and (min-width: 50.063em) {
  .hide-for-small,
  .hide-for-small-only,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-medium-up,
  .kadi-row-product div.dl-widget-link,
  .sv-widget div.logo-bar-wrapper,
  .sv-widget div.footer-logo,
  .show-for-medium-only,
  .hide-for-large,
  .hide-for-large-up,
  .hide-for-large-only,
  .hide-for-xlarge,
  .hide-for-xlarge-up,
  .hide-for-xlarge-only,
  .hide-for-xxlarge-up,
  .hide-for-xxlarge-only {
    display: inherit !important;
  }

  .show-for-small,
  .show-for-small-only,
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-medium-up,
  .hide-for-medium-only,
  .hide-for-large-down,
  .show-for-large,
  .show-for-large-up,
  .show-for-large-only,
  .show-for-xlarge,
  .show-for-xlarge-up,
  .show-for-xlarge-only,
  .show-for-xxlarge-up,
  .show-for-xxlarge-only {
    display: none !important;
  }

  table.hide-for-small,
  table.hide-for-small-only,
  table.show-for-medium,
  table.show-for-medium-down,
  table.show-for-medium-up,
  table.show-for-medium-only,
  table.hide-for-large,
  table.hide-for-large-up,
  table.hide-for-large-only,
  table.hide-for-xlarge,
  table.hide-for-xlarge-up,
  table.hide-for-xlarge-only,
  table.hide-for-xxlarge-up,
  table.hide-for-xxlarge-only {
    display: table;
  }

  thead.hide-for-small,
  thead.hide-for-small-only,
  thead.show-for-medium,
  thead.show-for-medium-down,
  thead.show-for-medium-up,
  thead.show-for-medium-only,
  thead.hide-for-large,
  thead.hide-for-large-up,
  thead.hide-for-large-only,
  thead.hide-for-xlarge,
  thead.hide-for-xlarge-up,
  thead.hide-for-xlarge-only,
  thead.hide-for-xxlarge-up,
  thead.hide-for-xxlarge-only {
    display: table-header-group !important;
  }

  tbody.hide-for-small,
  tbody.hide-for-small-only,
  tbody.show-for-medium,
  tbody.show-for-medium-down,
  tbody.show-for-medium-up,
  tbody.show-for-medium-only,
  tbody.hide-for-large,
  tbody.hide-for-large-up,
  tbody.hide-for-large-only,
  tbody.hide-for-xlarge,
  tbody.hide-for-xlarge-up,
  tbody.hide-for-xlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.hide-for-xxlarge-only {
    display: table-row-group !important;
  }

  tr.hide-for-small,
  tr.hide-for-small-only,
  tr.show-for-medium,
  tr.show-for-medium-down,
  tr.show-for-medium-up,
  tr.show-for-medium-only,
  tr.hide-for-large,
  tr.hide-for-large-up,
  tr.hide-for-large-only,
  tr.hide-for-xlarge,
  tr.hide-for-xlarge-up,
  tr.hide-for-xlarge-only,
  tr.hide-for-xxlarge-up,
  tr.hide-for-xxlarge-only {
    display: table-row !important;
  }

  td.hide-for-small,
  td.hide-for-small-only,
  td.show-for-medium,
  td.show-for-medium-down,
  td.show-for-medium-up,
  td.show-for-medium-only,
  td.hide-for-large,
  td.hide-for-large-up,
  td.hide-for-large-only,
  td.hide-for-xlarge,
  td.hide-for-xlarge-up,
  td.hide-for-xlarge-only,
  td.hide-for-xxlarge-up,
  td.hide-for-xxlarge-only,
  th.hide-for-small,
  th.hide-for-small-only,
  th.show-for-medium,
  th.show-for-medium-down,
  th.show-for-medium-up,
  th.show-for-medium-only,
  th.hide-for-large,
  th.hide-for-large-up,
  th.hide-for-large-only,
  th.hide-for-xlarge,
  th.hide-for-xlarge-up,
  th.hide-for-xlarge-only,
  th.hide-for-xxlarge-up,
  th.hide-for-xxlarge-only {
    display: table-cell !important;
  }
}

@media only screen and (min-width: 64.063em) {
  .hide-for-small,
  .hide-for-small-only,
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-medium-only,
  .show-for-medium-up,
  .kadi-row-product div.dl-widget-link,
  .sv-widget div.logo-bar-wrapper,
  .sv-widget div.footer-logo,
  .show-for-large,
  .show-for-large-up,
  .show-for-large-only,
  .hide-for-xlarge,
  .hide-for-xlarge-up,
  .hide-for-xlarge-only,
  .hide-for-xxlarge-up,
  .hide-for-xxlarge-only {
    display: inherit !important;
  }

  .show-for-small-only,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-medium-only,
  .hide-for-large,
  .hide-for-large-up,
  .hide-for-large-only,
  .show-for-xlarge,
  .show-for-xlarge-up,
  .show-for-xlarge-only,
  .show-for-xxlarge-up,
  .show-for-xxlarge-only {
    display: none !important;
  }

  table.hide-for-small,
  table.hide-for-small-only,
  table.hide-for-medium,
  table.hide-for-medium-down,
  table.hide-for-medium-only,
  table.show-for-medium-up,
  table.show-for-large,
  table.show-for-large-up,
  table.show-for-large-only,
  table.hide-for-xlarge,
  table.hide-for-xlarge-up,
  table.hide-for-xlarge-only,
  table.hide-for-xxlarge-up,
  table.hide-for-xxlarge-only {
    display: table;
  }

  thead.hide-for-small,
  thead.hide-for-small-only,
  thead.hide-for-medium,
  thead.hide-for-medium-down,
  thead.hide-for-medium-only,
  thead.show-for-medium-up,
  thead.show-for-large,
  thead.show-for-large-up,
  thead.show-for-large-only,
  thead.hide-for-xlarge,
  thead.hide-for-xlarge-up,
  thead.hide-for-xlarge-only,
  thead.hide-for-xxlarge-up,
  thead.hide-for-xxlarge-only {
    display: table-header-group !important;
  }

  tbody.hide-for-small,
  tbody.hide-for-small-only,
  tbody.hide-for-medium,
  tbody.hide-for-medium-down,
  tbody.hide-for-medium-only,
  tbody.show-for-medium-up,
  tbody.show-for-large,
  tbody.show-for-large-up,
  tbody.show-for-large-only,
  tbody.hide-for-xlarge,
  tbody.hide-for-xlarge-up,
  tbody.hide-for-xlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.hide-for-xxlarge-only {
    display: table-row-group !important;
  }

  tr.hide-for-small,
  tr.hide-for-small-only,
  tr.hide-for-medium,
  tr.hide-for-medium-down,
  tr.hide-for-medium-only,
  tr.show-for-medium-up,
  tr.show-for-large,
  tr.show-for-large-up,
  tr.show-for-large-only,
  tr.hide-for-xlarge,
  tr.hide-for-xlarge-up,
  tr.hide-for-xlarge-only,
  tr.hide-for-xxlarge-up,
  tr.hide-for-xxlarge-only {
    display: table-row !important;
  }

  td.hide-for-small,
  td.hide-for-small-only,
  td.hide-for-medium,
  td.hide-for-medium-down,
  td.hide-for-medium-only,
  td.show-for-medium-up,
  td.show-for-large,
  td.show-for-large-up,
  td.show-for-large-only,
  td.hide-for-xlarge,
  td.hide-for-xlarge-up,
  td.hide-for-xlarge-only,
  td.hide-for-xxlarge-up,
  td.hide-for-xxlarge-only,
  th.hide-for-small,
  th.hide-for-small-only,
  th.hide-for-medium,
  th.hide-for-medium-down,
  th.hide-for-medium-only,
  th.show-for-medium-up,
  th.show-for-large,
  th.show-for-large-up,
  th.show-for-large-only,
  th.hide-for-xlarge,
  th.hide-for-xlarge-up,
  th.hide-for-xlarge-only,
  th.hide-for-xxlarge-up,
  th.hide-for-xxlarge-only {
    display: table-cell !important;
  }
}

@media only screen and (min-width: 90.063em) {
  .hide-for-small,
  .hide-for-small-only,
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-medium-only,
  .show-for-medium-up,
  .kadi-row-product div.dl-widget-link,
  .sv-widget div.logo-bar-wrapper,
  .sv-widget div.footer-logo,
  .show-for-large-up,
  .hide-for-large-only,
  .show-for-xlarge,
  .show-for-xlarge-up,
  .show-for-xlarge-only,
  .hide-for-xxlarge-up,
  .hide-for-xxlarge-only {
    display: inherit !important;
  }

  .show-for-small-only,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-medium-only,
  .show-for-large,
  .show-for-large-only,
  .show-for-large-down,
  .hide-for-xlarge,
  .hide-for-xlarge-up,
  .hide-for-xlarge-only,
  .show-for-xxlarge-up,
  .show-for-xxlarge-only {
    display: none !important;
  }

  table.hide-for-small,
  table.hide-for-small-only,
  table.hide-for-medium,
  table.hide-for-medium-down,
  table.hide-for-medium-only,
  table.show-for-medium-up,
  table.show-for-large-up,
  table.hide-for-large-only,
  table.show-for-xlarge,
  table.show-for-xlarge-up,
  table.show-for-xlarge-only,
  table.hide-for-xxlarge-up,
  table.hide-for-xxlarge-only {
    display: table;
  }

  thead.hide-for-small,
  thead.hide-for-small-only,
  thead.hide-for-medium,
  thead.hide-for-medium-down,
  thead.hide-for-medium-only,
  thead.show-for-medium-up,
  thead.show-for-large-up,
  thead.hide-for-large-only,
  thead.show-for-xlarge,
  thead.show-for-xlarge-up,
  thead.show-for-xlarge-only,
  thead.hide-for-xxlarge-up,
  thead.hide-for-xxlarge-only {
    display: table-header-group !important;
  }

  tbody.hide-for-small,
  tbody.hide-for-small-only,
  tbody.hide-for-medium,
  tbody.hide-for-medium-down,
  tbody.hide-for-medium-only,
  tbody.show-for-medium-up,
  tbody.show-for-large-up,
  tbody.hide-for-large-only,
  tbody.show-for-xlarge,
  tbody.show-for-xlarge-up,
  tbody.show-for-xlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.hide-for-xxlarge-only {
    display: table-row-group !important;
  }

  tr.hide-for-small,
  tr.hide-for-small-only,
  tr.hide-for-medium,
  tr.hide-for-medium-down,
  tr.hide-for-medium-only,
  tr.show-for-medium-up,
  tr.show-for-large-up,
  tr.hide-for-large-only,
  tr.show-for-xlarge,
  tr.show-for-xlarge-up,
  tr.show-for-xlarge-only,
  tr.hide-for-xxlarge-up,
  tr.hide-for-xxlarge-only {
    display: table-row !important;
  }

  td.hide-for-small,
  td.hide-for-small-only,
  td.hide-for-medium,
  td.hide-for-medium-down,
  td.hide-for-medium-only,
  td.show-for-medium-up,
  td.show-for-large-up,
  td.hide-for-large-only,
  td.show-for-xlarge,
  td.show-for-xlarge-up,
  td.show-for-xlarge-only,
  td.hide-for-xxlarge-up,
  td.hide-for-xxlarge-only,
  th.hide-for-small,
  th.hide-for-small-only,
  th.hide-for-medium,
  th.hide-for-medium-down,
  th.hide-for-medium-only,
  th.show-for-medium-up,
  th.show-for-large-up,
  th.hide-for-large-only,
  th.show-for-xlarge,
  th.show-for-xlarge-up,
  th.show-for-xlarge-only,
  th.hide-for-xxlarge-up,
  th.hide-for-xxlarge-only {
    display: table-cell !important;
  }
}

@media only screen and (min-width: 120.063em) {
  .hide-for-small,
  .hide-for-small-only,
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-medium-only,
  .show-for-medium-up,
  .kadi-row-product div.dl-widget-link,
  .sv-widget div.logo-bar-wrapper,
  .sv-widget div.footer-logo,
  .show-for-large-up,
  .hide-for-large-only,
  .hide-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xxlarge-up,
  .show-for-xxlarge-only {
    display: inherit !important;
  }

  .show-for-small-only,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-medium-only,
  .show-for-large,
  .show-for-large-only,
  .show-for-large-down,
  .hide-for-xlarge,
  .show-for-xlarge-only,
  .hide-for-xxlarge-up,
  .hide-for-xxlarge-only {
    display: none !important;
  }

  table.hide-for-small,
  table.hide-for-small-only,
  table.hide-for-medium,
  table.hide-for-medium-down,
  table.hide-for-medium-only,
  table.show-for-medium-up,
  table.show-for-large-up,
  table.hide-for-xlarge-only,
  table.show-for-xlarge-up,
  table.show-for-xxlarge-up,
  table.show-for-xxlarge-only {
    display: table;
  }

  thead.hide-for-small,
  thead.hide-for-small-only,
  thead.hide-for-medium,
  thead.hide-for-medium-down,
  thead.hide-for-medium-only,
  thead.show-for-medium-up,
  thead.show-for-large-up,
  thead.hide-for-xlarge-only,
  thead.show-for-xlarge-up,
  thead.show-for-xxlarge-up,
  thead.show-for-xxlarge-only {
    display: table-header-group !important;
  }

  tbody.hide-for-small,
  tbody.hide-for-small-only,
  tbody.hide-for-medium,
  tbody.hide-for-medium-down,
  tbody.hide-for-medium-only,
  tbody.show-for-medium-up,
  tbody.show-for-large-up,
  tbody.hide-for-xlarge-only,
  tbody.show-for-xlarge-up,
  tbody.show-for-xxlarge-up,
  tbody.show-for-xxlarge-only {
    display: table-row-group !important;
  }

  tr.hide-for-small,
  tr.hide-for-small-only,
  tr.hide-for-medium,
  tr.hide-for-medium-down,
  tr.hide-for-medium-only,
  tr.show-for-medium-up,
  tr.show-for-large-up,
  tr.hide-for-xlarge-only,
  tr.show-for-xlarge-up,
  tr.show-for-xxlarge-up,
  tr.show-for-xxlarge-only {
    display: table-row !important;
  }

  td.hide-for-small,
  td.hide-for-small-only,
  td.hide-for-medium,
  td.hide-for-medium-down,
  td.hide-for-medium-only,
  td.show-for-medium-up,
  td.show-for-large-up,
  td.hide-for-xlarge-only,
  td.show-for-xlarge-up,
  td.show-for-xxlarge-up,
  td.show-for-xxlarge-only,
  th.hide-for-small,
  th.hide-for-small-only,
  th.hide-for-medium,
  th.hide-for-medium-down,
  th.hide-for-medium-only,
  th.show-for-medium-up,
  th.show-for-large-up,
  th.hide-for-xlarge-only,
  th.show-for-xlarge-up,
  th.show-for-xxlarge-up,
  th.show-for-xxlarge-only {
    display: table-cell !important;
  }
}

.show-for-landscape,
.hide-for-portrait {
  display: inherit !important;
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

table.hide-for-landscape,
table.show-for-portrait {
  display: table;
}

thead.hide-for-landscape,
thead.show-for-portrait {
  display: table-header-group !important;
}

tbody.hide-for-landscape,
tbody.show-for-portrait {
  display: table-row-group !important;
}

tr.hide-for-landscape,
tr.show-for-portrait {
  display: table-row !important;
}

td.hide-for-landscape,
td.show-for-portrait,
th.hide-for-landscape,
th.show-for-portrait {
  display: table-cell !important;
}

@media only screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: inherit !important;
  }

  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }

  table.show-for-landscape,
  table.hide-for-portrait {
    display: table;
  }

  thead.show-for-landscape,
  thead.hide-for-portrait {
    display: table-header-group !important;
  }

  tbody.show-for-landscape,
  tbody.hide-for-portrait {
    display: table-row-group !important;
  }

  tr.show-for-landscape,
  tr.hide-for-portrait {
    display: table-row !important;
  }

  td.show-for-landscape,
  td.hide-for-portrait,
  th.show-for-landscape,
  th.hide-for-portrait {
    display: table-cell !important;
  }
}

@media only screen and (orientation: portrait) {
  .show-for-portrait,
  .hide-for-landscape {
    display: inherit !important;
  }

  .hide-for-portrait,
  .show-for-landscape {
    display: none !important;
  }

  table.show-for-portrait,
  table.hide-for-landscape {
    display: table;
  }

  thead.show-for-portrait,
  thead.hide-for-landscape {
    display: table-header-group !important;
  }

  tbody.show-for-portrait,
  tbody.hide-for-landscape {
    display: table-row-group !important;
  }

  tr.show-for-portrait,
  tr.hide-for-landscape {
    display: table-row !important;
  }

  td.show-for-portrait,
  td.hide-for-landscape,
  th.show-for-portrait,
  th.hide-for-landscape {
    display: table-cell !important;
  }
}

.show-for-touch {
  display: none !important;
}

.hide-for-touch {
  display: inherit !important;
}

.touch .show-for-touch {
  display: inherit !important;
}

.touch .hide-for-touch {
  display: none !important;
}

table.hide-for-touch {
  display: table;
}

.touch table.show-for-touch {
  display: table;
}

thead.hide-for-touch {
  display: table-header-group !important;
}

.touch thead.show-for-touch {
  display: table-header-group !important;
}

tbody.hide-for-touch {
  display: table-row-group !important;
}

.touch tbody.show-for-touch {
  display: table-row-group !important;
}

tr.hide-for-touch {
  display: table-row !important;
}

.touch tr.show-for-touch {
  display: table-row !important;
}

td.hide-for-touch {
  display: table-cell !important;
}

.touch td.show-for-touch {
  display: table-cell !important;
}

th.hide-for-touch {
  display: table-cell !important;
}

.touch th.show-for-touch {
  display: table-cell !important;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.slideshow-wrapper {
  position: relative;
}

.slideshow-wrapper ul {
  list-style-type: none;
  margin: 0;
}

.slideshow-wrapper ul li,
.slideshow-wrapper ul li .orbit-caption {
  display: none;
}

.slideshow-wrapper ul li:first-child {
  display: block;
}

.slideshow-wrapper .orbit-container {
  background-color: transparent;
}

.slideshow-wrapper .orbit-container li {
  display: block;
}

.slideshow-wrapper .orbit-container li .orbit-caption {
  display: block;
}

.preloader {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-timing-function: linear;
  border: solid 3px;
  border-color: #555 #fff;
  border-radius: 1000px;
  display: block;
  height: 40px;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  width: 40px;
}

.orbit-container {
  background: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.orbit-container .orbit-slides-container {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  transform: translateZ(0);
}

.orbit-container .orbit-slides-container img {
  display: block;
  max-width: 100%;
}

.orbit-container .orbit-slides-container > * {
  margin-left: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.orbit-container .orbit-slides-container > *:first-child {
  margin-left: 0;
}

.orbit-container .orbit-slides-container > * .orbit-caption {
  background-color: rgba(51, 51, 51, 0.8);
  bottom: 0;
  color: #fff;
  font-size: 0.875rem;
  padding: 0.625rem 0.875rem;
  position: absolute;
  width: 100%;
}

.orbit-container .orbit-slide-number {
  background: rgba(0, 0, 0, 0);
  color: #fff;
  font-size: 12px;
  left: 10px;
  position: absolute;
  top: 10px;
  z-index: 10;
}

.orbit-container .orbit-slide-number span {
  font-weight: 700;
  padding: 0.3125rem;
}

.orbit-container .orbit-timer {
  height: 6px;
  position: absolute;
  right: 10px;
  top: 12px;
  width: 100px;
  z-index: 10;
}

.orbit-container .orbit-timer > span {
  border: solid 4px #fff;
  border-bottom: 0;
  border-top: 0;
  display: none;
  height: 14px;
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
}

.orbit-container .orbit-timer.paused > span {
  border: inset 8px;
  border-color: transparent transparent transparent #fff;
  border-right-style: solid;
  height: 14px;
  right: -4px;
  top: 0;
  width: 11px;
}

.orbit-container .orbit-timer.paused > span.dark {
  border-color: transparent transparent transparent #333;
}

.orbit-container:hover .orbit-timer > span {
  display: block;
}

.orbit-container .orbit-prev,
.orbit-container .orbit-next {
  background-color: rgba(255, 255, 255, 0.8);
  color: #fff;
  height: 60px;
  line-height: 50px;
  margin-top: -25px;
  position: absolute;
  text-indent: -9999px !important;
  top: 45%;
  width: 36px;
  z-index: 10;
}

.orbit-container .orbit-prev:hover,
.orbit-container .orbit-next:hover {
  background-color: rgba(158, 146, 131, 0.8);
}

.orbit-container .orbit-prev > span,
.orbit-container .orbit-next > span {
  border: inset 10px;
  display: block;
  height: 0;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 0;
}

.orbit-container .orbit-prev {
  left: 0;
}

.orbit-container .orbit-prev > span {
  border-color: transparent;
  border-right-color: #6d6458;
  border-right-style: solid;
}

.orbit-container .orbit-prev:hover > span {
  border-right-color: #fff;
}

.orbit-container .orbit-next {
  right: 0;
}

.orbit-container .orbit-next > span {
  border-color: transparent;
  border-left-color: #6d6458;
  border-left-style: solid;
  left: 50%;
  margin-left: -4px;
}

.orbit-container .orbit-next:hover > span {
  border-left-color: #fff;
}

.orbit-bullets-container {
  text-align: center;
}

.orbit-bullets {
  display: block;
  float: none;
  margin: 0 auto 30px;
  overflow: hidden;
  position: relative;
  text-align: center;
  top: 10px;
}

.orbit-bullets li {
  background: #ccc;
  border-radius: 1000px;
  display: inline-block;
  float: none;
  height: 0.5625rem;
  margin-right: 6px;
  width: 0.5625rem;
}

.orbit-bullets li.active {
  background: #999;
}

.orbit-bullets li:last-child {
  margin-right: 0;
}

.touch .orbit-container .orbit-prev,
.touch .orbit-container .orbit-next {
  display: none;
}

.touch .orbit-bullets {
  display: none;
}

@media only screen and (min-width: 50.063em) {
  .touch .orbit-container .orbit-prev,
  .touch .orbit-container .orbit-next {
    display: inherit;
  }

  .touch .orbit-bullets {
    display: block;
  }
}

@media only screen and (max-width: 50em) {
  .orbit-stack-on-small .orbit-slides-container {
    height: auto !important;
  }

  .orbit-stack-on-small .orbit-slides-container > * {
    margin-left: 0 !important;
    position: relative;
  }

  .orbit-stack-on-small .orbit-timer,
  .orbit-stack-on-small .orbit-next,
  .orbit-stack-on-small .orbit-prev,
  .orbit-stack-on-small .orbit-bullets {
    display: none;
  }
}

.inline-list {
  list-style: none;
  margin: 0 0 1.0625rem -1.375rem;
  overflow: hidden;
  padding: 0;
}

.inline-list > li {
  display: block;
  float: left;
  list-style: none;
  margin-left: 1.375rem;
}

.inline-list > li > * {
  display: block;
}

[class*='block-grid-'] {
  display: block;
  margin: 0 -0.625rem;
  padding: 0;
  *zoom: 1;
}

[class*='block-grid-']::before,
[class*='block-grid-']::after {
  content: ' ';
  display: table;
}

[class*='block-grid-']::after {
  clear: both;
}

[class*='block-grid-'] > li {
  display: block;
  float: left;
  height: auto;
  padding: 0 0.625rem 1.25rem;
}

@media only screen {
  .small-block-grid-1 > li {
    list-style: none;
    width: 100%;
  }

  .small-block-grid-1 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .small-block-grid-2 > li {
    list-style: none;
    width: 50%;
  }

  .small-block-grid-2 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .small-block-grid-3 > li {
    list-style: none;
    width: 33.33333%;
  }

  .small-block-grid-3 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .small-block-grid-4 > li {
    list-style: none;
    width: 25%;
  }

  .small-block-grid-4 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .small-block-grid-5 > li {
    list-style: none;
    width: 20%;
  }

  .small-block-grid-5 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .small-block-grid-6 > li {
    list-style: none;
    width: 16.66667%;
  }

  .small-block-grid-6 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }

  .small-block-grid-7 > li {
    list-style: none;
    width: 14.28571%;
  }

  .small-block-grid-7 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .small-block-grid-8 > li {
    list-style: none;
    width: 12.5%;
  }

  .small-block-grid-8 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .small-block-grid-9 > li {
    list-style: none;
    width: 11.11111%;
  }

  .small-block-grid-9 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .small-block-grid-10 > li {
    list-style: none;
    width: 10%;
  }

  .small-block-grid-10 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .small-block-grid-11 > li {
    list-style: none;
    width: 9.09091%;
  }

  .small-block-grid-11 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .small-block-grid-12 > li {
    list-style: none;
    width: 8.33333%;
  }

  .small-block-grid-12 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}

@media only screen and (min-width: 50.063em) {
  .medium-block-grid-1 > li {
    list-style: none;
    width: 100%;
  }

  .medium-block-grid-1 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .medium-block-grid-2 > li {
    list-style: none;
    width: 50%;
  }

  .medium-block-grid-2 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .medium-block-grid-3 > li {
    list-style: none;
    width: 33.33333%;
  }

  .medium-block-grid-3 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .medium-block-grid-4 > li {
    list-style: none;
    width: 25%;
  }

  .medium-block-grid-4 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .medium-block-grid-5 > li {
    list-style: none;
    width: 20%;
  }

  .medium-block-grid-5 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .medium-block-grid-6 > li {
    list-style: none;
    width: 16.66667%;
  }

  .medium-block-grid-6 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }

  .medium-block-grid-7 > li {
    list-style: none;
    width: 14.28571%;
  }

  .medium-block-grid-7 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .medium-block-grid-8 > li {
    list-style: none;
    width: 12.5%;
  }

  .medium-block-grid-8 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .medium-block-grid-9 > li {
    list-style: none;
    width: 11.11111%;
  }

  .medium-block-grid-9 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .medium-block-grid-10 > li {
    list-style: none;
    width: 10%;
  }

  .medium-block-grid-10 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .medium-block-grid-11 > li {
    list-style: none;
    width: 9.09091%;
  }

  .medium-block-grid-11 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .medium-block-grid-12 > li {
    list-style: none;
    width: 8.33333%;
  }

  .medium-block-grid-12 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}

@media only screen and (min-width: 64.063em) {
  .large-block-grid-1 > li {
    list-style: none;
    width: 100%;
  }

  .large-block-grid-1 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .large-block-grid-2 > li {
    list-style: none;
    width: 50%;
  }

  .large-block-grid-2 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .large-block-grid-3 > li {
    list-style: none;
    width: 33.33333%;
  }

  .large-block-grid-3 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .large-block-grid-4 > li {
    list-style: none;
    width: 25%;
  }

  .large-block-grid-4 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .large-block-grid-5 > li {
    list-style: none;
    width: 20%;
  }

  .large-block-grid-5 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .large-block-grid-6 > li {
    list-style: none;
    width: 16.66667%;
  }

  .large-block-grid-6 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }

  .large-block-grid-7 > li {
    list-style: none;
    width: 14.28571%;
  }

  .large-block-grid-7 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .large-block-grid-8 > li {
    list-style: none;
    width: 12.5%;
  }

  .large-block-grid-8 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .large-block-grid-9 > li {
    list-style: none;
    width: 11.11111%;
  }

  .large-block-grid-9 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .large-block-grid-10 > li {
    list-style: none;
    width: 10%;
  }

  .large-block-grid-10 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .large-block-grid-11 > li {
    list-style: none;
    width: 9.09091%;
  }

  .large-block-grid-11 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .large-block-grid-12 > li {
    list-style: none;
    width: 8.33333%;
  }

  .large-block-grid-12 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}

.button-sprite,
.sv-widget a.next-page,
.sv-widget .content-box a.next-page,
.sv-widget a.next-page:hover,
.sv-widget .content-box a.next-page:hover,
.sv-widget .colormode-1 a.next-page,
.sv-widget .colormode-1 a.next-page:hover,
.sv-widget .colormode-1 .kadi-col.colormode-1 a.next-page,
.sv-widget .colormode-1 .kadi-col.colormode-1 a.next-page:hover,
.sv-widget .colormode-1 .kadi-col.colormode-2 a.next-page,
.sv-widget .colormode-1 .kadi-col.colormode-2 a.next-page:hover,
.sv-widget .colormode-1 .kadi-col.colormode-3 a.next-page,
.sv-widget .colormode-1 .kadi-col.colormode-3 a.next-page:hover,
.sv-widget .colormode-1 .kadi-col.colormode-4 a.next-page,
.sv-widget .colormode-1 .kadi-col.colormode-4 a.next-page:hover,
.sv-widget .colormode-1 .kadi-col.colormode-4b a.next-page,
.sv-widget .colormode-1 .kadi-col.colormode-4b a.next-page:hover,
.sv-widget .colormode-1 .kadi-col.colormode-5 a.next-page,
.sv-widget .colormode-1 .kadi-col.colormode-5 a.next-page:hover,
.sv-widget .colormode-2 a.next-page,
.sv-widget .colormode-2 a.next-page:hover,
.sv-widget .colormode-2 .kadi-col.colormode-1 a.next-page,
.sv-widget .colormode-2 .kadi-col.colormode-1 a.next-page:hover,
.sv-widget .colormode-2 .kadi-col.colormode-2 a.next-page,
.sv-widget .colormode-2 .kadi-col.colormode-2 a.next-page:hover,
.sv-widget .colormode-2 .kadi-col.colormode-3 a.next-page,
.sv-widget .colormode-2 .kadi-col.colormode-3 a.next-page:hover,
.sv-widget .colormode-2 .kadi-col.colormode-4 a.next-page,
.sv-widget .colormode-2 .kadi-col.colormode-4 a.next-page:hover,
.sv-widget .colormode-2 .kadi-col.colormode-4b a.next-page,
.sv-widget .colormode-2 .kadi-col.colormode-4b a.next-page:hover,
.sv-widget .colormode-2 .kadi-col.colormode-5 a.next-page,
.sv-widget .colormode-2 .kadi-col.colormode-5 a.next-page:hover,
.sv-widget .colormode-3 a.next-page,
.sv-widget .colormode-3 a.next-page:hover,
.sv-widget .colormode-3 .kadi-col.colormode-1 a.next-page,
.sv-widget .colormode-3 .kadi-col.colormode-1 a.next-page:hover,
.sv-widget .colormode-3 .kadi-col.colormode-2 a.next-page,
.sv-widget .colormode-3 .kadi-col.colormode-2 a.next-page:hover,
.sv-widget .colormode-3 .kadi-col.colormode-3 a.next-page,
.sv-widget .colormode-3 .kadi-col.colormode-3 a.next-page:hover,
.sv-widget .colormode-3 .kadi-col.colormode-4 a.next-page,
.sv-widget .colormode-3 .kadi-col.colormode-4 a.next-page:hover,
.sv-widget .colormode-3 .kadi-col.colormode-4b a.next-page,
.sv-widget .colormode-3 .kadi-col.colormode-4b a.next-page:hover,
.sv-widget .colormode-3 .kadi-col.colormode-5 a.next-page,
.sv-widget .colormode-3 .kadi-col.colormode-5 a.next-page:hover,
.sv-widget .colormode-4 a.next-page,
.sv-widget .colormode-4 a.next-page:hover,
.sv-widget .colormode-4 .kadi-col.colormode-1 a.next-page,
.sv-widget .colormode-4 .kadi-col.colormode-1 a.next-page:hover,
.sv-widget .colormode-4 .kadi-col.colormode-2 a.next-page,
.sv-widget .colormode-4 .kadi-col.colormode-2 a.next-page:hover,
.sv-widget .colormode-4 .kadi-col.colormode-3 a.next-page,
.sv-widget .colormode-4 .kadi-col.colormode-3 a.next-page:hover,
.sv-widget .colormode-4 .kadi-col.colormode-4 a.next-page,
.sv-widget .colormode-4 .kadi-col.colormode-4 a.next-page:hover,
.sv-widget .colormode-4 .kadi-col.colormode-4b a.next-page,
.sv-widget .colormode-4 .kadi-col.colormode-4b a.next-page:hover,
.sv-widget .colormode-4 .kadi-col.colormode-5 a.next-page,
.sv-widget .colormode-4 .kadi-col.colormode-5 a.next-page:hover,
.sv-widget .colormode-4b a.next-page,
.sv-widget .colormode-4b a.next-page:hover,
.sv-widget .colormode-4b .kadi-col.colormode-1 a.next-page,
.sv-widget .colormode-4b .kadi-col.colormode-1 a.next-page:hover,
.sv-widget .colormode-4b .kadi-col.colormode-2 a.next-page,
.sv-widget .colormode-4b .kadi-col.colormode-2 a.next-page:hover,
.sv-widget .colormode-4b .kadi-col.colormode-3 a.next-page,
.sv-widget .colormode-4b .kadi-col.colormode-3 a.next-page:hover,
.sv-widget .colormode-4b .kadi-col.colormode-4 a.next-page,
.sv-widget .colormode-4b .kadi-col.colormode-4 a.next-page:hover,
.sv-widget .colormode-4b .kadi-col.colormode-4b a.next-page,
.sv-widget .colormode-4b .kadi-col.colormode-4b a.next-page:hover,
.sv-widget .colormode-4b .kadi-col.colormode-5 a.next-page,
.sv-widget .colormode-4b .kadi-col.colormode-5 a.next-page:hover,
.sv-widget .colormode-5 a.next-page,
.sv-widget .colormode-5 a.next-page:hover,
.sv-widget .colormode-5 .kadi-col.colormode-1 a.next-page,
.sv-widget .colormode-5 .kadi-col.colormode-1 a.next-page:hover,
.sv-widget .colormode-5 .kadi-col.colormode-2 a.next-page,
.sv-widget .colormode-5 .kadi-col.colormode-2 a.next-page:hover,
.sv-widget .colormode-5 .kadi-col.colormode-3 a.next-page,
.sv-widget .colormode-5 .kadi-col.colormode-3 a.next-page:hover,
.sv-widget .colormode-5 .kadi-col.colormode-4 a.next-page,
.sv-widget .colormode-5 .kadi-col.colormode-4 a.next-page:hover,
.sv-widget .colormode-5 .kadi-col.colormode-4b a.next-page,
.sv-widget .colormode-5 .kadi-col.colormode-4b a.next-page:hover,
.sv-widget .colormode-5 .kadi-col.colormode-5 a.next-page,
.sv-widget .colormode-5 .kadi-col.colormode-5 a.next-page:hover {
  background: url('../../images/ico/button-s7caace23b7.png') no-repeat;
}

.common-sprite,
.kadi-row-product .product-desc-toggle.expand,
.kadi-row-product .product-desc-toggle.collapse,
.sv-widget-asset ul.asset-info li.asset-info.movie > a.download,
.sv-slot-search .sv-widget-search-input label,
footer > div.get-in-touch div.contact ul a.mail,
footer > div.get-in-touch div.contact ul a.phone,
footer > div.get-in-touch div.fb a,
footer > div.get-in-touch div.yt a,
footer > div.get-in-touch div.instagram a,
footer > div.get-in-touch div.twitter a,
div#topnav-box div.expand,
div#topnav-box div.content .collapse,
div#topnav-box div.content ul li a.mail-sml,
div#topnav-box div.content ul li a.print,
div#topnav-box div.content ul li a.phone-sml,
a#scroll-top {
  background: url('../../images/ico/common-s89d0036bab.png') no-repeat;
}

.product-sprite,
.kadi-row-product .kadi-product-desc .labels div.label-1,
.kadi-row-product .kadi-product-desc .icons div.storage-label-1,
.kadi-row-product .kadi-product-desc .icons div.storage-label-1:hover,
.kadi-row-product .kadi-product-desc .icons div.storage-label-2,
.kadi-row-product .kadi-product-desc .icons div.storage-label-2:hover,
.kadi-row-product .kadi-product-desc .icons div.vegetarian-1,
.kadi-row-product .kadi-product-desc .icons div.vegetarian-1:hover,
.kadi-row-product .kadi-product-desc .icons div.vegetarian-2,
.kadi-row-product .kadi-product-desc .icons div.vegetarian-2:hover,
.kadi-row-product .kadi-product-desc .icons div.vegetarian-3,
.kadi-row-product .kadi-product-desc .icons div.vegetarian-3:hover,
.kadi-row-product .kadi-product-desc .icons div.vegetarian-4,
.kadi-row-product .kadi-product-desc .icons div.vegetarian-4:hover,
.kadi-row-product .kadi-product-desc .icons div.mail-contact a,
.kadi-row-product .kadi-product-desc .icons div.mail-contact a:hover,
.kadi-row-product div.dl-widget-link a,
.kadi-row-product div.dl-widget-link a:hover,
.kadi-row-product .product-preparation-1,
.kadi-row-product .product-preparation-1:hover,
.kadi-row-product .product-preparation-2,
.kadi-row-product .product-preparation-2:hover,
.kadi-row-product .product-preparation-3,
.kadi-row-product .product-preparation-3:hover,
.kadi-row-product .product-preparation-4,
.kadi-row-product .product-preparation-4:hover,
.kadi-row-product .product-preparation-5,
.kadi-row-product .product-preparation-5:hover,
.sv-widget-asset ul.asset-info div.info a.download,
.sv-widget-gallerydownload a.download {
  background: url('../../images/ico/product-sfe64fad29f.png') no-repeat;
}

.download-sprite,
.sv-widget-asset ul p.eco-desc,
.sv-widget-asset ul p.eco-desc.selected {
  background: url('../../images/ico/download-s0c97dcb0a4.png') no-repeat;
}

div.sv-inpage-menu-tooltip li a {
  font-size: 13px;
}

.ui-dialog {
  z-index: 10000;
}

.ui-dialog.ui-widget {
  font-size: 1rem;
}

.ui-dialog div.ym-form {
  background: none;
  border: 0;
  box-shadow: none;
}

.ui-dialog .jquery_tabs.sv-jquery-tabs {
  border: 0;
}

.ui-dialog .jquery_tabs .content {
  padding: 15px 0;
}

.ui-dialog .jquery_tabs ul.tabs-list li a {
  padding: 0.25em;
}

@media all {
  .ui-dialog * {
    margin: 0;
    padding: 0;
  }

  .ui-dialog option {
    padding-left: 0.4em;
  }

  .ui-dialog select {
    padding: 1px;
  }

  .ui-dialog * html body * {
    overflow: visible;
  }

  .ui-dialog body {
    background: #fff;
    color: #000;
    font-size: 100%;
    text-align: left;
  }

  .ui-dialog div:target {
    outline: 0 none;
  }

  .ui-dialog article,
  .ui-dialog aside,
  .ui-dialog details,
  .ui-dialog figcaption,
  .ui-dialog figure,
  .ui-dialog footer,
  .ui-dialog header,
  .ui-dialog hgroup,
  .ui-dialog nav,
  .ui-dialog section {
    display: block;
  }

  .ui-dialog audio,
  .ui-dialog canvas,
  .ui-dialog video {
    display: inline-block;
  }

  .ui-dialog audio:not([controls]) {
    display: none;
  }

  .ui-dialog [hidden] {
    display: none;
  }

  .ui-dialog input[type='search'] {
    //appearance: textfield;
  }

  .ui-dialog input[type='search']::-webkit-search-decoration {
    appearance: none;
  }

  .ui-dialog fieldset,
  .ui-dialog img {
    border: 0 solid;
  }

  .ui-dialog ul,
  .ui-dialog ol,
  .ui-dialog dl {
    margin: 0 0 1em 1em;
  }

  .ui-dialog li {
    line-height: 1.5em;
    margin-left: 0.8em;
  }

  .ui-dialog dt {
    font-weight: bold;
  }

  .ui-dialog dd {
    margin: 0 0 1em 0.8em;
  }

  .ui-dialog blockquote {
    margin: 0 0 1em 0.8em;
  }

  .ui-dialog q {
    quotes: none;
  }

  .ui-dialog blockquote::before,
  .ui-dialog blockquote::after,
  .ui-dialog q::before,
  .ui-dialog q::after {
    content: '';
  }

  .ui-dialog table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .ui-dialog .ym-clearfix::before {
    content: '';
    display: table;
  }

  .ui-dialog .ym-clearfix::after {
    clear: both;
    content: '.';
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
  }

  .ui-dialog .ym-contain-dt {
    display: table;
    overflow: visible;
    width: 100%;
  }

  .ui-dialog .ym-contain-oh {
    display: block;
    overflow: hidden;
    width: 100%;
  }

  .ui-dialog .ym-contain-fl {
    float: left;
    width: 100%;
  }

  .ui-dialog .ym-skip,
  .ui-dialog .ym-hideme,
  .ui-dialog .ym-print {
    left: -32768px;
    position: absolute;
    top: -32768px;
  }

  .ui-dialog .ym-skip:focus,
  .ui-dialog .ym-skip:active {
    left: 0;
    position: static;
    top: 0;
  }

  .ui-dialog .ym-skiplinks {
    left: -32768px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  .ui-dialog .ym-skiplinks .ym-skip:focus,
  .ui-dialog .ym-skiplinks .ym-skip:active {
    left: 32768px;
    outline: 0 none;
    position: absolute;
    width: 100%;
  }
}

@media screen, projection {
  .ui-dialog .ym-column {
    display: table;
    width: 100%;
  }

  .ui-dialog .ym-col1 {
    float: left;
    width: 20%;
  }

  .ui-dialog .ym-col2 {
    float: right;
    width: 20%;
  }

  .ui-dialog .ym-col3 {
    margin: 0 20%;
    width: auto;
  }

  .ui-dialog .ym-cbox .ym-cbox-left,
  .ui-dialog .ym-cbox-right {
    position: relative;
  }

  .ui-dialog .ym-cbox {
    padding: 0 10px;
  }

  .ui-dialog .ym-cbox-left {
    padding: 0 10px 0 0;
  }

  .ui-dialog .ym-cbox-right {
    padding: 0 0 0 10px;
  }

  .ui-dialog .ym-ie-clearing {
    display: none;
  }

  .ui-dialog .ym-grid {
    display: table;
    list-style-type: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    table-layout: fixed;
    width: 100%;
  }

  .ui-dialog .ym-gl {
    float: left;
    margin: 0;
  }

  .ui-dialog .ym-gr {
    float: right;
    margin: 0 0 0 -5px;
  }

  .ui-dialog .ym-g20 {
    width: 20%;
  }

  .ui-dialog .ym-g40 {
    width: 40%;
  }

  .ui-dialog .ym-g60 {
    width: 60%;
  }

  .ui-dialog .ym-g80 {
    width: 80%;
  }

  .ui-dialog .ym-g25 {
    width: 25%;
  }

  .ui-dialog .ym-g33 {
    width: 33.333%;
  }

  .ui-dialog .ym-g50 {
    width: 50%;
  }

  .ui-dialog .ym-g66 {
    width: 66.666%;
  }

  .ui-dialog .ym-g75 {
    width: 75%;
  }

  .ui-dialog .ym-g38 {
    width: 38.2%;
  }

  .ui-dialog .ym-g62 {
    width: 61.8%;
  }

  .ui-dialog .ym-gbox {
    padding: 0 10px;
  }

  .ui-dialog .ym-gbox-left {
    padding: 0 10px 0 0;
  }

  .ui-dialog .ym-gbox-right {
    padding: 0 0 0 10px;
  }

  .ui-dialog .ym-equalize {
    overflow: hidden;
  }

  .ui-dialog .ym-equalize > .ym-gl,
  .ui-dialog .ym-equalize > .ym-gr {
    display: table-cell;
    float: none;
    margin: 0;
    vertical-align: top;
  }

  .ui-dialog .ym-equalize > * > .ym-gbox,
  .ui-dialog .ym-equalize > * > .ym-gbox-left,
  .ui-dialog .ym-equalize > * > .ym-gbox-right {
    margin-bottom: -10000px;
    padding-bottom: 10000px;
  }
}

@media all {
  .ui-dialog .ym-form,
  .ui-dialog .ym-form fieldset {
    overflow: hidden;
  }

  .ui-dialog .ym-form div .ym-form label,
  .ui-dialog .ym-form .ym-message {
    display: block;
    position: relative;
  }

  .ui-dialog .ym-form .ym-fbox-check label {
    display: inline;
  }

  .ui-dialog .ym-form input,
  .ui-dialog .ym-form textarea {
    cursor: text;
  }

  .ui-dialog .ym-form input[type='checkbox'],
  .ui-dialog .ym-form input[type='radio'],
  .ui-dialog .ym-form select,
  .ui-dialog .ym-form label {
    cursor: pointer;
  }

  .ui-dialog .ym-form textarea {
    overflow: auto;
  }

  .ui-dialog .ym-form input[type=hidden] {
    display: none !important;
  }

  .ui-dialog .ym-form .ym-fbox-text::before,
  .ui-dialog .ym-form .ym-fbox-select::before,
  .ui-dialog .ym-form .ym-fbox-check::before,
  .ui-dialog .ym-form .ym-fbox-button::before {
    content: '';
    display: table;
  }

  .ui-dialog .ym-form .ym-fbox-text::after,
  .ui-dialog .ym-form .ym-fbox-select::after,
  .ui-dialog .ym-form .ym-fbox-check::after,
  .ui-dialog .ym-form .ym-fbox-button::after {
    clear: both;
    content: '.';
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
  }

  .ui-dialog .ym-form select,
  .ui-dialog .ym-form input,
  .ui-dialog .ym-form textarea {
    display: block;
    position: relative;
    width: 58.5%;
  }

  .ui-dialog .ym-form .ym-fbox-check input {
    display: inline;
    width: auto;
  }

  .ui-dialog .ym-form .ym-fbox-button input {
    display: inline;
    overflow: visible;
    width: auto;
  }

  .ui-dialog .ym-form .ym-fbox-check input:focus,
  .ui-dialog .ym-form .ym-fbox-check input:hover,
  .ui-dialog .ym-form .ym-fbox-check input:active {
    border: 0 none;
  }

  .ui-dialog .ym-full .ym-fbox-select select,
  .ui-dialog .ym-full .ym-fbox-text input,
  .ui-dialog .ym-full .ym-fbox-text textarea {
    margin-right: -3px;
    width: 94.2%;
  }

  .ui-dialog .ym-columnar .ym-fbox-text label,
  .ui-dialog .ym-columnar .ym-fbox-select label {
    display: inline;
    float: left;
    width: 30%;
  }

  .ui-dialog .ym-columnar .ym-fbox-check {
    position: relative;
  }

  .ui-dialog .ym-label {
    display: block;
  }

  .ui-dialog .ym-columnar .ym-fbox-check .ym-label {
    position: absolute;
    top: 0;
  }

  .ui-dialog .ym-columnar .ym-fbox-check input,
  .ui-dialog .ym-columnar .ym-error .ym-message {
    margin-left: 30%;
  }

  .ui-dialog .ym-columnar fieldset .ym-fbox-button,
  .ui-dialog fieldset.ym-columnar .ym-fbox-button {
    padding-left: 30%;
  }

  .ui-dialog .ym-columnar .ym-fbox-select select,
  .ui-dialog .ym-columnar .ym-fbox-text input,
  .ui-dialog .ym-columnar .ym-fbox-text textarea {
    float: left;
    margin-right: -3px;
    width: 67.2%;
  }

  .ui-dialog .ym-fbox-select select {
    width: 60%;
  }

  .ui-dialog .ym-full .ym-fbox-select select {
    width: 94.8%;
  }

  .ui-dialog .ym-columnar .ym-fbox-select select {
    width: 68.8%;
  }
}

@media print {
  .ui-dialog .ym-grid > .ym-gl,
  .ui-dialog .ym-grid > .ym-gr {
    display: table;
    overflow: visible;
  }

  .ui-dialog .ym-print {
    left: 0;
    position: static;
  }

  .ui-dialog .ym-noprint {
    display: none !important;
  }
}

@media screen and (min-width: 0) {
  .ui-dialog .ym-form input,
  .ui-dialog .ym-form input,
  .ui-dialog .ym-form textarea,
  .ui-dialog .ym-form select {
    box-sizing: border-box;
    width: 60%;
  }

  .ui-dialog .ym-columnar label {
    box-sizing: border-box;
  }

  .ui-dialog .ym-columnar .ym-fbox-select select,
  .ui-dialog .ym-columnar .ym-fbox-text input,
  .ui-dialog .ym-columnar .ym-fbox-text textarea {
    width: 70%;
  }

  .ui-dialog .ym-full .ym-fbox-select select,
  .ui-dialog .ym-full .ym-fbox-text input,
  .ui-dialog .ym-full .ym-fbox-text textarea {
    width: 100%;
  }
}

@media screen, projection {
  .ui-dialog .ym-form {
    background: #f4f4f4;
    border: 2px #fff solid;
    box-shadow: 0 0 4px #ddd;
    margin: 1.5em 0 0;
    padding: 0;
  }

  .ui-dialog .ym-form fieldset {
    background: transparent;
    margin: 0.75em 0;
    padding: 0 0.5em;
    position: static;
  }

  .ui-dialog .ym-form legend {
    background: transparent;
    color: #000;
    font-size: 1.2em;
    font-weight: 700;
    line-height: 1.25em;
    padding: 0 0.5em;
  }

  .ui-dialog .ym-form label {
    color: #666;
    line-height: 1.5em;
  }

  .ui-dialog .ym-label {
    color: #666;
    padding-top: 0.25em;
  }

  .ui-dialog .ym-form .ym-form div.ym-fbox-check:focus + label {
    color: #000;
  }

  .ui-dialog .ym-form div.ym-fbox-button {
    background: #ececec;
    border-top: 1px #e0e0e0 solid;
    margin: 0;
    padding: 0 1em 1.5em;
  }

  .ui-dialog .ym-form .ym-gbox {
    margin-right: 0;
    padding: 0 0.5em;
  }

  .ui-dialog .ym-form h6 {
    color: #000;
    margin: 1em 0 0 1em;
  }

  .ui-dialog .ym-form .ym-fbox-text input,
  .ui-dialog .ym-form .ym-fbox-text textarea {
    line-height: 1em;
    margin-top: -2px;
    padding: 0.25em 0.3em;
  }

  .ui-dialog .ym-form .ym-fbox-select select {
    line-height: 1em;
    margin-top: -2px;
    padding: 0.25em 2px 0.25em 1px;
  }

  .ui-dialog .ym-form input,
  .ui-dialog .ym-form textarea,
  .ui-dialog .ym-form select,
  .ui-dialog .ym-form optgroup {
    box-shadow: inset 0 0 4px #eee;
    font-family: Arial, sans-serif, sans-serif;
  }

  .ui-dialog .ym-form optgroup {
    font-style: normal;
    font-weight: 700;
  }

  .ui-dialog .ym-form .ym-fbox-text input,
  .ui-dialog .ym-form .ym-fbox-text textarea,
  .ui-dialog .ym-form .ym-fbox-select select {
    border: 1px solid #ddd;
  }

  .ui-dialog .ym-form .ym-message {
    color: #666;
    margin-bottom: 0.5em;
  }

  .ui-dialog .ym-form sup {
    color: #800;
    font-weight: 700;
  }

  .ui-dialog .ym-form input:valid,
  .ui-dialog .ym-form textarea:valid {
    background-color: #fff;
  }

  .ui-dialog .ym-form input:invalid,
  .ui-dialog .ym-form textarea:invalid {
    background-color: #fdd;
  }

  .ui-dialog .ym-form .ym-error label {
    color: #800;
    font-weight: 400;
  }

  .ui-dialog .ym-form .ym-error input,
  .ui-dialog .ym-form .ym-error select,
  .ui-dialog .ym-form .ym-error textarea {
    border: 1px #800 solid;
  }

  .ui-dialog .ym-form .ym-error input:hover,
  .ui-dialog .ym-form .ym-error input:focus,
  .ui-dialog .ym-form .ym-error select:hover,
  .ui-dialog .ym-form .ym-error select:focus,
  .ui-dialog .ym-form .ym-error textarea:hover,
  .ui-dialog .ym-form .ym-error textarea:focus {
    border: 1px #800 solid !important;
  }

  .ui-dialog .ym-form .ym-error .ym-message {
    color: #800;
  }

  .ui-dialog .ym-form div.ym-fbox-text input:focus,
  .ui-dialog .ym-form div select:focus,
  .ui-dialog .ym-form div textarea:focus,
  .ui-dialog .ym-form div.ym-fbox-text input:hover,
  .ui-dialog .ym-form div select:hover,
  .ui-dialog .ym-form div textarea:hover,
  .ui-dialog .ym-form div.ym-fbox-text input:active,
  .ui-dialog .ym-form div select:active,
  .ui-dialog .ym-form div textarea:active {
    background: #fff;
    border: 1px #888 solid;
  }

  .ui-dialog .ym-button,
  .ui-dialog .ym-form button,
  .ui-dialog .ym-form input[type='button'],
  .ui-dialog .ym-form input[type='reset'],
  .ui-dialog .ym-form input[type='submit'] {
    background-color: #ccc;
    background-image: linear-gradient(top, #eee, #ccc);
    border: 1px solid #777;
    border-radius: 0.2em;
    box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.8) inset, 0 1px 0 rgba(0, 0, 0, 0.15);
    color: #333 !important;
    cursor: pointer;
    display: inline-block;
    filter: progid:dximagetransform.Microsoft.gradient(startcolorstr='#eeeeee', endcolorstr='#cccccc');
    font: 1em/2em Arial, sans-serif;
    margin: 1.5em 0.75em 0 0;
    overflow: visible;
    padding: 0 1.5em;
    text-decoration: none !important;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
    white-space: nowrap;
  }

  .ui-dialog .ym-form button:hover,
  .ui-dialog .ym-form input[type='button']:hover,
  .ui-dialog .ym-form input[type='reset']:hover,
  .ui-dialog .ym-form input[type='submit']:hover,
  .ui-dialog .ym-button:hover {
    background-color: #ddd;
    background-image: linear-gradient(top, #fafafa, #ddd);
    filter: progid:dximagetransform.Microsoft.gradient(startcolorstr='#fafafa', endcolorstr='#dddddd');
  }

  .ui-dialog .ym-form button:active,
  .ui-dialog .ym-form input[type='button']:active,
  .ui-dialog .ym-form input[type='reset']:active,
  .ui-dialog .ym-form input[type='submit']:active,
  .ui-dialog .ym-button:active {
    background-color: #bbb;
    background-image: linear-gradient(top, #ccc, #bbb);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2) inset;
    filter: progid:dximagetransform.Microsoft.gradient(startcolorstr='#cccccc', endcolorstr='#bbbbbb');
    position: relative;
    top: 1px;
  }

  .ui-dialog .ym-form button:focus,
  .ui-dialog .ym-form input[type='button']:focus,
  .ui-dialog .ym-form input[type='reset']:focus,
  .ui-dialog .ym-form input[type='submit']:focus,
  .ui-dialog .ym-button:focus {
    background: #fafafa;
    outline: 0;
  }

  .ui-dialog .ym-form button::before,
  .ui-dialog .ym-button::before {
    background: #ccc;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0.15em 0 0 0.15em;
    box-shadow: 1px 0 0 rgba(0, 0, 0, 0.5), 2px 0 0 rgba(255, 255, 255, 0.5);
    float: left;
    font-size: 1.5em;
    margin: 0 1em 0 -1em;
    padding: 0 0.2em;
    pointer-events: none;
    text-align: center;
    width: 1em;
  }

  .ui-dialog .ym-add::before {
    content: '\271A';
  }

  .ui-dialog .ym-delete::before {
    content: '\2718';
  }

  .ui-dialog .ym-edit::before {
    content: '\270E';
  }

  .ui-dialog .ym-email::before {
    content: '\2709';
  }

  .ui-dialog .ym-like::before {
    content: '\2764';
  }

  .ui-dialog .ym-next::before {
    content: '\279C';
  }

  .ui-dialog .ym-play::before {
    content: '\25B6';
  }

  .ui-dialog .ym-save::before {
    content: '\2714';
  }

  .ui-dialog .ym-spark::before {
    content: '\2737';
  }

  .ui-dialog .ym-star::before {
    content: '\2605';
  }

  .ui-dialog .ym-form button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  .ui-dialog .ym-form input[type=button]::-moz-focus-inner,
  .ui-dialog .ym-form input[type=reset]::-moz-focus-inner,
  .ui-dialog .ym-form input[type=submit]::-moz-focus-inner {
    padding: 0.4em;
  }

  .ui-dialog .ym-form button[disabled],
  .ui-dialog .ym-form button[disabled]:hover,
  .ui-dialog .ym-form input[type=button][disabled],
  .ui-dialog .ym-form input[type=button][disabled]:hover,
  .ui-dialog .ym-form input[type=reset][disabled],
  .ui-dialog .ym-form input[type=reset][disabled]:hover,
  .ui-dialog .ym-form input[type=submit][disabled],
  .ui-dialog .ym-form input[type=submit][disabled]:hover,
  .ui-dialog .ym-button.ym-disabled,
  .ui-dialog .ym-button.ym-disabled:hover {
    background: #eee;
    border-color: #aaa;
    box-shadow: none;
    color: #aaa !important;
    cursor: default;
    position: static;
    text-shadow: none;
  }

  .ui-dialog * html .ym-button,
  .ui-dialog * html .ym-form button {
    background-color: #ccc;
    border: 1px solid #777;
    color: #333 !important;
    cursor: pointer;
    display: inline-block;
    filter: progid:dximagetransform.Microsoft.gradient(startcolorstr='#eeeeee', endcolorstr='#cccccc');
    font: 1em/2em Arial, sans-serif;
    margin: 1.5em 0.75em 0 0;
    overflow: visible;
    padding: 0 1.5em;
    text-decoration: none !important;
    white-space: nowrap;
  }

  .ui-dialog * html .ym-form button:focus,
  .ui-dialog * html .ym-button:focus {
    background: #fafafa;
    outline: 0;
  }

  .ui-dialog * html .ym-form button:hover,
  .ui-dialog * html .ym-button:hover {
    background-color: #ddd;
    filter: progid:dximagetransform.Microsoft.gradient(startcolorstr='#fafafa', endcolorstr='#dddddd');
  }

  .ui-dialog * html .ym-form button:active,
  .ui-dialog * html .ym-button:active {
    background-color: #bbb;
    filter: progid:dximagetransform.Microsoft.gradient(startcolorstr='#cccccc', endcolorstr='#bbbbbb');
    position: relative;
    top: 1px;
  }

  .ui-dialog * html .ym-button.ym-disabled,
  .ui-dialog * html .ym-button.ym-disabled:hover {
    background: #eee;
    border-color: #aaa;
    color: #aaa !important;
    cursor: default;
    position: static;
    text-shadow: none;
  }
}

.invisible,
.kadi-row-product .kadi-product-desc .icons div.mail-contact a span,
.kadi-row-product div.dl-widget-link a span,
.kadi-row-product .product-desc-toggle span,
.sv-widget-asset ul.asset-info div.info a.download span,
.sv-widget-gallerydownload a.download span,
footer > div.get-in-touch div.fb a span,
footer > div.get-in-touch div.yt a span,
footer > div.get-in-touch div.instagram a span,
footer > div.get-in-touch div.twitter a span {
  display: inline;
  height: 0;
  left: -1000px;
  overflow: hidden;
  position: absolute;
  width: 0;
}

.button-nwl-subscribe,
footer > div.get-in-touch div.nwl a.button {
  background: #403933;
  color: #fff !important;
  display: inline-block;
  padding: 3px 10px;
  text-decoration: none !important;
  text-transform: none;
}

.sv-widget .button-nwl-subscribe,
.sv-widget footer > div.get-in-touch div.nwl a.button,
footer > div.get-in-touch div.nwl .sv-widget a.button {
  padding: 3px 15px;
}

.sv-widget .button-nwl-subscribe:hover,
.sv-widget footer > div.get-in-touch div.nwl a.button:hover,
footer > div.get-in-touch div.nwl .sv-widget a.button:hover {
  background: #fff !important;
  color: #403933 !important;
}

@font-face {
  font-family: 'FS Sammy Regular';
  src: url('fonts/FSSammyWeb-Regular.eot');
  src: url('fonts/FSSammyWeb-Regular.eot?#iefix') format('embedded-opentype'), url('fonts/FSSammyWeb-Regular.woff') format('woff');
}

body.sv-ckeditor-form-edit > div.rent-object {
  margin: 0 auto;
  max-width: 150rem;
  width: 100%;
  *zoom: 1;
}

body.sv-ckeditor-form-edit > div.rent-object::before,
body.sv-ckeditor-form-edit > div.rent-object::after {
  content: ' ';
  display: table;
}

body.sv-ckeditor-form-edit > div.rent-object::after {
  clear: both;
}

body.sv-ckeditor-form-edit > div.rent-object > div.desc {
  float: left;
  left: auto;
  margin-left: 8.33333% !important;
  padding-left: 0;
  padding-right: 0;
  right: 0;
  width: 58.33333%;
}

body.sv-ckeditor-form-edit > div.rent-object > div.image {
  float: left;
  left: auto;
  margin-left: 0 !important;
  padding-left: 0;
  padding-right: 0;
  right: 0;
  width: 33.33333%;
}

body.sv-ckeditor-form-edit > div.portrait {
  padding: 0;
  position: relative;
  width: 75%;
}

body.sv-ckeditor-form-edit > div.portrait div.desc {
  bottom: 1.25rem;
  left: 1.25rem;
  position: absolute;
}

body.sv-ckeditor-form-edit > div.portrait div.desc h2 {
  margin-bottom: 5px;
}

body.sv-ckeditor-form-edit > div.portrait div.desc p.subtitle {
  font-family: 'FrutigerLTW01-75Black', sans-serif;
}

body.sv-ckeditor-form-edit > div.portrait div.desc p.contact {
  margin-bottom: 0;
  margin-top: 0.625rem;
}

body.sv-ckeditor-form-edit > div.portrait div.desc span {
  font-family: 'FrutigerLTW01-75Black', sans-serif;
  padding-right: 0.625rem;
}

body.sv-ckeditor-form-edit > div.portrait div.desc {
  position: static;
}

.kadi-row-product .kadi-col.title div {
  padding: 3.4375rem 0.875rem 0 4.375rem;
}

.kadi-row-product .kadi-col div.kadi-product-desc-short {
  min-height: 135px;
  padding: 3.4375rem 4.375rem 0 0.875rem;
}

.kadi-row-product .kadi-col div.kadi-product-desc-content {
  background: rgba(255, 255, 255, 0.85);
  padding: 0.875rem;
  position: absolute;
  z-index: 2;
}

.kadi-row-product .kadi-col div.kadi-product-desc-content h3 {
  font-size: 0.8125rem;
}

.kadi-row-product.product-group-overview > div {
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
}

@media only screen and (min-width: 64em) {
  .kadi-row-product.product-group-overview > div {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }
}

.kadi-row-product.product-group-overview ul {
  display: block;
  margin: 0 -0.625rem;
  padding: 0;
  *zoom: 1;
}

.kadi-row-product.product-group-overview ul::before,
.kadi-row-product.product-group-overview ul::after {
  content: ' ';
  display: table;
}

.kadi-row-product.product-group-overview ul::after {
  clear: both;
}

.kadi-row-product.product-group-overview ul > li {
  display: block;
  float: left;
  height: auto;
  padding: 0 0.625rem 1.25rem;
}

.kadi-row-product.product-group-overview ul > li {
  list-style: none;
  padding: 0 0.625rem 1.25rem;
  width: 50%;
}

.kadi-row-product.product-group-overview ul > li:nth-of-type(n) {
  clear: none;
}

.kadi-row-product.product-group-overview ul > li:nth-of-type(2n+1) {
  clear: both;
}

@media only screen and (min-width: 50.063em) {
  .kadi-row-product.product-group-overview ul {
    display: block;
    margin: 0 -0.625rem;
    padding: 0;
    *zoom: 1;
  }

  .kadi-row-product.product-group-overview ul::before,
  .kadi-row-product.product-group-overview ul::after {
    content: ' ';
    display: table;
  }

  .kadi-row-product.product-group-overview ul::after {
    clear: both;
  }

  .kadi-row-product.product-group-overview ul > li {
    display: block;
    float: left;
    height: auto;
    padding: 0 0.625rem 1.25rem;
  }

  .kadi-row-product.product-group-overview ul > li {
    list-style: none;
    padding: 0 0.625rem 1.25rem;
    width: 25%;
  }

  .kadi-row-product.product-group-overview ul > li:nth-of-type(n) {
    clear: none;
  }

  .kadi-row-product.product-group-overview ul > li:nth-of-type(4n+1) {
    clear: both;
  }
}

.kadi-row-product.product-group-overview ul li a {
  display: block;
  font-family: FrutigerLTW01-75Black, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
}

.kadi-row-product.product-group-overview ul li a.img {
  padding-bottom: 9px;
}

.kadi-row-product.product-group-overview ul li div.desc {
  min-height: 58px;
}

.kadi-row-product:nth-child(even) .kadi-product-desc {
  background: #dad6d0;
}

.kadi-row-product .kadi-product-desc {
  background: #f2f1ef;
}

.kadi-row-product .kadi-product-desc h2 {
  margin: 0 40px 0 0;
  text-transform: uppercase;
}

.kadi-row-product .kadi-product-desc > div {
  padding: 1.75rem 2.1875rem;
  position: relative;
}

@media only screen and (min-width: 64em) {
  .kadi-row-product .kadi-product-desc > div {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }
}

.kadi-row-product .kadi-product-desc .properties {
  display: table;
}

.kadi-row-product .kadi-product-desc .prop {
  display: table-row;
}

.kadi-row-product .kadi-product-desc .prop p {
  display: table-cell;
  padding-bottom: 0.375rem;
}

.kadi-row-product .kadi-product-desc .prop p.data-field-name {
  font-family: FrutigerLTW01-75Black, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
  padding-right: 1.75rem;
}

.kadi-row-product .kadi-product-desc .prop.sub p {
  padding-bottom: 0;
}

.kadi-row-product .kadi-product-desc .prop.sub p.data-field-name {
  font-family: FrutigerLTW01-55Roman, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
}

.kadi-row-product .kadi-product-desc .prop.subtitle p {
  margin: 0 40px 0 0;
  padding-bottom: 1.1875rem;
}

.kadi-row-product .kadi-product-desc .prop:last-child p {
  padding-bottom: 0;
}

.kadi-row-product .kadi-product-desc .labels {
  bottom: 1.75rem;
  position: absolute;
  right: 0.875rem;
}

.kadi-row-product .kadi-product-desc .labels div.label-1 {
  background-position: 0 0;
  height: 27px;
  width: 56px;
}

.kadi-row-product .kadi-product-desc .icons {
  position: absolute;
  right: 0.875rem;
  top: 1.75rem;
}

@media only screen and (max-width: 50em) {
  .modern-or-not-ie .kadi-row-product .kadi-product-desc .icons {
    top: 80px;
  }
}

.kadi-row-product .kadi-product-desc .icons div {
  height: 22px;
  margin-left: 0.875rem;
  width: 22px;
}

.kadi-row-product .kadi-product-desc .icons div.storage-label-1 {
  background-position: 0 -145px;
}

.kadi-row-product .kadi-product-desc .icons div.storage-label-1:hover {
  background-position: 0 -168px;
}

.kadi-row-product .kadi-product-desc .icons div.storage-label-2 {
  background-position: 0 -99px;
}

.kadi-row-product .kadi-product-desc .icons div.storage-label-2:hover {
  background-position: 0 -122px;
}

.kadi-row-product .kadi-product-desc .icons div.vegetarian-1 {
  background-position: 0 -519px;
  height: 22px;
  width: 32px;
}

.kadi-row-product .kadi-product-desc .icons div.vegetarian-1:hover {
  background-position: 0 -519px;
}

.kadi-row-product .kadi-product-desc .icons div.vegetarian-2 {
  background-position: 0 -473px;
  height: 22px;
  width: 40px;
}

.kadi-row-product .kadi-product-desc .icons div.vegetarian-2:hover {
  background-position: 0 -473px;
}

.kadi-row-product .kadi-product-desc .icons div.vegetarian-3 {
  background-position: 0 -496px;
  height: 22px;
  width: 40px;
}

.kadi-row-product .kadi-product-desc .icons div.vegetarian-3:hover {
  background-position: 0 -496px;
}

.kadi-row-product .kadi-product-desc .icons div.vegetarian-4 {
  background-position: 0 -450px;
  height: 22px;
  width: 47px;
}

.kadi-row-product .kadi-product-desc .icons div.vegetarian-4:hover {
  background-position: 0 -450px;
}

.kadi-row-product .kadi-product-desc .icons div.mail-contact {
  margin-left: 0.875rem;
}

.kadi-row-product .kadi-product-desc .icons div.mail-contact a {
  background-position: 0 -262px;
  display: block;
  height: 22px;
  width: 20px;
}

.kadi-row-product .kadi-product-desc .icons div.mail-contact a:hover {
  background-position: 0 -745px;
}

.kadi-row-product .prop.subtitle {
  font-family: FrutigerLTW01-75Black, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
}

.kadi-row-product div.dl-widget-link {
  position: absolute;
  right: 0.875rem;
  top: 1.75rem;
}

@media only screen and (min-width: 50.063em) {
  .kadi-row-product div.dl-widget-link {
    left: 0.875rem;
    right: auto;
  }
}

.kadi-row-product div.dl-widget-link a {
  background-position: 0 -788px;
  display: block;
  height: 22px;
  width: 12px;
}

.kadi-row-product div.dl-widget-link a:hover {
  background-position: 0 -562px;
}

.kadi-row-product .product-desc-toggle {
  display: block;
  height: 12px;
  width: 12px;
}

.kadi-row-product .product-desc-toggle.expand {
  background-position: 0 -5523px;
}

.kadi-row-product .product-desc-toggle.collapse {
  background-position: 0 -4682px;
}

.kadi-row-product .data-field-value.preparations {
  padding-top: 5px;
}

.kadi-row-product .product-preparation-1 {
  background-position: 0 -542px;
  height: 19px;
  margin-right: 10px;
  width: 23px;
}

.kadi-row-product .product-preparation-1:hover {
  background-position: 0 -625px;
}

.kadi-row-product .product-preparation-2 {
  background-position: 0 -665px;
  height: 19px;
  margin-right: 10px;
  width: 18px;
}

.kadi-row-product .product-preparation-2:hover {
  background-position: 0 -685px;
}

.kadi-row-product .product-preparation-3 {
  background-position: 0 -705px;
  height: 19px;
  margin-right: 10px;
  width: 19px;
}

.kadi-row-product .product-preparation-3:hover {
  background-position: 0 -768px;
}

.kadi-row-product .product-preparation-4 {
  background-position: 0 -585px;
  height: 19px;
  margin-right: 10px;
  width: 24px;
}

.kadi-row-product .product-preparation-4:hover {
  background-position: 0 -725px;
}

.kadi-row-product .product-preparation-5 {
  background-position: 0 -605px;
  height: 19px;
  margin-right: 10px;
  width: 23px;
}

.kadi-row-product .product-preparation-5:hover {
  background-position: 0 -645px;
}

div.flip-corner,
div.imagebox-prod-title {
  display: none;
}

@media only screen and (max-width: 50em) {
  .modern-or-not-ie .flipper {
    perspective: 600px;
    position: relative;
  }

  .modern-or-not-ie .flipper .kadi-col {
    backface-visibility: hidden;
    height: inherit;
    left: 0;
    position: absolute;
    top: 0;
    transform-style: preserve-3d;
    transition-duration: 0.6s;
    width: inherit;
  }

  .modern-or-not-ie .flipper .kadi-col.front {
    transform: rotateY(0deg);
    z-index: 900;
  }

  .modern-or-not-ie .flipper .kadi-col.back {
    transform: rotateY(-180deg);
    z-index: 800;
  }

  .modern-or-not-ie .flipper.flip .front {
    transform: rotateY(180deg);
    z-index: 900;
  }

  .modern-or-not-ie .flipper.flip .back {
    transform: rotateY(0deg);
    z-index: 1000;
  }

  .modern-or-not-ie .flipper div.imagebox-prod-title {
    display: block;
    left: 2.1875rem;
    position: absolute;
    top: 1.75rem;
  }

  .modern-or-not-ie .flipper div.imagebox-prod-title h2 {
    margin: 0 80px 0 0;
  }

  .modern-or-not-ie .flipper div.flip-corner {
    cursor: pointer;
    display: block;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

@media only screen and (max-width: 25em) {
  .modern-or-not-ie .kadi-product-image > img {
    padding-top: 80px;
  }
}

.sv-widget-asset > div {
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
  padding-top: 3.4375rem;
}

@media only screen and (min-width: 64em) {
  .sv-widget-asset > div {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }
}

.sv-widget-asset ul {
  list-style-type: none;
  margin: 0;
}

.sv-widget-asset ul > li {
  font-family: FrutigerLTW01-75Black, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
  text-transform: uppercase;
}

.sv-widget-asset ul > li > ul {
  padding-left: 45px;
}

.sv-widget-asset ul > li > ul > li {
  font-family: FrutigerLTW01-55Roman, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
  text-transform: none;
}

.sv-widget-asset ul > li > ul > li > p.eco-desc {
  text-decoration: underline;
}

.sv-widget-asset ul p.eco-desc {
  background-position: 0 -22px;
  cursor: pointer;
  line-height: 22px;
  padding-left: 45px;
}

.sv-widget-asset ul p.eco-desc.selected {
  background-position: 0 0;
}

.sv-widget-asset ul.asset-info {
  list-style: none;
  margin: 0 0 1.0625rem -1.375rem;
  overflow: hidden;
  padding: 0;
}

.sv-widget-asset ul.asset-info > li {
  display: block;
  float: left;
  list-style: none;
  margin-left: 1.375rem;
}

.sv-widget-asset ul.asset-info > li > * {
  display: block;
}

.sv-widget-asset ul.asset-info li {
  width: 200px;
}

.sv-widget-asset ul.asset-info li.asset-info.movie > a.download {
  background-position: 0 -3653px;
  display: block;
  height: 82px;
  width: 79px;
}

.sv-widget-asset ul.asset-info div.info {
  margin-top: 2px;
  padding: 2px;
  position: relative;
}

.sv-widget-asset ul.asset-info div.info p {
  margin: 0 16px 0 0;
}

.sv-widget-asset ul.asset-info div.info p:first-child {
  font-family: FrutigerLTW01-75Black, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
}

.sv-widget-asset ul.asset-info div.info a.download {
  background-position: 0 -788px;
  display: block;
  height: 22px;
  position: absolute;
  right: 4px;
  top: 2px;
  width: 12px;
}

.sv-slot-search .ym-fbox-check,
.sv-slot-search .sv-widget-search-submit,
.sv-slot-search .sv-widget-search-result-content,
.sv-slot-search .sv-widget-paging {
  display: none;
}

.sv-slot-search .form {
  margin: 0;
}

.sv-slot-search .sv-widget {
  background: none;
}

.sv-slot-search .sv-widget-search-input label {
  background-position: 3px -1532px;
  display: inline-block;
  height: 17px;
  width: 31px;
}

.sv-slot-search .ym-fbox-text {
  padding: 0 20px 10px;
}

.sv-slot-search .ym-fbox-text input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #403933;
  font-family: FrutigerLTW01-45Light, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
  width: 120px;
}

.lt-ie9.sv-view div#top {
  background: transparent url('../../images/bg-white-4ie.png');
  border-bottom: 1px solid #f2f1ef;
}

.lt-ie9.sv-view section.top-bar-section ul.dropdown li a {
  line-height: 25px;
}

.lt-ie9.sv-view section.top-bar-section ul.dropdown li:not(.has-form) a:not(.button) {
  line-height: 25px;
}

.lt-ie9.sv-view section#content {
  z-index: 1;
}

.lt-ie9.sv-view .kadi-row,
.lt-ie9.sv-view .kadi-row-product,
.lt-ie9.sv-view .kadi-row-contentlist,
.lt-ie9.sv-view section#content .wide,
section#content .lt-ie9.sv-view .wide {
  max-width: 2000px;
}

.lt-ie9.sv-view .kadi-row-product .kadi-col div.kadi-product-desc-content {
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#f7ffffff, endColorstr=#f7ffffff);
}

.lt-ie9.sv-view div.flip-corner,
.lt-ie9.sv-view div.imagebox-prod-title {
  display: none !important;
}

.sv-widget div.logo-bar-wrapper {
  border-bottom: 4px solid #b4a06e;
  border-top: 4px solid #b4a06e;
}

.sv-widget div.partner-bar {
  margin-right: 38px;
  max-width: 100%;
  padding-left: 35px;
  position: relative;
}

.sv-widget div.partner-bar ul {
  display: table;
  margin: 0;
  table-layout: fixed;
  width: 100%;
}

.sv-widget div.partner-bar ul li {
  display: table-cell;
  text-align: center;
  vertical-align: center;
}

.sv-widget div.partner-bar ul li:first-child {
  padding-left: 0;
  text-align: left;
}

.sv-widget div.partner-bar ul {
  margin: 20px 0;
}

.sv-widget div.partner-bar ul li {
  padding: 0 10px;
}

@media only screen and (min-width: 64em) {
  .sv-widget div.partner-bar {
    padding-left: 217px;
  }
}

.sv-widget div.partner-bar.presenting-partner {
  padding-left: 35px;
}

.sv-widget div.partner-bar.presenting-partner ul {
  display: table;
  margin: 0;
  table-layout: fixed;
  width: 100%;
}

.sv-widget div.partner-bar.presenting-partner ul li {
  display: table-cell;
  text-align: center;
  vertical-align: center;
}

.sv-widget div.partner-bar.presenting-partner ul li:first-child {
  padding-left: 0;
  text-align: left;
}

.sv-widget div.partner-bar.presenting-partner ul {
  margin: 20px 0;
}

.sv-widget div.partner-bar.presenting-partner li {
  padding: 0 22px;
}

.sv-widget div.partner-bar.presenting-partner li:first-child {
  padding-right: 42px;
}

.sv-widget div.partner-bar.presenting-partner li:last-child {
  padding-left: 42px;
}

@media only screen and (min-width: 50.063em) and (max-width: 64em) {
  .sv-widget div.partner-bar.presenting-partner li:nth-child(n+5) {
    display: none;
  }

  .sv-widget div.partner-bar.presenting-partner li:nth-child(4) {
    padding-left: 42px;
  }
}

.sv-widget div.partner-bar.presenting-partner li.label {
  color: #b4a06e;
  font-family: FrutigerLTW01-45Light, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
  font-size: 0.75rem;
  white-space: nowrap;
}

.sv-widget div.footer-logo {
  border-top: 4px solid #b4a06e;
  padding: 16px 0 0;
}

.sv-widget div.footer-logo div.list {
  display: table;
  margin: 0 auto;
  padding: 0 2.25rem 1.875rem;
}

.sv-widget div.footer-logo div.list ul {
  text-align: center;
}

.sv-widget div.footer-logo div.list ul {
  margin: 0;
}

.sv-widget div.footer-logo div.list ul li {
  display: inline-block;
  list-style-type: none;
  text-align: center;
}

.sv-widget div.footer-logo div.list ul li:first-child {
  padding-left: 0;
  text-align: left;
}

.sv-widget div.footer-logo div.list ul li:last-child {
  padding-right: 0;
}

.sv-widget div.footer-logo div.list ul li img {
  max-width: none;
}

.sv-widget div.footer-logo div.list p {
  color: #b4a06e;
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin: 0 0 8px;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
}

.sv-widget div.footer-logo div.list.partner li {
  padding: 0 20px 10px;
}

.sv-widget div.footer-logo div.list.sponsor li,
.sv-widget div.footer-logo div.list.media li {
  padding: 0 22px 10px;
}

.sv-widget div.footer-logo .product-and-timing-sponsors {
  display: table;
  margin: 0 auto;
}

.sv-widget div.footer-logo .product-and-timing-sponsors div.list {
  display: block;
}

.sv-widget div.footer-logo .product-sponsors {
  float: left;
}

.sv-widget div.footer-logo .product-sponsors li {
  padding: 0 22px 10px;
}

.sv-widget div.footer-logo .timing {
  float: left;
}

.sv-widget div.footer-logo .timing div.list {
  padding: 0;
}

.sv-widget div.content-logo-list div.title {
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
  padding-top: 3.4375rem;
  text-decoration: underline;
}

@media only screen and (min-width: 64em) {
  .sv-widget div.content-logo-list div.title {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }
}

.sv-widget div.content-logo-list ul {
  display: table;
  margin: 0;
  table-layout: fixed;
  width: 100%;
}

.sv-widget div.content-logo-list ul li {
  display: table-cell;
  text-align: center;
  vertical-align: center;
}

.sv-widget div.content-logo-list ul li:first-child {
  padding-left: 0;
  text-align: left;
}

.sv-widget div.content-logo-list li {
  padding: 0 0 2.1875rem;
}

@media only screen and (min-width: 64em) {
  .sv-widget div.content-logo-list li {
    padding: 0 2.1875rem 2.1875rem;
  }
}

section#content div.sv-widget-search select {
  background: none;
  width: auto;
}

section#content div.sv-widget-search h2.sv-widget-search-result-title {
  font-size: 1.5rem;
}

@media only screen and (min-width: 64em) {
  section#content div.sv-widget-search h2.sv-widget-search-result-title {
    font-size: 2.34375vw;
    line-height: 2.73438vw;
  }
}

section#content div.sv-widget-search .sv-widget-search-input {
  margin: 0 auto;
  max-width: 150rem;
  width: 100%;
  *zoom: 1;
}

section#content div.sv-widget-search .sv-widget-search-input::before,
section#content div.sv-widget-search .sv-widget-search-input::after {
  content: ' ';
  display: table;
}

section#content div.sv-widget-search .sv-widget-search-input::after {
  clear: both;
}

section#content div.sv-widget-search .sv-widget-search-input label {
  padding: 0;
}

section#content div.sv-widget-search .sv-widget-search-input .ym-fbox-text {
  float: left;
  left: auto;
  margin-left: 0 !important;
  padding-left: 0;
  padding-right: 0;
  right: 0;
  width: 33.33333%;
}

section#content div.sv-widget-search .ym-fbox-check label {
  float: left;
  left: auto;
  margin-left: 0 !important;
  padding-left: 0;
  padding-right: 0;
  right: 0;
  width: 8.33333%;
}

section#content div.sv-widget-search .ym-fbox-check input {
  float: left;
  left: auto;
  margin-left: 0 !important;
  padding-left: 0;
  padding-right: 0;
  right: 0;
  width: 8.33333%;
}

section#content div.sv-widget-search .ym-fbox-check .field > label {
  display: none;
}

section#content div.sv-widget-search .sv-wid-search-itemsperpage-label {
  line-height: 30px;
}

section#content div.sv-widget-search .sv-widget-search-result-entry-wrapper {
  background: #f2f1ef;
  margin-bottom: 20px;
  padding: 10px;
}

section#content div.sv-widget-search .sv-widget-search-result-entry-title {
  font-family: FrutigerLTW01-75Black, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.1875rem;
}

@media only screen and (min-width: 64em) {
  section#content div.sv-widget-search .sv-widget-search-result-entry-title {
    font-size: 1.5625vw;
    line-height: 2.14844vw;
  }
}

section#content div.sv-widget-search .sv-widget-search-result-entry-text a {
  color: #6d6458;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
}

@media only screen and (min-width: 64em) {
  section#content div.sv-widget-search .sv-widget-search-result-entry-text a {
    font-size: 1.26953vw;
    line-height: 1.85547vw;
  }
}

section#content div.sv-widget-search .sv-widget-search-result-entry-text a .sv-search-marked {
  font-family: FrutigerLTW01-75Black, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
}

section#content div.sv-widget-search .svPaging {
  margin: 0 auto;
  max-width: 150rem;
  width: 100%;
  *zoom: 1;
}

section#content div.sv-widget-search .svPaging::before,
section#content div.sv-widget-search .svPaging::after {
  content: ' ';
  display: table;
}

section#content div.sv-widget-search .svPaging::after {
  clear: both;
}

section#content div.sv-widget-search .svPaging .ym-g50 {
  float: left;
  left: auto;
  margin-left: 0 !important;
  padding-left: 0;
  padding-right: 0;
  right: 0;
  width: 50%;
}

section#content div.sv-widget-search .svPaging .ym-gbox {
  font-size: 0.8125rem;
  line-height: 1.1875rem;
}

@media only screen and (min-width: 64em) {
  section#content div.sv-widget-search .svPaging .ym-gbox {
    font-size: 1.26953vw;
    line-height: 1.85547vw;
  }
}

@media print {
  a[href]::after,
  abbr[title]::after {
    content: '';
  }

  blockquote {
    border: 0;
  }

  .top-bar .menu-icon {
    display: none;
  }

  footer div.nwl,
  footer div.fb,
  footer div.yt {
    display: none;
  }

  .kadi-row,
  .kadi-row-product,
  .kadi-row-contentlist,
  section#content .wide {
    background-image: none;
  }

  .kadi-row div.content-box,
  .kadi-row-product div.content-box,
  .kadi-row-contentlist div.content-box,
  section#content .wide div.content-box {
    height: auto !important;
  }

  .kadi-row-product {
    page-break-inside: avoid;
  }

  .kadi-row-product.product-group-overview {
    display: none;
  }

  .kadi-row-product .kadi-product-desc {
    min-height: 0 !important;
  }

  .lt-ie9 div#top {
    display: none;
  }

  .lt-ie9 .kadi-col {
    float: left;
    left: auto;
    margin-left: 0 !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    right: 0;
    width: 50%;
  }

  .lt-ie9 .quote {
    font-size: 20px;
    line-height: 1.2;
  }

  .lt-ie9 div#ie8-print-logo {
    display: block !important;
    margin-top: 10px;
  }
}

.sv-widget-gallerydownload {
  padding-left: 4.375rem;
  padding-right: 4.375rem;
  padding-top: 3.4375rem;
}

.sv-widget-gallerydownload ul {
  list-style-type: none;
  margin-left: 0;
}

.sv-widget-gallerydownload .gallery-info {
  display: block;
  float: left;
  list-style: none;
  margin-bottom: 1.375rem;
  margin-right: 2.75rem;
}

.sv-widget-gallerydownload img {
  float: left;
}

.sv-widget-gallerydownload a.download {
  background-position: 0 -788px;
  display: block;
  float: right;
  height: 22px;
  margin-left: 5px;
  width: 12px;
}

.plf-red div p {
  color: #e60000;
}

.plf-red div p a {
  text-decoration: underline;
}

.sv-inpage body {
  background: #f2f1ef;
}

strong,
b {
  font-family: 'FrutigerLTW01-75Black', sans-serif;
  font-weight: normal;
}

.ui-tooltip {
  background: #6d6458;
  border-color: #f2f1ef;
  color: #fff;
}

@media only screen and (min-width: 64em) {
  .ui-tooltip {
    font-size: 1.26953vw;
    line-height: 1.26953vw;
  }
}

#content .sv-widget {
  @-moz-document url-prefix('') {
    select {
      background: #fafafa;
    }

    select:hover {
      background: #f3f3f3;
    }
  }
}

section#content .sv-widget button,
section#content .sv-widget .button {
  appearance: none;
  background-color: #403933;
  border: 0 #332e29 solid;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: FrutigerLTW01-55Roman, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal !important;
  line-height: normal;
  margin: 0 0 1.25rem;
  padding: 1.0625rem 2rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color 300ms ease-out;
}

section#content .sv-widget button:hover,
section#content .sv-widget button:focus,
section#content .sv-widget .button:hover,
section#content .sv-widget .button:focus {
  background-color: #332e29;
}

section#content .sv-widget button:hover,
section#content .sv-widget button:focus,
section#content .sv-widget .button:hover,
section#content .sv-widget .button:focus {
  color: #fff;
}

section#content .sv-widget button.secondary,
section#content .sv-widget .button.secondary {
  background-color: #e7e7e7;
  border-color: #b9b9b9;
  color: #333;
}

section#content .sv-widget button.secondary:hover,
section#content .sv-widget button.secondary:focus,
section#content .sv-widget .button.secondary:hover,
section#content .sv-widget .button.secondary:focus {
  background-color: #b9b9b9;
}

section#content .sv-widget button.secondary:hover,
section#content .sv-widget button.secondary:focus,
section#content .sv-widget .button.secondary:hover,
section#content .sv-widget .button.secondary:focus {
  color: #333;
}

section#content .sv-widget button.success,
section#content .sv-widget .button.success {
  background-color: #43ac6a;
  border-color: #368a55;
  color: #fff;
}

section#content .sv-widget button.success:hover,
section#content .sv-widget button.success:focus,
section#content .sv-widget .button.success:hover,
section#content .sv-widget .button.success:focus {
  background-color: #368a55;
}

section#content .sv-widget button.success:hover,
section#content .sv-widget button.success:focus,
section#content .sv-widget .button.success:hover,
section#content .sv-widget .button.success:focus {
  color: #fff;
}

section#content .sv-widget button.alert,
section#content .sv-widget .button.alert {
  background-color: #f04124;
  border-color: #cf2a0e;
  color: #fff;
}

section#content .sv-widget button.alert:hover,
section#content .sv-widget button.alert:focus,
section#content .sv-widget .button.alert:hover,
section#content .sv-widget .button.alert:focus {
  background-color: #cf2a0e;
}

section#content .sv-widget button.alert:hover,
section#content .sv-widget button.alert:focus,
section#content .sv-widget .button.alert:hover,
section#content .sv-widget .button.alert:focus {
  color: #fff;
}

section#content .sv-widget button.large,
section#content .sv-widget .button.large {
  font-size: 1.25rem;
  padding: 1.125rem 2.25rem 1.1875rem;
}

section#content .sv-widget button.small,
section#content .sv-widget .button.small {
  font-size: 0.8125rem;
  padding: 0.875rem 1.75rem 0.9375rem;
}

section#content .sv-widget button.tiny,
section#content .sv-widget .button.tiny {
  font-size: 0.6875rem;
  padding: 0.625rem 1.25rem 0.6875rem;
}

section#content .sv-widget button.expand,
section#content .sv-widget .button.expand {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

section#content .sv-widget button.left-align,
section#content .sv-widget .button.left-align {
  text-align: left;
  text-indent: 0.75rem;
}

section#content .sv-widget button.right-align,
section#content .sv-widget .button.right-align {
  padding-right: 0.75rem;
  text-align: right;
}

section#content .sv-widget button.radius,
section#content .sv-widget .button.radius {
  border-radius: 3px;
}

section#content .sv-widget button.round,
section#content .sv-widget .button.round {
  border-radius: 1000px;
}

section#content .sv-widget button.disabled,
section#content .sv-widget button[disabled],
section#content .sv-widget .button.disabled,
section#content .sv-widget .button[disabled] {
  background-color: #403933;
  border-color: #332e29;
  box-shadow: none;
  color: #fff;
  cursor: default;
  opacity: 0.7;
}

section#content .sv-widget button.disabled:hover,
section#content .sv-widget button.disabled:focus,
section#content .sv-widget button[disabled]:hover,
section#content .sv-widget button[disabled]:focus,
section#content .sv-widget .button.disabled:hover,
section#content .sv-widget .button.disabled:focus,
section#content .sv-widget .button[disabled]:hover,
section#content .sv-widget .button[disabled]:focus {
  background-color: #332e29;
}

section#content .sv-widget button.disabled:hover,
section#content .sv-widget button.disabled:focus,
section#content .sv-widget button[disabled]:hover,
section#content .sv-widget button[disabled]:focus,
section#content .sv-widget .button.disabled:hover,
section#content .sv-widget .button.disabled:focus,
section#content .sv-widget .button[disabled]:hover,
section#content .sv-widget .button[disabled]:focus {
  color: #fff;
}

section#content .sv-widget button.disabled:hover,
section#content .sv-widget button.disabled:focus,
section#content .sv-widget button[disabled]:hover,
section#content .sv-widget button[disabled]:focus,
section#content .sv-widget .button.disabled:hover,
section#content .sv-widget .button.disabled:focus,
section#content .sv-widget .button[disabled]:hover,
section#content .sv-widget .button[disabled]:focus {
  background-color: #403933;
}

section#content .sv-widget button.disabled.secondary,
section#content .sv-widget button[disabled].secondary,
section#content .sv-widget .button.disabled.secondary,
section#content .sv-widget .button[disabled].secondary {
  background-color: #e7e7e7;
  border-color: #b9b9b9;
  box-shadow: none;
  color: #333;
  cursor: default;
  opacity: 0.7;
}

section#content .sv-widget button.disabled.secondary:hover,
section#content .sv-widget button.disabled.secondary:focus,
section#content .sv-widget button[disabled].secondary:hover,
section#content .sv-widget button[disabled].secondary:focus,
section#content .sv-widget .button.disabled.secondary:hover,
section#content .sv-widget .button.disabled.secondary:focus,
section#content .sv-widget .button[disabled].secondary:hover,
section#content .sv-widget .button[disabled].secondary:focus {
  background-color: #b9b9b9;
}

section#content .sv-widget button.disabled.secondary:hover,
section#content .sv-widget button.disabled.secondary:focus,
section#content .sv-widget button[disabled].secondary:hover,
section#content .sv-widget button[disabled].secondary:focus,
section#content .sv-widget .button.disabled.secondary:hover,
section#content .sv-widget .button.disabled.secondary:focus,
section#content .sv-widget .button[disabled].secondary:hover,
section#content .sv-widget .button[disabled].secondary:focus {
  color: #333;
}

section#content .sv-widget button.disabled.secondary:hover,
section#content .sv-widget button.disabled.secondary:focus,
section#content .sv-widget button[disabled].secondary:hover,
section#content .sv-widget button[disabled].secondary:focus,
section#content .sv-widget .button.disabled.secondary:hover,
section#content .sv-widget .button.disabled.secondary:focus,
section#content .sv-widget .button[disabled].secondary:hover,
section#content .sv-widget .button[disabled].secondary:focus {
  background-color: #e7e7e7;
}

section#content .sv-widget button.disabled.success,
section#content .sv-widget button[disabled].success,
section#content .sv-widget .button.disabled.success,
section#content .sv-widget .button[disabled].success {
  background-color: #43ac6a;
  border-color: #368a55;
  box-shadow: none;
  color: #fff;
  cursor: default;
  opacity: 0.7;
}

section#content .sv-widget button.disabled.success:hover,
section#content .sv-widget button.disabled.success:focus,
section#content .sv-widget button[disabled].success:hover,
section#content .sv-widget button[disabled].success:focus,
section#content .sv-widget .button.disabled.success:hover,
section#content .sv-widget .button.disabled.success:focus,
section#content .sv-widget .button[disabled].success:hover,
section#content .sv-widget .button[disabled].success:focus {
  background-color: #368a55;
}

section#content .sv-widget button.disabled.success:hover,
section#content .sv-widget button.disabled.success:focus,
section#content .sv-widget button[disabled].success:hover,
section#content .sv-widget button[disabled].success:focus,
section#content .sv-widget .button.disabled.success:hover,
section#content .sv-widget .button.disabled.success:focus,
section#content .sv-widget .button[disabled].success:hover,
section#content .sv-widget .button[disabled].success:focus {
  color: #fff;
}

section#content .sv-widget button.disabled.success:hover,
section#content .sv-widget button.disabled.success:focus,
section#content .sv-widget button[disabled].success:hover,
section#content .sv-widget button[disabled].success:focus,
section#content .sv-widget .button.disabled.success:hover,
section#content .sv-widget .button.disabled.success:focus,
section#content .sv-widget .button[disabled].success:hover,
section#content .sv-widget .button[disabled].success:focus {
  background-color: #43ac6a;
}

section#content .sv-widget button.disabled.alert,
section#content .sv-widget button[disabled].alert,
section#content .sv-widget .button.disabled.alert,
section#content .sv-widget .button[disabled].alert {
  background-color: #f04124;
  border-color: #cf2a0e;
  box-shadow: none;
  color: #fff;
  cursor: default;
  opacity: 0.7;
}

section#content .sv-widget button.disabled.alert:hover,
section#content .sv-widget button.disabled.alert:focus,
section#content .sv-widget button[disabled].alert:hover,
section#content .sv-widget button[disabled].alert:focus,
section#content .sv-widget .button.disabled.alert:hover,
section#content .sv-widget .button.disabled.alert:focus,
section#content .sv-widget .button[disabled].alert:hover,
section#content .sv-widget .button[disabled].alert:focus {
  background-color: #cf2a0e;
}

section#content .sv-widget button.disabled.alert:hover,
section#content .sv-widget button.disabled.alert:focus,
section#content .sv-widget button[disabled].alert:hover,
section#content .sv-widget button[disabled].alert:focus,
section#content .sv-widget .button.disabled.alert:hover,
section#content .sv-widget .button.disabled.alert:focus,
section#content .sv-widget .button[disabled].alert:hover,
section#content .sv-widget .button[disabled].alert:focus {
  color: #fff;
}

section#content .sv-widget button.disabled.alert:hover,
section#content .sv-widget button.disabled.alert:focus,
section#content .sv-widget button[disabled].alert:hover,
section#content .sv-widget button[disabled].alert:focus,
section#content .sv-widget .button.disabled.alert:hover,
section#content .sv-widget .button.disabled.alert:focus,
section#content .sv-widget .button[disabled].alert:hover,
section#content .sv-widget .button[disabled].alert:focus {
  background-color: #f04124;
}

@media only screen and (min-width: 50.063em) {
  section#content .sv-widget button,
  section#content .sv-widget .button {
    display: inline-block;
  }
}

section#content .sv-widget form {
  margin: 0 0 1rem;
}

section#content .sv-widget form .row .row {
  margin: 0 -0.5rem;
}

section#content .sv-widget form .row .row .column,
section#content .sv-widget form .row .row .columns {
  padding: 0 0.5rem;
}

section#content .sv-widget form .row .row.collapse {
  margin: 0;
}

section#content .sv-widget form .row .row.collapse .column,
section#content .sv-widget form .row .row.collapse .columns {
  padding: 0;
}

section#content .sv-widget form .row .row.collapse input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

section#content .sv-widget form .row input.column,
section#content .sv-widget form .row input.columns,
section#content .sv-widget form .row textarea.column,
section#content .sv-widget form .row textarea.columns {
  padding-left: 0.5rem;
}

section#content .sv-widget label {
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

section#content .sv-widget label.right {
  float: none;
  text-align: right;
}

section#content .sv-widget label.inline {
  margin: 0 0 1rem;
  padding: 0.625rem 0;
}

section#content .sv-widget label small {
  color: #676767;
  text-transform: capitalize;
}

section#content .sv-widget select {
  appearance: none !important;
  background: #fafafa url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iM3B4IiB2aWV3Qm94PSIwIDAgNiAzIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA2IDMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIHBvaW50cz0iNS45OTIsMCAyLjk5MiwzIC0wLjAwOCwwICIvPjwvc3ZnPg==') no-repeat;
  background-position-x: 97%;
  background-position-y: center;
  border: 1px solid #ccc;
  border-radius: 0;
  font-size: 0.875rem;
  padding: 0.5rem;
}

section#content .sv-widget select.radius {
  border-radius: 3px;
}

section#content .sv-widget select:hover {
  background: #f3f3f3 url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iM3B4IiB2aWV3Qm94PSIwIDAgNiAzIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA2IDMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIHBvaW50cz0iNS45OTIsMCAyLjk5MiwzIC0wLjAwOCwwICIvPjwvc3ZnPg==') no-repeat;
  background-position-x: 97%;
  background-position-y: center;
  border-color: #999;
}

section#content .sv-widget select::-ms-expand {
  display: none;
}

section#content .sv-widget .prefix,
section#content .sv-widget .postfix {
  border-style: solid;
  border-width: 1px;
  display: block;
  font-size: 0.875rem;
  height: 2.3125rem;
  line-height: 2.3125rem;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 2;
}

section#content .sv-widget .postfix.button {
  border: 0;
  line-height: 2.125rem;
  padding: 0;
  text-align: center;
}

section#content .sv-widget .prefix.button {
  border: 0;
  line-height: 2.125rem;
  padding: 0;
  text-align: center;
}

section#content .sv-widget .prefix.button.radius {
  border-radius: 3px 0 0 3px;
}

section#content .sv-widget .postfix.button.radius {
  border-radius: 0 3px 3px 0;
}

section#content .sv-widget .prefix.button.round {
  border-radius: 1000px 0 0 1000px;
}

section#content .sv-widget .postfix.button.round {
  border-radius: 0 1000px 1000px 0;
}

section#content .sv-widget span.prefix,
section#content .sv-widget label.prefix {
  background: #f2f2f2;
  border-color: #ccc;
  border-right: 0;
  color: #333;
}

section#content .sv-widget span.prefix.radius,
section#content .sv-widget label.prefix.radius {
  border-radius: 3px 0 0 3px;
}

section#content .sv-widget span.postfix,
section#content .sv-widget label.postfix {
  background: #f2f2f2;
  border-color: #ccc;
  border-left: 0;
  color: #333;
}

section#content .sv-widget span.postfix.radius,
section#content .sv-widget label.postfix.radius {
  border-radius: 0 3px 3px 0;
}

section#content .sv-widget input[type='text'],
section#content .sv-widget input[type='password'],
section#content .sv-widget input[type='date'],
section#content .sv-widget input[type='datetime'],
section#content .sv-widget input[type='datetime-local'],
section#content .sv-widget input[type='month'],
section#content .sv-widget input[type='week'],
section#content .sv-widget input[type='email'],
section#content .sv-widget input[type='number'],
section#content .sv-widget input[type='search'],
section#content .sv-widget input[type='tel'],
section#content .sv-widget input[type='time'],
section#content .sv-widget input[type='url'],
section#content .sv-widget textarea {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: #6d6458;
  display: block;
  font-family: inherit;
  font-size: 0.875rem;
  height: 2.3125rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  transition: -webkit-box-shadow 0.45s, border-color 0.45s ease-in-out;
  transition: -moz-box-shadow 0.45s, border-color 0.45s ease-in-out;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
  width: 100%;
}

section#content .sv-widget input[type='text']:focus,
section#content .sv-widget input[type='password']:focus,
section#content .sv-widget input[type='date']:focus,
section#content .sv-widget input[type='datetime']:focus,
section#content .sv-widget input[type='datetime-local']:focus,
section#content .sv-widget input[type='month']:focus,
section#content .sv-widget input[type='week']:focus,
section#content .sv-widget input[type='email']:focus,
section#content .sv-widget input[type='number']:focus,
section#content .sv-widget input[type='search']:focus,
section#content .sv-widget input[type='tel']:focus,
section#content .sv-widget input[type='time']:focus,
section#content .sv-widget input[type='url']:focus,
section#content .sv-widget textarea:focus {
  border-color: #999;
  box-shadow: 0 0 5px #999;
}

section#content .sv-widget input[type='text']:focus,
section#content .sv-widget input[type='password']:focus,
section#content .sv-widget input[type='date']:focus,
section#content .sv-widget input[type='datetime']:focus,
section#content .sv-widget input[type='datetime-local']:focus,
section#content .sv-widget input[type='month']:focus,
section#content .sv-widget input[type='week']:focus,
section#content .sv-widget input[type='email']:focus,
section#content .sv-widget input[type='number']:focus,
section#content .sv-widget input[type='search']:focus,
section#content .sv-widget input[type='tel']:focus,
section#content .sv-widget input[type='time']:focus,
section#content .sv-widget input[type='url']:focus,
section#content .sv-widget textarea:focus {
  background: #fafafa;
  border-color: #999;
  outline: none;
}

section#content .sv-widget input[type='text'][disabled],
section#content .sv-widget input[type='password'][disabled],
section#content .sv-widget input[type='date'][disabled],
section#content .sv-widget input[type='datetime'][disabled],
section#content .sv-widget input[type='datetime-local'][disabled],
section#content .sv-widget input[type='month'][disabled],
section#content .sv-widget input[type='week'][disabled],
section#content .sv-widget input[type='email'][disabled],
section#content .sv-widget input[type='number'][disabled],
section#content .sv-widget input[type='search'][disabled],
section#content .sv-widget input[type='tel'][disabled],
section#content .sv-widget input[type='time'][disabled],
section#content .sv-widget input[type='url'][disabled],
section#content .sv-widget textarea[disabled] {
  background-color: #ddd;
}

section#content .sv-widget input[type='text'].radius,
section#content .sv-widget input[type='password'].radius,
section#content .sv-widget input[type='date'].radius,
section#content .sv-widget input[type='datetime'].radius,
section#content .sv-widget input[type='datetime-local'].radius,
section#content .sv-widget input[type='month'].radius,
section#content .sv-widget input[type='week'].radius,
section#content .sv-widget input[type='email'].radius,
section#content .sv-widget input[type='number'].radius,
section#content .sv-widget input[type='search'].radius,
section#content .sv-widget input[type='tel'].radius,
section#content .sv-widget input[type='time'].radius,
section#content .sv-widget input[type='url'].radius,
section#content .sv-widget textarea.radius {
  border-radius: 3px;
}

section#content .sv-widget select {
  height: 2.3125rem;
}

section#content .sv-widget input[type='file'],
section#content .sv-widget input[type='checkbox'],
section#content .sv-widget input[type='radio'],
section#content .sv-widget select {
  margin: 0 0 1rem;
}

section#content .sv-widget input[type='checkbox'] + label,
section#content .sv-widget input[type='radio'] + label {
  display: inline-block;
  margin-bottom: 0;
  margin-left: 0.5rem;
  margin-right: 1rem;
  vertical-align: baseline;
}

section#content .sv-widget input[type='file'] {
  width: 100%;
}

section#content .sv-widget fieldset {
  border: solid 1px #ddd;
  margin: 1.125rem 0;
  padding: 1.25rem;
}

section#content .sv-widget fieldset legend {
  background: #fff;
  font-weight: bold;
  margin: 0 0 0 -0.1875rem;
  padding: 0 0.1875rem;
}

section#content .sv-widget [data-abide] .error small.error,
section#content .sv-widget [data-abide] span.error,
section#content .sv-widget [data-abide] small.error {
  background: #f04124;
  color: #fff;
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: 0.375rem 0.5625rem 0.5625rem;
}

section#content .sv-widget [data-abide] span.error,
section#content .sv-widget [data-abide] small.error {
  display: none;
}

section#content .sv-widget span.error,
section#content .sv-widget small.error {
  background: #f04124;
  color: #fff;
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: 0.375rem 0.5625rem 0.5625rem;
}

section#content .sv-widget .error input,
section#content .sv-widget .error textarea,
section#content .sv-widget .error select {
  margin-bottom: 0;
}

section#content .sv-widget .error input[type='checkbox'],
section#content .sv-widget .error input[type='radio'] {
  margin-bottom: 1rem;
}

section#content .sv-widget .error label,
section#content .sv-widget .error label.error {
  color: #f04124;
}

section#content .sv-widget .error small.error {
  background: #f04124;
  color: #fff;
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: 0.375rem 0.5625rem 0.5625rem;
}

section#content .sv-widget .error > label > small {
  background: transparent;
  color: #676767;
  display: inline;
  font-size: 60%;
  font-style: normal;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

section#content .sv-widget .error span.error-message {
  display: block;
}

section#content .sv-widget input.error,
section#content .sv-widget textarea.error {
  margin-bottom: 0;
}

section#content .sv-widget label.error {
  color: #f04124;
}

section#content .sv-widget .ui-state-error {
  margin: -0.8rem 0 0;
}

@media only screen {
  .sv-widget.sv-widget-content img.resized {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    width: 100%;
  }

  .sv-widget:not(.sv-widget-content) img.resized {
    visibility: hidden;
  }
}

.sv-widget.sv-widget-content a {
  text-decoration: underline;
}

.sv-widget.sv-widget-content h1 a,
.sv-widget.sv-widget-content h2 a {
  text-decoration: none;
}

.sv-widget.sv-widget-content h1 a:hover,
.sv-widget.sv-widget-content h2 a:hover {
  text-decoration: underline;
}

.lt-ie9 .sv-widget.sv-widget-content h1 {
  color: #f00;
}

.sv-widget blockquote {
  margin: 0;
}

.sv-widget table {
  width: 100%;
}

.sv-widget table td {
  padding-bottom: 5px;
  padding-left: 10px;
}

.sv-widget table td:first-child {
  padding-left: 0;
}

.sv-widget form[rel='SV_FORM'] .row {
  padding-bottom: 1rem;
}

.sv-widget,
.sv-widget .content-box {
  background-color: #fff;
}

.sv-widget,
.sv-widget p,
.sv-widget li,
.sv-widget blockquote,
.sv-widget h1,
.sv-widget h2,
.sv-widget h3 a,
.sv-widget a:link,
.sv-widget a:visited,
.sv-widget a:hover,
.sv-widget a:active,
.sv-widget .content-box,
.sv-widget .content-box p,
.sv-widget .content-box li,
.sv-widget .content-box blockquote,
.sv-widget .content-box h1,
.sv-widget .content-box h2,
.sv-widget .content-box h3 a,
.sv-widget .content-box a:link,
.sv-widget .content-box a:visited,
.sv-widget .content-box a:hover,
.sv-widget .content-box a:active {
  color: #6d6458;
}

.sv-widget a.next-page,
.sv-widget .content-box a.next-page {
  background-position: 0 -27px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget a.next-page:hover,
.sv-widget .content-box a.next-page:hover {
  background-position: 0 -131px;
}

.sv-widget .colormode-1 {
  background-color: #fff;
}

.sv-widget .colormode-1,
.sv-widget .colormode-1 p,
.sv-widget .colormode-1 li,
.sv-widget .colormode-1 blockquote,
.sv-widget .colormode-1 h1,
.sv-widget .colormode-1 h2,
.sv-widget .colormode-1 h3 a,
.sv-widget .colormode-1 a:link,
.sv-widget .colormode-1 a:visited,
.sv-widget .colormode-1 a:hover,
.sv-widget .colormode-1 a:active {
  color: #6d6458;
}

.sv-widget .colormode-1 a.next-page {
  background-position: 0 -27px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-1 a.next-page:hover {
  background-position: 0 -131px;
}

.sv-widget .colormode-1 .content-box {
  background-color: #fff;
}

.sv-widget .colormode-1 .kadi-col.colormode-1 {
  background-color: #fff;
}

.sv-widget .colormode-1 .kadi-col.colormode-1,
.sv-widget .colormode-1 .kadi-col.colormode-1 p,
.sv-widget .colormode-1 .kadi-col.colormode-1 li,
.sv-widget .colormode-1 .kadi-col.colormode-1 blockquote,
.sv-widget .colormode-1 .kadi-col.colormode-1 h1,
.sv-widget .colormode-1 .kadi-col.colormode-1 h2,
.sv-widget .colormode-1 .kadi-col.colormode-1 h3 a,
.sv-widget .colormode-1 .kadi-col.colormode-1 a:link,
.sv-widget .colormode-1 .kadi-col.colormode-1 a:visited,
.sv-widget .colormode-1 .kadi-col.colormode-1 a:hover,
.sv-widget .colormode-1 .kadi-col.colormode-1 a:active {
  color: #6d6458;
}

.sv-widget .colormode-1 .kadi-col.colormode-1 a.next-page {
  background-position: 0 -27px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-1 .kadi-col.colormode-1 a.next-page:hover {
  background-position: 0 -131px;
}

.sv-widget .colormode-1 .kadi-col.colormode-1 .content-box {
  background-color: #fff;
}

.sv-widget .colormode-1 .kadi-col.colormode-2 {
  background-color: #f2f1ef;
}

.sv-widget .colormode-1 .kadi-col.colormode-2,
.sv-widget .colormode-1 .kadi-col.colormode-2 p,
.sv-widget .colormode-1 .kadi-col.colormode-2 li,
.sv-widget .colormode-1 .kadi-col.colormode-2 blockquote,
.sv-widget .colormode-1 .kadi-col.colormode-2 h1,
.sv-widget .colormode-1 .kadi-col.colormode-2 h2,
.sv-widget .colormode-1 .kadi-col.colormode-2 h3 a,
.sv-widget .colormode-1 .kadi-col.colormode-2 a:link,
.sv-widget .colormode-1 .kadi-col.colormode-2 a:visited,
.sv-widget .colormode-1 .kadi-col.colormode-2 a:hover,
.sv-widget .colormode-1 .kadi-col.colormode-2 a:active {
  color: #403933;
}

.sv-widget .colormode-1 .kadi-col.colormode-2 a.next-page {
  background-position: 0 0;
  display: inline-block;
  height: 27px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-1 .kadi-col.colormode-2 a.next-page:hover {
  background-position: 0 -183px;
}

.sv-widget .colormode-1 .kadi-col.colormode-2 .content-box {
  background-color: #f2f1ef;
}

.sv-widget .colormode-1 .kadi-col.colormode-3 {
  background-color: #dad6d0;
}

.sv-widget .colormode-1 .kadi-col.colormode-3,
.sv-widget .colormode-1 .kadi-col.colormode-3 p,
.sv-widget .colormode-1 .kadi-col.colormode-3 li,
.sv-widget .colormode-1 .kadi-col.colormode-3 blockquote,
.sv-widget .colormode-1 .kadi-col.colormode-3 h1,
.sv-widget .colormode-1 .kadi-col.colormode-3 h2,
.sv-widget .colormode-1 .kadi-col.colormode-3 h3 a,
.sv-widget .colormode-1 .kadi-col.colormode-3 a:link,
.sv-widget .colormode-1 .kadi-col.colormode-3 a:visited,
.sv-widget .colormode-1 .kadi-col.colormode-3 a:hover,
.sv-widget .colormode-1 .kadi-col.colormode-3 a:active {
  color: #403933;
}

.sv-widget .colormode-1 .kadi-col.colormode-3 a.next-page {
  background-position: 0 -105px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-1 .kadi-col.colormode-3 a.next-page:hover {
  background-position: 0 -235px;
}

.sv-widget .colormode-1 .kadi-col.colormode-3 .content-box {
  background-color: #dad6d0;
}

.sv-widget .colormode-1 .kadi-col.colormode-4 {
  background-color: #9e9283;
}

.sv-widget .colormode-1 .kadi-col.colormode-4,
.sv-widget .colormode-1 .kadi-col.colormode-4 p,
.sv-widget .colormode-1 .kadi-col.colormode-4 li,
.sv-widget .colormode-1 .kadi-col.colormode-4 blockquote,
.sv-widget .colormode-1 .kadi-col.colormode-4 h1,
.sv-widget .colormode-1 .kadi-col.colormode-4 h2,
.sv-widget .colormode-1 .kadi-col.colormode-4 h3 a,
.sv-widget .colormode-1 .kadi-col.colormode-4 a:link,
.sv-widget .colormode-1 .kadi-col.colormode-4 a:visited,
.sv-widget .colormode-1 .kadi-col.colormode-4 a:hover,
.sv-widget .colormode-1 .kadi-col.colormode-4 a:active {
  color: #403933;
}

.sv-widget .colormode-1 .kadi-col.colormode-4 a.next-page {
  background-position: 0 -53px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-1 .kadi-col.colormode-4 a.next-page:hover {
  background-position: 0 -209px;
}

.sv-widget .colormode-1 .kadi-col.colormode-4 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-1 .kadi-col.colormode-4b {
  background-color: #9e9283;
}

.sv-widget .colormode-1 .kadi-col.colormode-4b,
.sv-widget .colormode-1 .kadi-col.colormode-4b p,
.sv-widget .colormode-1 .kadi-col.colormode-4b li,
.sv-widget .colormode-1 .kadi-col.colormode-4b blockquote,
.sv-widget .colormode-1 .kadi-col.colormode-4b h1,
.sv-widget .colormode-1 .kadi-col.colormode-4b h2,
.sv-widget .colormode-1 .kadi-col.colormode-4b h3 a,
.sv-widget .colormode-1 .kadi-col.colormode-4b a:link,
.sv-widget .colormode-1 .kadi-col.colormode-4b a:visited,
.sv-widget .colormode-1 .kadi-col.colormode-4b a:hover,
.sv-widget .colormode-1 .kadi-col.colormode-4b a:active {
  color: #fff;
}

.sv-widget .colormode-1 .kadi-col.colormode-4b a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-1 .kadi-col.colormode-4b a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-1 .kadi-col.colormode-4b .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-1 .kadi-col.colormode-5 {
  background-color: #9e9283;
}

.sv-widget .colormode-1 .kadi-col.colormode-5,
.sv-widget .colormode-1 .kadi-col.colormode-5 p,
.sv-widget .colormode-1 .kadi-col.colormode-5 li,
.sv-widget .colormode-1 .kadi-col.colormode-5 blockquote,
.sv-widget .colormode-1 .kadi-col.colormode-5 h1,
.sv-widget .colormode-1 .kadi-col.colormode-5 h2,
.sv-widget .colormode-1 .kadi-col.colormode-5 h3 a,
.sv-widget .colormode-1 .kadi-col.colormode-5 a:link,
.sv-widget .colormode-1 .kadi-col.colormode-5 a:visited,
.sv-widget .colormode-1 .kadi-col.colormode-5 a:hover,
.sv-widget .colormode-1 .kadi-col.colormode-5 a:active {
  color: #fff;
}

.sv-widget .colormode-1 .kadi-col.colormode-5 a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-1 .kadi-col.colormode-5 a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-1 .kadi-col.colormode-5 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-2 {
  background-color: #f2f1ef;
}

.sv-widget .colormode-2,
.sv-widget .colormode-2 p,
.sv-widget .colormode-2 li,
.sv-widget .colormode-2 blockquote,
.sv-widget .colormode-2 h1,
.sv-widget .colormode-2 h2,
.sv-widget .colormode-2 h3 a,
.sv-widget .colormode-2 a:link,
.sv-widget .colormode-2 a:visited,
.sv-widget .colormode-2 a:hover,
.sv-widget .colormode-2 a:active {
  color: #403933;
}

.sv-widget .colormode-2 a.next-page {
  background-position: 0 0;
  display: inline-block;
  height: 27px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-2 a.next-page:hover {
  background-position: 0 -183px;
}

.sv-widget .colormode-2 .content-box {
  background-color: #f2f1ef;
}

.sv-widget .colormode-2 .kadi-col.colormode-1 {
  background-color: #fff;
}

.sv-widget .colormode-2 .kadi-col.colormode-1,
.sv-widget .colormode-2 .kadi-col.colormode-1 p,
.sv-widget .colormode-2 .kadi-col.colormode-1 li,
.sv-widget .colormode-2 .kadi-col.colormode-1 blockquote,
.sv-widget .colormode-2 .kadi-col.colormode-1 h1,
.sv-widget .colormode-2 .kadi-col.colormode-1 h2,
.sv-widget .colormode-2 .kadi-col.colormode-1 h3 a,
.sv-widget .colormode-2 .kadi-col.colormode-1 a:link,
.sv-widget .colormode-2 .kadi-col.colormode-1 a:visited,
.sv-widget .colormode-2 .kadi-col.colormode-1 a:hover,
.sv-widget .colormode-2 .kadi-col.colormode-1 a:active {
  color: #6d6458;
}

.sv-widget .colormode-2 .kadi-col.colormode-1 a.next-page {
  background-position: 0 -27px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-2 .kadi-col.colormode-1 a.next-page:hover {
  background-position: 0 -131px;
}

.sv-widget .colormode-2 .kadi-col.colormode-1 .content-box {
  background-color: #fff;
}

.sv-widget .colormode-2 .kadi-col.colormode-2 {
  background-color: #f2f1ef;
}

.sv-widget .colormode-2 .kadi-col.colormode-2,
.sv-widget .colormode-2 .kadi-col.colormode-2 p,
.sv-widget .colormode-2 .kadi-col.colormode-2 li,
.sv-widget .colormode-2 .kadi-col.colormode-2 blockquote,
.sv-widget .colormode-2 .kadi-col.colormode-2 h1,
.sv-widget .colormode-2 .kadi-col.colormode-2 h2,
.sv-widget .colormode-2 .kadi-col.colormode-2 h3 a,
.sv-widget .colormode-2 .kadi-col.colormode-2 a:link,
.sv-widget .colormode-2 .kadi-col.colormode-2 a:visited,
.sv-widget .colormode-2 .kadi-col.colormode-2 a:hover,
.sv-widget .colormode-2 .kadi-col.colormode-2 a:active {
  color: #403933;
}

.sv-widget .colormode-2 .kadi-col.colormode-2 a.next-page {
  background-position: 0 0;
  display: inline-block;
  height: 27px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-2 .kadi-col.colormode-2 a.next-page:hover {
  background-position: 0 -183px;
}

.sv-widget .colormode-2 .kadi-col.colormode-2 .content-box {
  background-color: #f2f1ef;
}

.sv-widget .colormode-2 .kadi-col.colormode-3 {
  background-color: #dad6d0;
}

.sv-widget .colormode-2 .kadi-col.colormode-3,
.sv-widget .colormode-2 .kadi-col.colormode-3 p,
.sv-widget .colormode-2 .kadi-col.colormode-3 li,
.sv-widget .colormode-2 .kadi-col.colormode-3 blockquote,
.sv-widget .colormode-2 .kadi-col.colormode-3 h1,
.sv-widget .colormode-2 .kadi-col.colormode-3 h2,
.sv-widget .colormode-2 .kadi-col.colormode-3 h3 a,
.sv-widget .colormode-2 .kadi-col.colormode-3 a:link,
.sv-widget .colormode-2 .kadi-col.colormode-3 a:visited,
.sv-widget .colormode-2 .kadi-col.colormode-3 a:hover,
.sv-widget .colormode-2 .kadi-col.colormode-3 a:active {
  color: #403933;
}

.sv-widget .colormode-2 .kadi-col.colormode-3 a.next-page {
  background-position: 0 -105px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-2 .kadi-col.colormode-3 a.next-page:hover {
  background-position: 0 -235px;
}

.sv-widget .colormode-2 .kadi-col.colormode-3 .content-box {
  background-color: #dad6d0;
}

.sv-widget .colormode-2 .kadi-col.colormode-4 {
  background-color: #9e9283;
}

.sv-widget .colormode-2 .kadi-col.colormode-4,
.sv-widget .colormode-2 .kadi-col.colormode-4 p,
.sv-widget .colormode-2 .kadi-col.colormode-4 li,
.sv-widget .colormode-2 .kadi-col.colormode-4 blockquote,
.sv-widget .colormode-2 .kadi-col.colormode-4 h1,
.sv-widget .colormode-2 .kadi-col.colormode-4 h2,
.sv-widget .colormode-2 .kadi-col.colormode-4 h3 a,
.sv-widget .colormode-2 .kadi-col.colormode-4 a:link,
.sv-widget .colormode-2 .kadi-col.colormode-4 a:visited,
.sv-widget .colormode-2 .kadi-col.colormode-4 a:hover,
.sv-widget .colormode-2 .kadi-col.colormode-4 a:active {
  color: #403933;
}

.sv-widget .colormode-2 .kadi-col.colormode-4 a.next-page {
  background-position: 0 -53px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-2 .kadi-col.colormode-4 a.next-page:hover {
  background-position: 0 -209px;
}

.sv-widget .colormode-2 .kadi-col.colormode-4 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-2 .kadi-col.colormode-4b {
  background-color: #9e9283;
}

.sv-widget .colormode-2 .kadi-col.colormode-4b,
.sv-widget .colormode-2 .kadi-col.colormode-4b p,
.sv-widget .colormode-2 .kadi-col.colormode-4b li,
.sv-widget .colormode-2 .kadi-col.colormode-4b blockquote,
.sv-widget .colormode-2 .kadi-col.colormode-4b h1,
.sv-widget .colormode-2 .kadi-col.colormode-4b h2,
.sv-widget .colormode-2 .kadi-col.colormode-4b h3 a,
.sv-widget .colormode-2 .kadi-col.colormode-4b a:link,
.sv-widget .colormode-2 .kadi-col.colormode-4b a:visited,
.sv-widget .colormode-2 .kadi-col.colormode-4b a:hover,
.sv-widget .colormode-2 .kadi-col.colormode-4b a:active {
  color: #fff;
}

.sv-widget .colormode-2 .kadi-col.colormode-4b a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-2 .kadi-col.colormode-4b a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-2 .kadi-col.colormode-4b .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-2 .kadi-col.colormode-5 {
  background-color: #9e9283;
}

.sv-widget .colormode-2 .kadi-col.colormode-5,
.sv-widget .colormode-2 .kadi-col.colormode-5 p,
.sv-widget .colormode-2 .kadi-col.colormode-5 li,
.sv-widget .colormode-2 .kadi-col.colormode-5 blockquote,
.sv-widget .colormode-2 .kadi-col.colormode-5 h1,
.sv-widget .colormode-2 .kadi-col.colormode-5 h2,
.sv-widget .colormode-2 .kadi-col.colormode-5 h3 a,
.sv-widget .colormode-2 .kadi-col.colormode-5 a:link,
.sv-widget .colormode-2 .kadi-col.colormode-5 a:visited,
.sv-widget .colormode-2 .kadi-col.colormode-5 a:hover,
.sv-widget .colormode-2 .kadi-col.colormode-5 a:active {
  color: #fff;
}

.sv-widget .colormode-2 .kadi-col.colormode-5 a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-2 .kadi-col.colormode-5 a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-2 .kadi-col.colormode-5 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-3 {
  background-color: #dad6d0;
}

.sv-widget .colormode-3,
.sv-widget .colormode-3 p,
.sv-widget .colormode-3 li,
.sv-widget .colormode-3 blockquote,
.sv-widget .colormode-3 h1,
.sv-widget .colormode-3 h2,
.sv-widget .colormode-3 h3 a,
.sv-widget .colormode-3 a:link,
.sv-widget .colormode-3 a:visited,
.sv-widget .colormode-3 a:hover,
.sv-widget .colormode-3 a:active {
  color: #403933;
}

.sv-widget .colormode-3 a.next-page {
  background-position: 0 -105px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-3 a.next-page:hover {
  background-position: 0 -235px;
}

.sv-widget .colormode-3 .content-box {
  background-color: #dad6d0;
}

.sv-widget .colormode-3 .kadi-col.colormode-1 {
  background-color: #fff;
}

.sv-widget .colormode-3 .kadi-col.colormode-1,
.sv-widget .colormode-3 .kadi-col.colormode-1 p,
.sv-widget .colormode-3 .kadi-col.colormode-1 li,
.sv-widget .colormode-3 .kadi-col.colormode-1 blockquote,
.sv-widget .colormode-3 .kadi-col.colormode-1 h1,
.sv-widget .colormode-3 .kadi-col.colormode-1 h2,
.sv-widget .colormode-3 .kadi-col.colormode-1 h3 a,
.sv-widget .colormode-3 .kadi-col.colormode-1 a:link,
.sv-widget .colormode-3 .kadi-col.colormode-1 a:visited,
.sv-widget .colormode-3 .kadi-col.colormode-1 a:hover,
.sv-widget .colormode-3 .kadi-col.colormode-1 a:active {
  color: #6d6458;
}

.sv-widget .colormode-3 .kadi-col.colormode-1 a.next-page {
  background-position: 0 -27px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-3 .kadi-col.colormode-1 a.next-page:hover {
  background-position: 0 -131px;
}

.sv-widget .colormode-3 .kadi-col.colormode-1 .content-box {
  background-color: #fff;
}

.sv-widget .colormode-3 .kadi-col.colormode-2 {
  background-color: #f2f1ef;
}

.sv-widget .colormode-3 .kadi-col.colormode-2,
.sv-widget .colormode-3 .kadi-col.colormode-2 p,
.sv-widget .colormode-3 .kadi-col.colormode-2 li,
.sv-widget .colormode-3 .kadi-col.colormode-2 blockquote,
.sv-widget .colormode-3 .kadi-col.colormode-2 h1,
.sv-widget .colormode-3 .kadi-col.colormode-2 h2,
.sv-widget .colormode-3 .kadi-col.colormode-2 h3 a,
.sv-widget .colormode-3 .kadi-col.colormode-2 a:link,
.sv-widget .colormode-3 .kadi-col.colormode-2 a:visited,
.sv-widget .colormode-3 .kadi-col.colormode-2 a:hover,
.sv-widget .colormode-3 .kadi-col.colormode-2 a:active {
  color: #403933;
}

.sv-widget .colormode-3 .kadi-col.colormode-2 a.next-page {
  background-position: 0 0;
  display: inline-block;
  height: 27px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-3 .kadi-col.colormode-2 a.next-page:hover {
  background-position: 0 -183px;
}

.sv-widget .colormode-3 .kadi-col.colormode-2 .content-box {
  background-color: #f2f1ef;
}

.sv-widget .colormode-3 .kadi-col.colormode-3 {
  background-color: #dad6d0;
}

.sv-widget .colormode-3 .kadi-col.colormode-3,
.sv-widget .colormode-3 .kadi-col.colormode-3 p,
.sv-widget .colormode-3 .kadi-col.colormode-3 li,
.sv-widget .colormode-3 .kadi-col.colormode-3 blockquote,
.sv-widget .colormode-3 .kadi-col.colormode-3 h1,
.sv-widget .colormode-3 .kadi-col.colormode-3 h2,
.sv-widget .colormode-3 .kadi-col.colormode-3 h3 a,
.sv-widget .colormode-3 .kadi-col.colormode-3 a:link,
.sv-widget .colormode-3 .kadi-col.colormode-3 a:visited,
.sv-widget .colormode-3 .kadi-col.colormode-3 a:hover,
.sv-widget .colormode-3 .kadi-col.colormode-3 a:active {
  color: #403933;
}

.sv-widget .colormode-3 .kadi-col.colormode-3 a.next-page {
  background-position: 0 -105px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-3 .kadi-col.colormode-3 a.next-page:hover {
  background-position: 0 -235px;
}

.sv-widget .colormode-3 .kadi-col.colormode-3 .content-box {
  background-color: #dad6d0;
}

.sv-widget .colormode-3 .kadi-col.colormode-4 {
  background-color: #9e9283;
}

.sv-widget .colormode-3 .kadi-col.colormode-4,
.sv-widget .colormode-3 .kadi-col.colormode-4 p,
.sv-widget .colormode-3 .kadi-col.colormode-4 li,
.sv-widget .colormode-3 .kadi-col.colormode-4 blockquote,
.sv-widget .colormode-3 .kadi-col.colormode-4 h1,
.sv-widget .colormode-3 .kadi-col.colormode-4 h2,
.sv-widget .colormode-3 .kadi-col.colormode-4 h3 a,
.sv-widget .colormode-3 .kadi-col.colormode-4 a:link,
.sv-widget .colormode-3 .kadi-col.colormode-4 a:visited,
.sv-widget .colormode-3 .kadi-col.colormode-4 a:hover,
.sv-widget .colormode-3 .kadi-col.colormode-4 a:active {
  color: #403933;
}

.sv-widget .colormode-3 .kadi-col.colormode-4 a.next-page {
  background-position: 0 -53px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-3 .kadi-col.colormode-4 a.next-page:hover {
  background-position: 0 -209px;
}

.sv-widget .colormode-3 .kadi-col.colormode-4 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-3 .kadi-col.colormode-4b {
  background-color: #9e9283;
}

.sv-widget .colormode-3 .kadi-col.colormode-4b,
.sv-widget .colormode-3 .kadi-col.colormode-4b p,
.sv-widget .colormode-3 .kadi-col.colormode-4b li,
.sv-widget .colormode-3 .kadi-col.colormode-4b blockquote,
.sv-widget .colormode-3 .kadi-col.colormode-4b h1,
.sv-widget .colormode-3 .kadi-col.colormode-4b h2,
.sv-widget .colormode-3 .kadi-col.colormode-4b h3 a,
.sv-widget .colormode-3 .kadi-col.colormode-4b a:link,
.sv-widget .colormode-3 .kadi-col.colormode-4b a:visited,
.sv-widget .colormode-3 .kadi-col.colormode-4b a:hover,
.sv-widget .colormode-3 .kadi-col.colormode-4b a:active {
  color: #fff;
}

.sv-widget .colormode-3 .kadi-col.colormode-4b a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-3 .kadi-col.colormode-4b a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-3 .kadi-col.colormode-4b .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-3 .kadi-col.colormode-5 {
  background-color: #9e9283;
}

.sv-widget .colormode-3 .kadi-col.colormode-5,
.sv-widget .colormode-3 .kadi-col.colormode-5 p,
.sv-widget .colormode-3 .kadi-col.colormode-5 li,
.sv-widget .colormode-3 .kadi-col.colormode-5 blockquote,
.sv-widget .colormode-3 .kadi-col.colormode-5 h1,
.sv-widget .colormode-3 .kadi-col.colormode-5 h2,
.sv-widget .colormode-3 .kadi-col.colormode-5 h3 a,
.sv-widget .colormode-3 .kadi-col.colormode-5 a:link,
.sv-widget .colormode-3 .kadi-col.colormode-5 a:visited,
.sv-widget .colormode-3 .kadi-col.colormode-5 a:hover,
.sv-widget .colormode-3 .kadi-col.colormode-5 a:active {
  color: #fff;
}

.sv-widget .colormode-3 .kadi-col.colormode-5 a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-3 .kadi-col.colormode-5 a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-3 .kadi-col.colormode-5 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-4 {
  background-color: #9e9283;
}

.sv-widget .colormode-4,
.sv-widget .colormode-4 p,
.sv-widget .colormode-4 li,
.sv-widget .colormode-4 blockquote,
.sv-widget .colormode-4 h1,
.sv-widget .colormode-4 h2,
.sv-widget .colormode-4 h3 a,
.sv-widget .colormode-4 a:link,
.sv-widget .colormode-4 a:visited,
.sv-widget .colormode-4 a:hover,
.sv-widget .colormode-4 a:active {
  color: #403933;
}

.sv-widget .colormode-4 a.next-page {
  background-position: 0 -53px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4 a.next-page:hover {
  background-position: 0 -209px;
}

.sv-widget .colormode-4 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-4 .kadi-col.colormode-1 {
  background-color: #fff;
}

.sv-widget .colormode-4 .kadi-col.colormode-1,
.sv-widget .colormode-4 .kadi-col.colormode-1 p,
.sv-widget .colormode-4 .kadi-col.colormode-1 li,
.sv-widget .colormode-4 .kadi-col.colormode-1 blockquote,
.sv-widget .colormode-4 .kadi-col.colormode-1 h1,
.sv-widget .colormode-4 .kadi-col.colormode-1 h2,
.sv-widget .colormode-4 .kadi-col.colormode-1 h3 a,
.sv-widget .colormode-4 .kadi-col.colormode-1 a:link,
.sv-widget .colormode-4 .kadi-col.colormode-1 a:visited,
.sv-widget .colormode-4 .kadi-col.colormode-1 a:hover,
.sv-widget .colormode-4 .kadi-col.colormode-1 a:active {
  color: #6d6458;
}

.sv-widget .colormode-4 .kadi-col.colormode-1 a.next-page {
  background-position: 0 -27px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4 .kadi-col.colormode-1 a.next-page:hover {
  background-position: 0 -131px;
}

.sv-widget .colormode-4 .kadi-col.colormode-1 .content-box {
  background-color: #fff;
}

.sv-widget .colormode-4 .kadi-col.colormode-2 {
  background-color: #f2f1ef;
}

.sv-widget .colormode-4 .kadi-col.colormode-2,
.sv-widget .colormode-4 .kadi-col.colormode-2 p,
.sv-widget .colormode-4 .kadi-col.colormode-2 li,
.sv-widget .colormode-4 .kadi-col.colormode-2 blockquote,
.sv-widget .colormode-4 .kadi-col.colormode-2 h1,
.sv-widget .colormode-4 .kadi-col.colormode-2 h2,
.sv-widget .colormode-4 .kadi-col.colormode-2 h3 a,
.sv-widget .colormode-4 .kadi-col.colormode-2 a:link,
.sv-widget .colormode-4 .kadi-col.colormode-2 a:visited,
.sv-widget .colormode-4 .kadi-col.colormode-2 a:hover,
.sv-widget .colormode-4 .kadi-col.colormode-2 a:active {
  color: #403933;
}

.sv-widget .colormode-4 .kadi-col.colormode-2 a.next-page {
  background-position: 0 0;
  display: inline-block;
  height: 27px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4 .kadi-col.colormode-2 a.next-page:hover {
  background-position: 0 -183px;
}

.sv-widget .colormode-4 .kadi-col.colormode-2 .content-box {
  background-color: #f2f1ef;
}

.sv-widget .colormode-4 .kadi-col.colormode-3 {
  background-color: #dad6d0;
}

.sv-widget .colormode-4 .kadi-col.colormode-3,
.sv-widget .colormode-4 .kadi-col.colormode-3 p,
.sv-widget .colormode-4 .kadi-col.colormode-3 li,
.sv-widget .colormode-4 .kadi-col.colormode-3 blockquote,
.sv-widget .colormode-4 .kadi-col.colormode-3 h1,
.sv-widget .colormode-4 .kadi-col.colormode-3 h2,
.sv-widget .colormode-4 .kadi-col.colormode-3 h3 a,
.sv-widget .colormode-4 .kadi-col.colormode-3 a:link,
.sv-widget .colormode-4 .kadi-col.colormode-3 a:visited,
.sv-widget .colormode-4 .kadi-col.colormode-3 a:hover,
.sv-widget .colormode-4 .kadi-col.colormode-3 a:active {
  color: #403933;
}

.sv-widget .colormode-4 .kadi-col.colormode-3 a.next-page {
  background-position: 0 -105px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4 .kadi-col.colormode-3 a.next-page:hover {
  background-position: 0 -235px;
}

.sv-widget .colormode-4 .kadi-col.colormode-3 .content-box {
  background-color: #dad6d0;
}

.sv-widget .colormode-4 .kadi-col.colormode-4 {
  background-color: #9e9283;
}

.sv-widget .colormode-4 .kadi-col.colormode-4,
.sv-widget .colormode-4 .kadi-col.colormode-4 p,
.sv-widget .colormode-4 .kadi-col.colormode-4 li,
.sv-widget .colormode-4 .kadi-col.colormode-4 blockquote,
.sv-widget .colormode-4 .kadi-col.colormode-4 h1,
.sv-widget .colormode-4 .kadi-col.colormode-4 h2,
.sv-widget .colormode-4 .kadi-col.colormode-4 h3 a,
.sv-widget .colormode-4 .kadi-col.colormode-4 a:link,
.sv-widget .colormode-4 .kadi-col.colormode-4 a:visited,
.sv-widget .colormode-4 .kadi-col.colormode-4 a:hover,
.sv-widget .colormode-4 .kadi-col.colormode-4 a:active {
  color: #403933;
}

.sv-widget .colormode-4 .kadi-col.colormode-4 a.next-page {
  background-position: 0 -53px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4 .kadi-col.colormode-4 a.next-page:hover {
  background-position: 0 -209px;
}

.sv-widget .colormode-4 .kadi-col.colormode-4 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-4 .kadi-col.colormode-4b {
  background-color: #9e9283;
}

.sv-widget .colormode-4 .kadi-col.colormode-4b,
.sv-widget .colormode-4 .kadi-col.colormode-4b p,
.sv-widget .colormode-4 .kadi-col.colormode-4b li,
.sv-widget .colormode-4 .kadi-col.colormode-4b blockquote,
.sv-widget .colormode-4 .kadi-col.colormode-4b h1,
.sv-widget .colormode-4 .kadi-col.colormode-4b h2,
.sv-widget .colormode-4 .kadi-col.colormode-4b h3 a,
.sv-widget .colormode-4 .kadi-col.colormode-4b a:link,
.sv-widget .colormode-4 .kadi-col.colormode-4b a:visited,
.sv-widget .colormode-4 .kadi-col.colormode-4b a:hover,
.sv-widget .colormode-4 .kadi-col.colormode-4b a:active {
  color: #fff;
}

.sv-widget .colormode-4 .kadi-col.colormode-4b a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4 .kadi-col.colormode-4b a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-4 .kadi-col.colormode-4b .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-4 .kadi-col.colormode-5 {
  background-color: #9e9283;
}

.sv-widget .colormode-4 .kadi-col.colormode-5,
.sv-widget .colormode-4 .kadi-col.colormode-5 p,
.sv-widget .colormode-4 .kadi-col.colormode-5 li,
.sv-widget .colormode-4 .kadi-col.colormode-5 blockquote,
.sv-widget .colormode-4 .kadi-col.colormode-5 h1,
.sv-widget .colormode-4 .kadi-col.colormode-5 h2,
.sv-widget .colormode-4 .kadi-col.colormode-5 h3 a,
.sv-widget .colormode-4 .kadi-col.colormode-5 a:link,
.sv-widget .colormode-4 .kadi-col.colormode-5 a:visited,
.sv-widget .colormode-4 .kadi-col.colormode-5 a:hover,
.sv-widget .colormode-4 .kadi-col.colormode-5 a:active {
  color: #fff;
}

.sv-widget .colormode-4 .kadi-col.colormode-5 a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4 .kadi-col.colormode-5 a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-4 .kadi-col.colormode-5 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-4b {
  background-color: #9e9283;
}

.sv-widget .colormode-4b,
.sv-widget .colormode-4b p,
.sv-widget .colormode-4b li,
.sv-widget .colormode-4b blockquote,
.sv-widget .colormode-4b h1,
.sv-widget .colormode-4b h2,
.sv-widget .colormode-4b h3 a,
.sv-widget .colormode-4b a:link,
.sv-widget .colormode-4b a:visited,
.sv-widget .colormode-4b a:hover,
.sv-widget .colormode-4b a:active {
  color: #fff;
}

.sv-widget .colormode-4b a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4b a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-4b .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-4b .kadi-col.colormode-1 {
  background-color: #fff;
}

.sv-widget .colormode-4b .kadi-col.colormode-1,
.sv-widget .colormode-4b .kadi-col.colormode-1 p,
.sv-widget .colormode-4b .kadi-col.colormode-1 li,
.sv-widget .colormode-4b .kadi-col.colormode-1 blockquote,
.sv-widget .colormode-4b .kadi-col.colormode-1 h1,
.sv-widget .colormode-4b .kadi-col.colormode-1 h2,
.sv-widget .colormode-4b .kadi-col.colormode-1 h3 a,
.sv-widget .colormode-4b .kadi-col.colormode-1 a:link,
.sv-widget .colormode-4b .kadi-col.colormode-1 a:visited,
.sv-widget .colormode-4b .kadi-col.colormode-1 a:hover,
.sv-widget .colormode-4b .kadi-col.colormode-1 a:active {
  color: #6d6458;
}

.sv-widget .colormode-4b .kadi-col.colormode-1 a.next-page {
  background-position: 0 -27px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4b .kadi-col.colormode-1 a.next-page:hover {
  background-position: 0 -131px;
}

.sv-widget .colormode-4b .kadi-col.colormode-1 .content-box {
  background-color: #fff;
}

.sv-widget .colormode-4b .kadi-col.colormode-2 {
  background-color: #f2f1ef;
}

.sv-widget .colormode-4b .kadi-col.colormode-2,
.sv-widget .colormode-4b .kadi-col.colormode-2 p,
.sv-widget .colormode-4b .kadi-col.colormode-2 li,
.sv-widget .colormode-4b .kadi-col.colormode-2 blockquote,
.sv-widget .colormode-4b .kadi-col.colormode-2 h1,
.sv-widget .colormode-4b .kadi-col.colormode-2 h2,
.sv-widget .colormode-4b .kadi-col.colormode-2 h3 a,
.sv-widget .colormode-4b .kadi-col.colormode-2 a:link,
.sv-widget .colormode-4b .kadi-col.colormode-2 a:visited,
.sv-widget .colormode-4b .kadi-col.colormode-2 a:hover,
.sv-widget .colormode-4b .kadi-col.colormode-2 a:active {
  color: #403933;
}

.sv-widget .colormode-4b .kadi-col.colormode-2 a.next-page {
  background-position: 0 0;
  display: inline-block;
  height: 27px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4b .kadi-col.colormode-2 a.next-page:hover {
  background-position: 0 -183px;
}

.sv-widget .colormode-4b .kadi-col.colormode-2 .content-box {
  background-color: #f2f1ef;
}

.sv-widget .colormode-4b .kadi-col.colormode-3 {
  background-color: #dad6d0;
}

.sv-widget .colormode-4b .kadi-col.colormode-3,
.sv-widget .colormode-4b .kadi-col.colormode-3 p,
.sv-widget .colormode-4b .kadi-col.colormode-3 li,
.sv-widget .colormode-4b .kadi-col.colormode-3 blockquote,
.sv-widget .colormode-4b .kadi-col.colormode-3 h1,
.sv-widget .colormode-4b .kadi-col.colormode-3 h2,
.sv-widget .colormode-4b .kadi-col.colormode-3 h3 a,
.sv-widget .colormode-4b .kadi-col.colormode-3 a:link,
.sv-widget .colormode-4b .kadi-col.colormode-3 a:visited,
.sv-widget .colormode-4b .kadi-col.colormode-3 a:hover,
.sv-widget .colormode-4b .kadi-col.colormode-3 a:active {
  color: #403933;
}

.sv-widget .colormode-4b .kadi-col.colormode-3 a.next-page {
  background-position: 0 -105px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4b .kadi-col.colormode-3 a.next-page:hover {
  background-position: 0 -235px;
}

.sv-widget .colormode-4b .kadi-col.colormode-3 .content-box {
  background-color: #dad6d0;
}

.sv-widget .colormode-4b .kadi-col.colormode-4 {
  background-color: #9e9283;
}

.sv-widget .colormode-4b .kadi-col.colormode-4,
.sv-widget .colormode-4b .kadi-col.colormode-4 p,
.sv-widget .colormode-4b .kadi-col.colormode-4 li,
.sv-widget .colormode-4b .kadi-col.colormode-4 blockquote,
.sv-widget .colormode-4b .kadi-col.colormode-4 h1,
.sv-widget .colormode-4b .kadi-col.colormode-4 h2,
.sv-widget .colormode-4b .kadi-col.colormode-4 h3 a,
.sv-widget .colormode-4b .kadi-col.colormode-4 a:link,
.sv-widget .colormode-4b .kadi-col.colormode-4 a:visited,
.sv-widget .colormode-4b .kadi-col.colormode-4 a:hover,
.sv-widget .colormode-4b .kadi-col.colormode-4 a:active {
  color: #403933;
}

.sv-widget .colormode-4b .kadi-col.colormode-4 a.next-page {
  background-position: 0 -53px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4b .kadi-col.colormode-4 a.next-page:hover {
  background-position: 0 -209px;
}

.sv-widget .colormode-4b .kadi-col.colormode-4 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-4b .kadi-col.colormode-4b {
  background-color: #9e9283;
}

.sv-widget .colormode-4b .kadi-col.colormode-4b,
.sv-widget .colormode-4b .kadi-col.colormode-4b p,
.sv-widget .colormode-4b .kadi-col.colormode-4b li,
.sv-widget .colormode-4b .kadi-col.colormode-4b blockquote,
.sv-widget .colormode-4b .kadi-col.colormode-4b h1,
.sv-widget .colormode-4b .kadi-col.colormode-4b h2,
.sv-widget .colormode-4b .kadi-col.colormode-4b h3 a,
.sv-widget .colormode-4b .kadi-col.colormode-4b a:link,
.sv-widget .colormode-4b .kadi-col.colormode-4b a:visited,
.sv-widget .colormode-4b .kadi-col.colormode-4b a:hover,
.sv-widget .colormode-4b .kadi-col.colormode-4b a:active {
  color: #fff;
}

.sv-widget .colormode-4b .kadi-col.colormode-4b a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4b .kadi-col.colormode-4b a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-4b .kadi-col.colormode-4b .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-4b .kadi-col.colormode-5 {
  background-color: #9e9283;
}

.sv-widget .colormode-4b .kadi-col.colormode-5,
.sv-widget .colormode-4b .kadi-col.colormode-5 p,
.sv-widget .colormode-4b .kadi-col.colormode-5 li,
.sv-widget .colormode-4b .kadi-col.colormode-5 blockquote,
.sv-widget .colormode-4b .kadi-col.colormode-5 h1,
.sv-widget .colormode-4b .kadi-col.colormode-5 h2,
.sv-widget .colormode-4b .kadi-col.colormode-5 h3 a,
.sv-widget .colormode-4b .kadi-col.colormode-5 a:link,
.sv-widget .colormode-4b .kadi-col.colormode-5 a:visited,
.sv-widget .colormode-4b .kadi-col.colormode-5 a:hover,
.sv-widget .colormode-4b .kadi-col.colormode-5 a:active {
  color: #fff;
}

.sv-widget .colormode-4b .kadi-col.colormode-5 a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-4b .kadi-col.colormode-5 a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-4b .kadi-col.colormode-5 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-5 {
  background-color: #9e9283;
}

.sv-widget .colormode-5,
.sv-widget .colormode-5 p,
.sv-widget .colormode-5 li,
.sv-widget .colormode-5 blockquote,
.sv-widget .colormode-5 h1,
.sv-widget .colormode-5 h2,
.sv-widget .colormode-5 h3 a,
.sv-widget .colormode-5 a:link,
.sv-widget .colormode-5 a:visited,
.sv-widget .colormode-5 a:hover,
.sv-widget .colormode-5 a:active {
  color: #fff;
}

.sv-widget .colormode-5 a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-5 a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-5 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-5 .kadi-col.colormode-1 {
  background-color: #fff;
}

.sv-widget .colormode-5 .kadi-col.colormode-1,
.sv-widget .colormode-5 .kadi-col.colormode-1 p,
.sv-widget .colormode-5 .kadi-col.colormode-1 li,
.sv-widget .colormode-5 .kadi-col.colormode-1 blockquote,
.sv-widget .colormode-5 .kadi-col.colormode-1 h1,
.sv-widget .colormode-5 .kadi-col.colormode-1 h2,
.sv-widget .colormode-5 .kadi-col.colormode-1 h3 a,
.sv-widget .colormode-5 .kadi-col.colormode-1 a:link,
.sv-widget .colormode-5 .kadi-col.colormode-1 a:visited,
.sv-widget .colormode-5 .kadi-col.colormode-1 a:hover,
.sv-widget .colormode-5 .kadi-col.colormode-1 a:active {
  color: #6d6458;
}

.sv-widget .colormode-5 .kadi-col.colormode-1 a.next-page {
  background-position: 0 -27px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-5 .kadi-col.colormode-1 a.next-page:hover {
  background-position: 0 -131px;
}

.sv-widget .colormode-5 .kadi-col.colormode-1 .content-box {
  background-color: #fff;
}

.sv-widget .colormode-5 .kadi-col.colormode-2 {
  background-color: #f2f1ef;
}

.sv-widget .colormode-5 .kadi-col.colormode-2,
.sv-widget .colormode-5 .kadi-col.colormode-2 p,
.sv-widget .colormode-5 .kadi-col.colormode-2 li,
.sv-widget .colormode-5 .kadi-col.colormode-2 blockquote,
.sv-widget .colormode-5 .kadi-col.colormode-2 h1,
.sv-widget .colormode-5 .kadi-col.colormode-2 h2,
.sv-widget .colormode-5 .kadi-col.colormode-2 h3 a,
.sv-widget .colormode-5 .kadi-col.colormode-2 a:link,
.sv-widget .colormode-5 .kadi-col.colormode-2 a:visited,
.sv-widget .colormode-5 .kadi-col.colormode-2 a:hover,
.sv-widget .colormode-5 .kadi-col.colormode-2 a:active {
  color: #403933;
}

.sv-widget .colormode-5 .kadi-col.colormode-2 a.next-page {
  background-position: 0 0;
  display: inline-block;
  height: 27px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-5 .kadi-col.colormode-2 a.next-page:hover {
  background-position: 0 -183px;
}

.sv-widget .colormode-5 .kadi-col.colormode-2 .content-box {
  background-color: #f2f1ef;
}

.sv-widget .colormode-5 .kadi-col.colormode-3 {
  background-color: #dad6d0;
}

.sv-widget .colormode-5 .kadi-col.colormode-3,
.sv-widget .colormode-5 .kadi-col.colormode-3 p,
.sv-widget .colormode-5 .kadi-col.colormode-3 li,
.sv-widget .colormode-5 .kadi-col.colormode-3 blockquote,
.sv-widget .colormode-5 .kadi-col.colormode-3 h1,
.sv-widget .colormode-5 .kadi-col.colormode-3 h2,
.sv-widget .colormode-5 .kadi-col.colormode-3 h3 a,
.sv-widget .colormode-5 .kadi-col.colormode-3 a:link,
.sv-widget .colormode-5 .kadi-col.colormode-3 a:visited,
.sv-widget .colormode-5 .kadi-col.colormode-3 a:hover,
.sv-widget .colormode-5 .kadi-col.colormode-3 a:active {
  color: #403933;
}

.sv-widget .colormode-5 .kadi-col.colormode-3 a.next-page {
  background-position: 0 -105px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-5 .kadi-col.colormode-3 a.next-page:hover {
  background-position: 0 -235px;
}

.sv-widget .colormode-5 .kadi-col.colormode-3 .content-box {
  background-color: #dad6d0;
}

.sv-widget .colormode-5 .kadi-col.colormode-4 {
  background-color: #9e9283;
}

.sv-widget .colormode-5 .kadi-col.colormode-4,
.sv-widget .colormode-5 .kadi-col.colormode-4 p,
.sv-widget .colormode-5 .kadi-col.colormode-4 li,
.sv-widget .colormode-5 .kadi-col.colormode-4 blockquote,
.sv-widget .colormode-5 .kadi-col.colormode-4 h1,
.sv-widget .colormode-5 .kadi-col.colormode-4 h2,
.sv-widget .colormode-5 .kadi-col.colormode-4 h3 a,
.sv-widget .colormode-5 .kadi-col.colormode-4 a:link,
.sv-widget .colormode-5 .kadi-col.colormode-4 a:visited,
.sv-widget .colormode-5 .kadi-col.colormode-4 a:hover,
.sv-widget .colormode-5 .kadi-col.colormode-4 a:active {
  color: #403933;
}

.sv-widget .colormode-5 .kadi-col.colormode-4 a.next-page {
  background-position: 0 -53px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-5 .kadi-col.colormode-4 a.next-page:hover {
  background-position: 0 -209px;
}

.sv-widget .colormode-5 .kadi-col.colormode-4 .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-5 .kadi-col.colormode-4b {
  background-color: #9e9283;
}

.sv-widget .colormode-5 .kadi-col.colormode-4b,
.sv-widget .colormode-5 .kadi-col.colormode-4b p,
.sv-widget .colormode-5 .kadi-col.colormode-4b li,
.sv-widget .colormode-5 .kadi-col.colormode-4b blockquote,
.sv-widget .colormode-5 .kadi-col.colormode-4b h1,
.sv-widget .colormode-5 .kadi-col.colormode-4b h2,
.sv-widget .colormode-5 .kadi-col.colormode-4b h3 a,
.sv-widget .colormode-5 .kadi-col.colormode-4b a:link,
.sv-widget .colormode-5 .kadi-col.colormode-4b a:visited,
.sv-widget .colormode-5 .kadi-col.colormode-4b a:hover,
.sv-widget .colormode-5 .kadi-col.colormode-4b a:active {
  color: #fff;
}

.sv-widget .colormode-5 .kadi-col.colormode-4b a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-5 .kadi-col.colormode-4b a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-5 .kadi-col.colormode-4b .content-box {
  background-color: #9e9283;
}

.sv-widget .colormode-5 .kadi-col.colormode-5 {
  background-color: #9e9283;
}

.sv-widget .colormode-5 .kadi-col.colormode-5,
.sv-widget .colormode-5 .kadi-col.colormode-5 p,
.sv-widget .colormode-5 .kadi-col.colormode-5 li,
.sv-widget .colormode-5 .kadi-col.colormode-5 blockquote,
.sv-widget .colormode-5 .kadi-col.colormode-5 h1,
.sv-widget .colormode-5 .kadi-col.colormode-5 h2,
.sv-widget .colormode-5 .kadi-col.colormode-5 h3 a,
.sv-widget .colormode-5 .kadi-col.colormode-5 a:link,
.sv-widget .colormode-5 .kadi-col.colormode-5 a:visited,
.sv-widget .colormode-5 .kadi-col.colormode-5 a:hover,
.sv-widget .colormode-5 .kadi-col.colormode-5 a:active {
  color: #fff;
}

.sv-widget .colormode-5 .kadi-col.colormode-5 a.next-page {
  background-position: 0 -157px;
  display: inline-block;
  height: 26px;
  text-indent: -30000px;
  width: 26px;
}

.sv-widget .colormode-5 .kadi-col.colormode-5 a.next-page:hover {
  background-position: 0 -79px;
}

.sv-widget .colormode-5 .kadi-col.colormode-5 .content-box {
  background-color: #9e9283;
}

.sv-view .sv-widget {
  margin: 0;
  position: static;
}

@media only screen and (min-width: 50.063em) {
  .sv-view body:not(.no-font-resize) .sv-widget .kadi-product-desc h2 {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 50.063em) and (min-width: 64em) {
  .sv-view body:not(.no-font-resize) .sv-widget h1,
  .sv-view body:not(.no-font-resize) .sv-widget h2,
  .sv-view body:not(.no-font-resize) .sv-widget h3 {
    font-size: 3.22266vw;
    line-height: 3.22266vw;
  }
}

@media only screen and (min-width: 50.063em) and (min-width: 64em) {
  .sv-view body:not(.no-font-resize) .sv-widget .kadi-product-desc h2 {
    font-size: 1.95313vw;
    line-height: 1.95313vw;
  }
}

@media only screen and (min-width: 50.063em) and (min-width: 64em) {
  .sv-view body:not(.no-font-resize) .sv-widget .quote {
    font-size: 3.90625vw;
    line-height: 4.6875vw;
  }
}

@media only screen and (min-width: 64em) and (min-width: 64em) {
  .sv-view body:not(.no-font-resize) .sv-widget p,
  .sv-view body:not(.no-font-resize) .sv-widget ul,
  .sv-view body:not(.no-font-resize) .sv-widget ol,
  .sv-view body:not(.no-font-resize) .sv-widget dl {
    font-size: 1.26953vw;
    line-height: 1.85547vw;
  }
}

.sv-inpage .sv-widget {
  margin: 0 0 3em;
  max-width: 100%;
}

.sv-inpage .sv-widget:hover div.sv_inpage_overlay_editable {
  border-color: #f00;
}

h1,
h2 {
  text-transform: uppercase;
}

div#top {
  background: rgba(255, 255, 255, 0.97);
  box-shadow: #e1e1e1 2px 4px 5px;
  height: 102px;
  z-index: 2;
}

.sv-view div#top {
  position: absolute;
  width: 100%;
}

div#top .top-bar {
  height: 94px;
  padding-top: 25px;
}

div#top .top-bar.expanded {
  height: auto;
}

div#top .top-bar .title-area {
  display: table;
}

@media only screen and (max-width: 64.063em) {
  div#top .top-bar .title-area {
    width: 100%;
  }
}

div#top .top-bar .title-area .name {
  display: table-cell;
  vertical-align: middle;
}

div#top .top-bar .title-area .name a {
  padding: 0 20px 0 37px;
}

@media only screen and (max-width: 64.063em) {
  section.top-bar-section {
    padding-top: 5px;
  }

  section.top-bar-section ul {
    background: rgba(255, 255, 255, 0.97);
    border-top: 1px solid #f2f1ef;
  }

  section.top-bar-section ul li.active {
    text-decoration: underline;
  }

  section.top-bar-section ul li a {
    border-bottom: 1px solid #f2f1ef;
    padding: 8px 37px;
  }

  section.top-bar-section ul li a:hover,
  section.top-bar-section ul li a:active {
    text-decoration: underline;
  }

  section.top-bar-section ul .dropdown li a {
    padding: 8px 37px;
  }

  section.top-bar-section h5 {
    font-size: 1rem;
  }

  section.top-bar-section ul#language-nav li {
    display: inline-block;
    text-transform: uppercase;
    width: 20%;
  }

  section.top-bar-section ul#language-nav li a.sel {
    background: #f2f1ef;
  }
}

@media only screen and (min-width: 50.063em) {
  section.top-bar-section .kadi-row-product ul.product-preparation-1,
  .kadi-row-product section.top-bar-section ul.product-preparation-1,
  section.top-bar-section .kadi-row-product ul.product-preparation-2,
  .kadi-row-product section.top-bar-section ul.product-preparation-2,
  section.top-bar-section .kadi-row-product ul.product-preparation-3,
  .kadi-row-product section.top-bar-section ul.product-preparation-3,
  section.top-bar-section .kadi-row-product ul.product-preparation-4,
  .kadi-row-product section.top-bar-section ul.product-preparation-4,
  section.top-bar-section .kadi-row-product ul.product-preparation-5,
  .kadi-row-product section.top-bar-section ul.product-preparation-5 {
    padding-left: 29px;
  }

  section.top-bar-section .kadi-row-product ul.product-preparation-1 > li > a,
  .kadi-row-product section.top-bar-section ul.product-preparation-1 > li > a,
  section.top-bar-section .kadi-row-product ul.product-preparation-2 > li > a,
  .kadi-row-product section.top-bar-section ul.product-preparation-2 > li > a,
  section.top-bar-section .kadi-row-product ul.product-preparation-3 > li > a,
  .kadi-row-product section.top-bar-section ul.product-preparation-3 > li > a,
  section.top-bar-section .kadi-row-product ul.product-preparation-4 > li > a,
  .kadi-row-product section.top-bar-section ul.product-preparation-4 > li > a,
  section.top-bar-section .kadi-row-product ul.product-preparation-5 > li > a,
  .kadi-row-product section.top-bar-section ul.product-preparation-5 > li > a {
    text-transform: uppercase;
  }
}

@media only screen and (min-width: 50.063em) and (min-width: 64em) {
  section.top-bar-section ul li:not(.has-form) a:not(.button) {
    font-size: 1.17188vw;
  }
}

@media only screen and (min-width: 50.063em) and (min-width: 64em) {
  section.top-bar-section ul.dropdown li:not(.has-form):not(.has-form) a:not(.button) {
    font-size: 1.17188vw;
    line-height: 2.44141vw;
  }
}

@media only screen and (min-width: 50.063em) and (max-width: 64em) {
  .top-bar-section .kadi-row-product ul.product-preparation-1 > li:not(.has-form) > a:not(.button),
  .kadi-row-product .top-bar-section ul.product-preparation-1 > li:not(.has-form) > a:not(.button),
  .top-bar-section .kadi-row-product ul.product-preparation-2 > li:not(.has-form) > a:not(.button),
  .kadi-row-product .top-bar-section ul.product-preparation-2 > li:not(.has-form) > a:not(.button),
  .top-bar-section .kadi-row-product ul.product-preparation-3 > li:not(.has-form) > a:not(.button),
  .kadi-row-product .top-bar-section ul.product-preparation-3 > li:not(.has-form) > a:not(.button),
  .top-bar-section .kadi-row-product ul.product-preparation-4 > li:not(.has-form) > a:not(.button),
  .kadi-row-product .top-bar-section ul.product-preparation-4 > li:not(.has-form) > a:not(.button),
  .top-bar-section .kadi-row-product ul.product-preparation-5 > li:not(.has-form) > a:not(.button),
  .kadi-row-product .top-bar-section ul.product-preparation-5 > li:not(.has-form) > a:not(.button) {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 64.063em) {
  section.top-bar-section li.active > a,
  section.top-bar-section a:active,
  section.top-bar-section a:hover {
    text-decoration: underline;
  }

  .top-bar-section .kadi-row-product ul.product-preparation-1 > li:not(.has-form) a:not(.button),
  .kadi-row-product .top-bar-section ul.product-preparation-1 > li:not(.has-form) a:not(.button),
  .top-bar-section .kadi-row-product ul.product-preparation-2 > li:not(.has-form) a:not(.button),
  .kadi-row-product .top-bar-section ul.product-preparation-2 > li:not(.has-form) a:not(.button),
  .top-bar-section .kadi-row-product ul.product-preparation-3 > li:not(.has-form) a:not(.button),
  .kadi-row-product .top-bar-section ul.product-preparation-3 > li:not(.has-form) a:not(.button),
  .top-bar-section .kadi-row-product ul.product-preparation-4 > li:not(.has-form) a:not(.button),
  .kadi-row-product .top-bar-section ul.product-preparation-4 > li:not(.has-form) a:not(.button),
  .top-bar-section .kadi-row-product ul.product-preparation-5 > li:not(.has-form) a:not(.button),
  .kadi-row-product .top-bar-section ul.product-preparation-5 > li:not(.has-form) a:not(.button) {
    padding: 0 23px;
  }

  section.top-bar-section ul.left > li > a {
    text-transform: uppercase;
  }

  section.top-bar-section ul li:not(.has-form) a:not(.button) {
    font-family: FrutigerLTW01-45Light, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
    font-size: 1.3vw;
  }

  section.top-bar-section ul.dropdown li:not(.has-form) a:not(.button) {
    color: #9e9283;
  }

  section.top-bar-section ul.dropdown li:not(.has-form).active > a {
    color: #403933;
  }

  section.top-bar-section ul.dropdown li:not(.has-form):not(.has-form) a:not(.button) {
    line-height: 1.5625rem;
  }

  section.top-bar-section .has-dropdown .dropdown li.has-dropdown > a::after {
    content: '';
  }

  ul#language-nav {
    text-transform: uppercase;
  }

  ul#language-nav li {
    color: #9e9283;
  }

  ul#language-nav li:first-child a {
    padding-left: 10px;
  }

  ul#language-nav li:first-child a::after {
    content: '/';
    padding: 0 4px;
    position: absolute;
  }

  ul#language-nav li a {
    color: #9e9283;
    padding: 0 0 0 10px;
  }

  ul#language-nav li a.sel {
    cursor: default;
    text-decoration: underline;
  }

  ul#language-nav li a.sel::after {
    text-decoration: none;
  }

  .top-bar .name h1 a {
    padding: 0 72px 0 37px;
  }
}

.kadi-row,
.kadi-row-product,
.kadi-row-contentlist,
section#content .wide {
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  max-width: 150rem;
  width: 100%;
  *zoom: 1;
}

.kadi-row::before,
.kadi-row-product::before,
.kadi-row-contentlist::before,
section#content .wide::before,
.kadi-row::after,
.kadi-row-product::after,
.kadi-row-contentlist::after,
section#content .wide::after {
  content: ' ';
  display: table;
}

.kadi-row::after,
.kadi-row-product::after,
.kadi-row-contentlist::after,
section#content .wide::after {
  clear: both;
}

.kadi-row.img-right,
.img-right.kadi-row-product,
.img-right.kadi-row-contentlist,
section#content .img-right.wide {
  background-position: right;
}

.sv-inpage .kadi-row,
.sv-inpage .kadi-row-product,
.sv-inpage .kadi-row-contentlist,
.sv-inpage section#content .wide,
section#content .sv-inpage .wide {
  width: 90%;
}

.kadi-row-contentlist .kadi-col {
  background-size: contain;
}

.kadi-row-contentlist .kadi-col > div.rent-object {
  margin: 0 auto;
  max-width: 150rem;
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
  padding-top: 3.4375rem;
  width: 100%;
  *zoom: 1;
}

.kadi-row-contentlist .kadi-col > div.rent-object::before,
.kadi-row-contentlist .kadi-col > div.rent-object::after {
  content: ' ';
  display: table;
}

.kadi-row-contentlist .kadi-col > div.rent-object::after {
  clear: both;
}

@media only screen and (min-width: 64em) {
  .kadi-row-contentlist .kadi-col > div.rent-object {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }
}

.kadi-row-contentlist .kadi-col > div.rent-object > div.image {
  float: left;
  left: auto;
  margin-left: 0 !important;
  padding-left: 0;
  padding-right: 5%;
  right: 0;
  width: 40%;
}

.kadi-row-contentlist .kadi-col > div.portrait {
  padding: 0;
  position: relative;
}

.kadi-row-contentlist .kadi-col > div.portrait div.desc {
  bottom: 1.25rem;
  left: 1.25rem;
  position: absolute;
}

.kadi-row-contentlist .kadi-col > div.portrait div.desc h2 {
  margin-bottom: 5px;
}

.kadi-row-contentlist .kadi-col > div.portrait div.desc p.subtitle {
  font-family: 'FrutigerLTW01-75Black', sans-serif;
}

.kadi-row-contentlist .kadi-col > div.portrait div.desc p.contact {
  margin-bottom: 0;
  margin-top: 0.625rem;
}

.kadi-row-contentlist .kadi-col > div.portrait div.desc span {
  font-family: 'FrutigerLTW01-75Black', sans-serif;
  padding-right: 0.625rem;
}

.kadi-col {
  float: left;
  left: auto;
  margin-left: 0 !important;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  right: 0;
  width: 100%;
}

@media only screen and (min-width: 50.063em), print {
  .kadi-col.half,
  section#content div.kadi-col {
    float: left;
    left: auto;
    margin-left: 0 !important;
    padding-left: 0;
    padding-right: 0;
    right: 0;
    width: 50%;
  }
}

.content-box {
  display: table-cell;
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
  vertical-align: middle;
}

@media only screen and (min-width: 64em) {
  .content-box {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }
}

.content-box p:last-child {
  margin-bottom: 0;
}

.content-box > div {
  padding: 3.4375rem 0;
}

.quote {
  font-family: FS Sammy Regular, serif;
  font-size: 2.5rem;
  line-height: 3rem;
  padding: 3.4375rem 0;
  text-align: center;
}

.content-box .quote {
  padding: 0;
}

.quote::before {
  content: '\00ab';
  padding-right: 5px;
}

.quote::after {
  content: '\00bb';
  padding-left: 5px;
}

.quote-sender {
  margin-top: 3rem;
  text-align: center;
}

.quote-sender span.name {
  display: block;
  font-family: 'FrutigerLTW01-75Black', sans-serif;
}

.sv-view section#content {
  padding-top: 100px;
}

.sv-inpage section#content {
  margin-top: 25px;
}

section#content .content-default div.sv-widget {
  padding: 3.4375rem 2.1875rem;
  position: relative;
}

@media only screen and (min-width: 64em) {
  section#content .content-default div.sv-widget {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }
}

footer {
  background: #f2f1ef;
}

footer p,
footer a {
  font-family: FrutigerLTW01-45Light, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
  font-size: 0.75rem;
  margin: 0;
}

footer > div.impressum {
  background: #403933;
  color: #fff;
  text-transform: uppercase;
}

footer > div.impressum a {
  color: #fff;
  padding-left: 20px;
}

footer > div.impressum p {
  line-height: 35px;
  text-align: center;
}

footer > div.impressum > div > div {
  float: none;
  left: auto;
  margin-left: 0 !important;
  margin-right: auto;
  padding-left: 4.375rem;
  padding-right: 4.375rem;
  right: 0;
  width: 100%;
}

footer > div.get-in-touch {
  color: #403933;
  min-height: 120px;
}

footer > div.get-in-touch div.row {
  padding-top: 26px;
}

@media only screen and (max-width: 50em) {
  footer > div.get-in-touch div.row .column {
    padding-bottom: 20px;
    text-align: center;
  }
}

footer > div.get-in-touch span.title {
  text-decoration: underline;
  text-transform: uppercase;
}

footer > div.get-in-touch div.contact ul {
  list-style-type: none;
  margin: 0;
}

@media only screen and (min-width: 50.063em) {
  footer > div.get-in-touch div.contact ul {
    margin: 9px 0 0;
  }
}

footer > div.get-in-touch div.contact ul li {
  line-height: 12px;
  padding-top: 9px;
}

footer > div.get-in-touch div.contact ul a {
  display: inline-block;
  line-height: 12px;
  padding-left: 34px;
}

footer > div.get-in-touch div.contact ul a.mail {
  background-position: 0 -1056px;
  padding-bottom: 9px;
  padding-top: 3px;
}

footer > div.get-in-touch div.contact ul a.phone {
  background-position: 0 -1172px;
  padding-top: 9px;
}

@media print {
  footer > div.get-in-touch div.contact ul li {
    padding: 0;
  }

  footer > div.get-in-touch div.contact ul a {
    padding: 0;
  }
}

footer > div.get-in-touch div.nwl {
  text-decoration: underline;
  text-transform: uppercase;
}

footer > div.get-in-touch div.nwl a.button {
  margin-top: 5px;
}

footer > div.get-in-touch div.fb a {
  background: url('/design/images/ico/common/fb-hover.png') no-repeat center;
  background-size: 26px;
  display: inline-block;
  height: 26px;
  width: 26px;
}

footer > div.get-in-touch div.yt a {
  background: url('/design/images/ico/common/yt-hover.png') no-repeat center;
  background-size: 28px;
  display: inline-block;
  height: 26px;
  width: 30px;
}

footer > div.get-in-touch div.instagram a {
  background: url('/design/images/ico/common/instagram.png') no-repeat center;
  background-size: 26px;
  display: inline-block;
  height: 26px;
  width: 26px;
}

footer > div.get-in-touch div.twitter a {
  background-position: 0 -126px;
  display: inline-block;
  height: 26px;
  width: 26px;
}

@media only screen and (min-width: 50.063em) {
  footer > div.get-in-touch div.column {
    padding: 0;
  }

  footer > div.get-in-touch div.address {
    margin-left: 37px;
    width: 180px;
  }

  footer > div.get-in-touch div.contact {
    width: 233px;
  }

  footer > div.get-in-touch div.nwl {
    width: 313px;
  }

  footer > div.get-in-touch div.fb {
    width: 61px;
  }

  footer > div.get-in-touch div.fb a {
    margin-top: 48px;
  }

  footer > div.get-in-touch div.yt {
    width: 61px;
  }

  footer > div.get-in-touch div.yt a {
    margin-top: 48px;
  }

  footer > div.get-in-touch div.instagram {
    width: 61px;
  }

  footer > div.get-in-touch div.instagram a {
    margin-top: 48px;
  }

  footer > div.get-in-touch div.twitter {
    width: 30px;
  }

  footer > div.get-in-touch div.twitter a {
    margin-top: 48px;
  }
}

@media only screen and (min-width: 50.063em) and (max-width: 64em) {
  footer > div.get-in-touch div.nwl {
    width: 213px;
  }
}

footer > div > div {
  margin: 0 auto;
  max-width: 150rem;
  width: 100%;
  *zoom: 1;
}

footer > div > div::before,
footer > div > div::after {
  content: ' ';
  display: table;
}

footer > div > div::after {
  clear: both;
}

div#topnav-box {
  position: absolute;
  right: 0;
  top: 32px;
  z-index: 3;
}

div#topnav-box div.expand {
  background-position: 0 -4543px;
  cursor: pointer;
  height: 38px;
  width: 38px;
}

div#topnav-box div.content {
  background: #f2f1ef;
  display: none;
  font-size: 0.75rem;
  height: 156px;
  position: relative;
  width: 206px;
}

div#topnav-box div.content .collapse {
  background-position: 0 -4682px;
  height: 12px;
  position: absolute;
  right: 11px;
  top: 11px;
  width: 12px;
}

div#topnav-box div.content ul {
  list-style-type: none;
  margin: 0;
  padding: 6px 0 0 20px;
}

div#topnav-box div.content ul li {
  padding-bottom: 21px;
}

div#topnav-box div.content ul li a {
  display: block;
  font-family: FrutigerLTW01-45Light, 'Helvetica Neue', 'Helvetica', sans-serif, Arial, sans-serif;
  font-size: 0.75rem;
  padding-left: 34px;
}

div#topnav-box div.content ul li a.mail-sml {
  background-position: 4px -2839px;
}

div#topnav-box div.content ul li a.mail-sml:hover,
div#topnav-box div.content ul li a.mail-sml:active {
  text-decoration: underline;
}

div#topnav-box div.content ul li a.print {
  background-position: 2px -2129px;
}

div#topnav-box div.content ul li a.print:hover,
div#topnav-box div.content ul li a.print:active {
  text-decoration: underline;
}

div#topnav-box div.content ul li a.phone-sml {
  background-position: 5px -2365px;
}

div#topnav-box div.content ul li a.phone-sml:hover,
div#topnav-box div.content ul li a.phone-sml:active {
  text-decoration: underline;
}

a#scroll-top {
  background-position: 0 -918px;
  bottom: 130px;
  cursor: pointer;
  height: 38px;
  position: fixed;
  right: 0;
  width: 38px;
}

input.gkSubmit {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  cursor: pointer;
  font-family: FrutigerLTW01-55Roman, 'Helvetica Neue', sans-serif, sans-serif, Arial, sans-serif;
  font-size: 14px;
  padding: 3px 10px;
  text-transform: uppercase;
}

.quote {
  font-family: 'FS Sammy Regular', sans-serif;
}
