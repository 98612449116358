.swiper {
  .swiper-wrapper {
    .swiper-slide {
      height: auto !important;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: $white;
    border-radius: 50px;
    box-shadow: 0 3px 6px $box-shadow-light-gray;
    color: $black !important;
    padding: 30px;

    @media screen and (max-width: $width-breakpoint) {
      display: none !important;
    }

    &::after {
      font-size: x-large !important;
      font-weight: bold;
    }
  }
}
