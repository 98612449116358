
.glyphicons.pdf{
    height: 26px;
    width: 19px;
    padding:5px;
    margin: 0;
    background: url(/design/css/deprecated/glyphicons/images/icon_pdf.png) 0px 0px no-repeat;
}
.glyphicons.pdf i:before{
    content:"";
}

.glyphicons.word{
    height: 26px;
    width: 19px;
    padding:5px;
    margin: 0;
    background: url(/design/css/deprecated/glyphicons/images/icon_word.png) 0px 0px no-repeat;
}
.glyphicons.word i:before{
    content:"";
}

.glyphicons.powerpoint{
    height: 26px;
    width: 19px;
    padding:5px;
    margin: 0;
    background: url(/design/css/deprecated/glyphicons/images/icon_powerpoint.png) 0px 0px no-repeat;
}
.glyphicons.powerpoint i:before{
    content:"";
}

.glyphicons.excel{
    height: 26px;
    width: 19px;
    padding:5px;
    margin: 0;
    background: url(/design/css/deprecated/glyphicons/images/icon_excel.png) 0px 0px no-repeat;
}
.glyphicons.excel i:before{
    content:"";
}