.plf-news-row {
  display: flex;
  flex-wrap: wrap;

  .plf-news-entry-date {
    display: inline-block;
    font-size: 0.9rem;
    line-height: 1.6rem;
  }

  .plf-news-entry {
    border: 4px solid #f2f1ef;
    display: flex;
    flex-direction: column;

    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .plf-news-text {
      padding: 25px 25px 0;

      .plf-news-title {
        color: #6d6458;
        font-size: 1.9rem;
        line-height: 1;
      }
    }

    .plf-news-detail-array {
      align-self: flex-end;
      margin-top: auto;
      padding-bottom: 25px;
      padding-right: 25px;

      .next-page {
        background: url('../../../../../design/2/images/ico/button-s7caace23b7.png') no-repeat 0 -27px;
        display: inline-block;
        height: 26px;
        text-indent: -30000px;
        width: 26px;
      }
    }
  }

  .plf-news-details {
    padding-bottom: 46px;

    .plf-title-wrapper {
      align-items: center;
      background-color: #f2f1ef;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 46px;

      .plf-title-tile {
        padding: 0;
      }
    }

    .plf-news-meta-infos {
      clear: both;

      .column {
        &:first-of-type {
          padding-left: 2.1875rem;
          padding-right: 0;
        }

        &:last-of-type {
          padding-left: 0;
          padding-right: 2.1875rem;
        }
      }

      @media only screen and (min-width: 64em) {
        .column,
        .column:first-of-type,
        .column:last-of-type {
          padding-left: 4.375rem;
          padding-right: 4.375rem;
        }
      }

      .plf-news-button {
        cursor: pointer;
        display: inline-block;
        margin-left: 8px;

        svg {
          font-size: 1.6rem;
        }

        .plf-news-like-count {
          font-size: 0.9rem;
        }
      }

      .plf-news-share-links {
        background-color: #6d6458;
        border-radius: 8px;
        font-size: 1.8rem;
        padding: 8px 16px;
        position: absolute;
        right: 4rem;
        top: 2rem;
        z-index: 2;

        &::before {
          border-color: transparent transparent #6d6458;
          border-style: solid;
          border-width: 5px;
          bottom: 100%;
          content: '';
          margin-left: -5px;
          position: absolute;
          right: 16px;
        }

        &.hide {
          display: none;
        }

        a {
          color: #fff !important;
          padding-right: 6px;
        }

        a:last-of-type {
          padding-right: 0;
        }
      }
    }

    .plf-news-content {
      padding-bottom: 0.9rem;
      padding-top: 0.9rem;
    }

    .plf-video-wrapper {
      padding-bottom: 0;
      padding-top: 0;

      .plf-video {
        text-align: center;

        iframe {
          height: 25vw;
          width: 50vw;
        }
      }
    }
  }
}
