.video-block {
  background-color: $white;
  overflow: hidden;
  padding: 3%;

  .video-banner {
    align-items: flex-end;
    background-color: $light-gray;
    display: flex;
    height: auto;
    margin-bottom: 5vh;

    @media screen and (max-width: $width-breakpoint) {
      display: block;
    }

    .video-banner-content {
      // when not explicitly defined, the background-color becomes white
      background-color: $light-gray;

      .video-button {
        background-color: $white;
        border-radius: 30px;
        display: inline-block;
        margin-bottom: 2%;
        padding: 14px 24px;
      }
    }
  }

  .video-list {
    display: flex;
    gap: 30px;

    @media screen and (max-width: $width-breakpoint) {
      display: block;

      .video-container {
        margin-bottom: 5%;
      }
    }

    .video-container {
      background: $light-gray 0% 0% no-repeat padding-box;
      box-shadow: 0 3px 70px $box-shadow-light-gray;
      flex-direction: column;
      flex-grow: 1;
      width: fill-available;

      .video-player-container {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        width: 100%;

        .video-player {
          bottom: 0;
          height: 100%;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
        }
      }

      .video-info {
        padding: 4% 4% 8%;
      }
    }
  }
}
