.participants-block {
  display: block;

  .participants-row {
    background-color: $light-gray;
    min-height: fit-content;
    overflow: hidden;
    padding: 3%;

    .participants-container {
      margin-bottom: 2%;
      padding-bottom: 35px;

      .participant {
        background-color: $white;

        .participant-image {
          // stylelint doesn't recognize aspect-ratio, even though it gets supported by all modern browsers
          /* stylelint-disable-next-line property-no-unknown */
          aspect-ratio: 5 / 6;
          object-fit: cover;
          object-position: 50% 25%;
          width: 100%;
        }

        .participant-textbox {
          line-height: 0;
          padding: 6% 6% 10%;

          h1 {
            text-transform: uppercase;
          }

          p {
            margin-bottom: 0;
          }
        }
      }

      .swiper-pagination {
        bottom: 0;
        position: absolute;
      }
    }
  }
}
