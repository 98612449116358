// Colors
$white: #fff;
$light-gray: #f5f5f5;
$black: #000;

// Border-colors
$box-shadow-light-gray: #00000026;

// Breakpoints
$width-breakpoint: 50.063em;
