@import './app/main';
@import './app/base.css';
@import './app/style.css';
@import './variables';

@import './app/deprecated/sprite.css';
@import './app/deprecated/shared.css';
@import './app/deprecated/glyphicons/css/glyphicons.css';
@import './app/deprecated/glyphicons/css/glyphicons-extended.css';

@import './app/copiedfromcore/lib/jqueryui/css/jquery-ui.css';
@import './app/copiedfromcore/lib/zoomy/zoomy.css';
@import './app/copiedfromcore/lib/tooltip/jquery.cluetip.css';
@import './app/copiedfromcore/lib/colorbox/theme1/colorbox.css';

@import './app/swiper/swiper';

@import '../vendor/lightbox/css/lightbox.css';

@import '../../../platform/widgets/view/news/scss/news';
@import '../../../platform/widgets/view/news/scss/blog';
@import '../../../platform/widgets/view/video/scss/video';
@import '../../../platform/widgets/view/participants/scss/participants';
@import '../../../platform/widgets/view/participants/scss/participant-detail';
