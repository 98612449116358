/* stylelint-disable */
div.ui-cluetip {
    font-size: 1em
}

.ui-cluetip-header, .ui-cluetip-content {
    padding: 12px
}

.ui-cluetip-header {
    font-size: 1em;
    margin: 0;
    overflow: hidden
}

.cluetip-title .cluetip-close {
    float: right;
    position: relative
}

.cluetip-close img {
    border: 0
}

#cluetip-waitimage {
    width: 43px;
    height: 11px;
    position: absolute;
    background-image: url(/sitevision/lib/tooltip/images/wait.gif)
}

.cluetip-arrows {
    display: none;
    position: absolute;
    top: 0;
    left: -11px;
    width: 11px;
    height: 22px;
    background-repeat: no-repeat;
    background-position: 0 0;
    border-width: 0
}

.cluetip-extra {
    display: none
}

.cluetip-default {
    background-color: #d9d9c2
}

.cluetip-default .ui-cluetip-header {
    background-color: #87876a
}

div.cluetip-default .cluetip-arrows {
    border-width: 0;
    background: transparent none
}

div.clue-right-default .cluetip-arrows {
    background-image: url(/sitevision/lib/tooltip/images/darrowleft.gif)
}

div.clue-left-default .cluetip-arrows {
    background-image: url(/sitevision/lib/tooltip/images/darrowright.gif);
    left: 100%;
    margin-right: -11px
}

div.clue-top-default .cluetip-arrows {
    background-image: url(/sitevision/lib/tooltip/images/darrowdown.gif);
    top: 100%;
    left: 50%;
    margin-left: -11px;
    width: 22px;
    height: 11px
}

div.clue-bottom-default .cluetip-arrows {
    background-image: url(/sitevision/lib/tooltip/images/darrowup.gif);
    top: -11px;
    left: 50%;
    margin-left: -11px;
    width: 22px;
    height: 11px
}

.cluetip-jtip {
    background-color: #fff
}

.cluetip-jtip .cluetip-outer {
    border: 2px solid #ccc;
    position: relative
}

.cluetip-jtip .cluetip-inner {
    padding: 5px;
    display: inline-block
}

.cluetip-jtip div.cluetip-close {
    text-align: right;
    margin: 0 5px 0;
    color: #900
}

.cluetip-jtip .ui-cluetip-header {
    background-color: #ccc;
    padding: 6px
}

div.cluetip-jtip .cluetip-arrows {
    border-width: 0;
    background: transparent none
}

div.clue-right-jtip .cluetip-arrows {
    background-image: url(/sitevision/lib/tooltip/images/arrowleft.gif)
}

div.clue-left-jtip .cluetip-arrows {
    background-image: url(/sitevision/lib/tooltip/images/arrowright.gif);
    left: 100%;
    margin-right: -11px
}

div.clue-top-jtip .cluetip-arrows {
    background-image: url(/sitevision/lib/tooltip/images/arrowdown.gif);
    top: 100%;
    left: 50%;
    width: 22px;
    height: 11px;
    margin-left: -11px
}

div.clue-bottom-jtip .cluetip-arrows {
    background-image: url(/sitevision/lib/tooltip/images/arrowup.gif);
    top: -11px;
    left: 50%;
    width: 22px;
    height: 11px;
    margin-left: -11px
}

.cluetip-rounded {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #ccc
}

.cluetip-rounded .cluetip-arrows {
    border-color: #ccc
}

div.cluetip-rounded .cluetip-arrows {
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
    border-style: solid;
    background: transparent none
}

div.clue-right-rounded .cluetip-arrows {
    border-width: 11px 11px 11px 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent
}

div.clue-left-rounded .cluetip-arrows {
    left: 100%;
    margin-right: -11px;
    border-width: 11px 0 11px 11px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent
}

div.clue-top-rounded .cluetip-arrows {
    top: 100%;
    left: 50%;
    border-width: 11px 11px 0;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent
}

.clue-bottom-rounded .cluetip-arrows {
    top: -11px;
    left: 50%;
    border-width: 0 11px 11px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent
}

.cluetip-rounded .cluetip-title, .cluetip-rounded .cluetip-inner {
    zoom: 1
}